.subscribe-cta {
  $circle-size: 80px;
  position: fixed;
  right: 0;
  bottom: 2rem;
  z-index: 51;
  display: flex;
  flex-direction: row-reverse;
  border-radius: 50px 0 0 50px;
  cursor: pointer;
  transition: all $base-animation-timing $base-animation-ease;
  color: $color-white;
  font-weight: $font-weight-bold;
  height: $circle-size;
  overflow: hidden;

  &__icon {
    border-radius: 50%;
    background: $color-brand-values;
    width: $circle-size;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
    transition: all $base-animation-timing $base-animation-ease;
    position: relative;
    padding: 1.1rem;
    transform: translateX(5rem);

    img {
      width: 100%;
    }
  }

  &__label {
    font-family: $font-family-nimb;
    opacity: 0;
    padding: 5px 35px 5px 45px;
    margin-left: calc($circle-size/-2);
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    transition: all $base-animation-timing $base-animation-ease
      $base-animation-timing;
    overflow: hidden;
    background: $color-brand-values;
  }

  &:hover, &:focus {
    color: $color-white;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);

    .subscribe-cta {
      &__icon {
        box-shadow: none;
        transform: none;
      }

      &__label {
        opacity: 1;

        @media all and (max-width: $bp-md) {
          width: auto;
        }
      }
    }
  }

  @media all and (max-width: $bp-md) {
    $circle-size: 60px;
    height: $circle-size;
    &:hover {
      .subscribe-cta__label {
        transform: none;
      }
    }

    &__icon {
      width: $circle-size;
      transform: translateX(6rem);
    }
  }
}

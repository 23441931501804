$accordion-padding: 1rem 1.25rem;
.accordion {
  margin-bottom: $spacing-unit;
}

.accordion-item {
  overflow: hidden;
  border-bottom: 1px solid $color-accordion-border;

  &:first-child {
    border-top: 1px solid $color-accordion-border;
  }
}

//Accordion Trigger
.accordion-trigger {
  @extend %nimbusBoldTitle;
  color: $color-accordion;
  width: 100%;
  padding: $accordion-padding;
  cursor: pointer;
  background-color: $color-accordion-bg;
  overflow: hidden;
  position: relative;
  transition: background-color 0.2s, color 0.2s;

  @media all and (max-width: $bp-sm-landscape) {
    padding-top: 1rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  &.is-active-menu,
  &:hover {
    color: $color-brand-lightblue;
    background-color: $color-brand-white;

    .expand-icon {
      background-color: $color-brand-lightblue;

      &:after {
        background-color: $color-brand-lightblue;
      }
    }
  }

  &.is-active-menu {
    .expand-icon:after {
      left: 0;
      transform: translate3d(0, 0, 0) rotate(0deg);
    }
  }

  &.accordion-trigger-blank {
    cursor: text;
    &:hover {
      color: $color-accordion;
    }
  }

  .expand-icon {
    @include expand-plus;

    left: 0;
    top: 8px;
  }
}

.accordion-title,
.accordion-info {
  float: left;

  @media all and (max-width: $bp-sm-landscape) {
    display: block;
    float: none;
  }
}

.accordion-title {
  width: 80%;
  font-size: 1.7rem;
  line-height: 1.4rem;
  padding-left: 40px;
  padding-right: 15px;
  position: relative;

  @media all and (max-width: $bp-sm-landscape) {
    font-size: 1.4rem;
    width: 100%;
    padding-right: 0;
  }
}

//Sub Components of accordion title
.department {
  text-transform: none;
  font-size: 0.95rem;
  font-weight: 400;
  color: $color-text-gray-alt;
}

.openings {
  display: block;
  font-size: 1rem;
  color: $color-text;
}

.locations {
  .locations-item {
    display: inline-block;

    &:after {
      content: ',';
    }

    &:last-child {
      &:after {
        content: '';
      }
    }
  }

  &:before {
    @include font-awesome-icon;

    content: '\f041';
    position: absolute;
    margin-left: -15px;

    @media all and (max-width: $bp-sm-landscape) {
      position: static;
      margin-left: 0;
      margin-right: 5px;
    }
  }
}

//Main Accordion Content
.accordion-info {
  @extend %nimbusBoldTitle;

  font-size: 0.95rem;
  font-weight: 400;
  color: $color-text-gray-alt;
  width: 20%;

  @media all and (max-width: $bp-sm-landscape) {
    padding-left: 40px;
    width: 100%;
  }
}

.story-count {
  position: absolute;
  right: 0;
  top: 0;
  width: 20%;
  height: 100%;
  text-align: center;
  font-size: 1rem;
  font-weight: $font-weight-bold;
  color: $color-text;
  vertical-align: middle;

  .fa {
    font-size: 1.25rem;
    margin-top: 7px;
  }

  @media all and (max-width: $bp-sm-landscape) {
    position: static;
    right: auto;
    top: auto;
    border-left: 0 none;
    width: 100%;
    text-align: left;
  }
}

.story-count-label {
  display: block;
  margin-top: 5px;

  @media all and (max-width: $bp-sm-landscape) {
    display: inline-block;
  }
}

.accordion-content {
  width: 100%;
  display: none;
  font-size: 0.95rem;
  overflow: hidden;

  .rich-text {
    padding: $accordion-padding;
    color: #494949;
  }
}

//Logic that Displays the first menu item to be open
.accordion[data-accordion-first-open='true'] {
  .accordion-item {
    &:first-child {
      .accordion-trigger {
      }

      .accordion-content {
        display: block;
      }
    }
  }
}

.search-results-data {
	font-size: 14px;
	margin-bottom: 22px;
	color: rgba($color-black, 0.6);

	.select-dropdown {
		transform: translateY(-15%);
		margin-left: 10px;
	}
}

.results-data-count {
	@media all and (max-width: $bp-sm-landscape-iphone) {
		display: block;
		margin-bottom: calc($spacing-unit/2);
	}
}

.results-data-label {
	@extend %nimbusBoldTitle;

	font-size: 1.5rem;
}

.search-sort-label {
	@extend .filter-label-small;

	display: inline-block;
	border-left: 1px solid $color-brand-darkblue;
	margin-left: 3%;
	padding-left: 3%;
	line-height: 1.5;
	width: auto;

	@media all and (max-width: $bp-sm-landscape-iphone) {
		border: 0 none;
		padding-left: 0;
		margin-left: 0;
	}

	&.standalone {
		border: none;
		padding: 0;
		margin: 0;
	}
}
.select-dropdown {
  position: relative;
  display: inline-block;
  background-color: #ececec;
  padding: 10px 20px;
  vertical-align: top;
  border-radius: 50px;

  &:hover {
    background: $color-load-more-bg;
  }

  &:focus {
    outline: 2px solid $color-brand-lightblue;
  }

  select {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: transparent;
    opacity: 0;
    z-index: 5;
    cursor: pointer;

    &:focus {
      outline: 2px solid black;
    }
  }

  &__full {
    width: 100%;

    .select-dropdown-label {
      &:after {
        position: absolute;
        /*top: 5px;
				right: 5px;*/
        top: 10px;
        right: 15px;
      }
    }
  }
}

.select-dropdown-label {
  color: $color-links;
  font-size: 1rem;
  line-height: 1;
  font-family: $font-family-nimb;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: space-between;

  &:after {
    @include font-awesome-icon;
    content: '\f107';
  }
}

.select-dropdown__full{
  &:focus {
    outline: 2px solid $color-brand-lightblue
  }
}

.lang-dropdown-wrap {
  position: relative;
  display: flex;
  justify-content: flex-end;

  //layout modifications to push down sidebar and account for language dropdown
  & + .grid-content {
    .grid-content-sidebar {
      margin-top: 60px;

      @media all and (max-width: $bp-md) {
        margin-top: 50px;
      }
    }
  }
}

.lang-dropdown {
  position: relative;
  right: 0;
  width: 100%;
  min-width: 150px;
  margin-bottom: 1rem;

  .select-dropdown-label {
    &:after {
      position: absolute;
      right: 15px;
    }
  }
}

.focused {
  outline: 2px solid $color-brand-lightblue;
}

﻿.table-page {
    margin-top: 280px;
    min-height: calc(100vh - 19vh - 310px);

    @media all and (max-width: $bp-lg) {
        margin-top: 220px;
        min-height: calc(100vh - 18vh - 220px);
    }

    @media all and (max-width: $bp-md) {
        margin-top: 165px;
        min-height: calc(100vh - 19vh - 165px);
    }

    @media all and (max-width: $bp-sm-landscape) {
        margin-top: 95px;
        min-height: calc(100vh - 145px);
    }
}

.ajax-active {
	overflow: hidden;
}

.home-ajax-content {
	position: fixed;
	top: 0;
	bottom: 0;
	width: $width-ajax-content;
	right: -$width-ajax-content;
	background-color: $color-white;
	z-index: $z-index-ajax-content;
	transition: right 1s;
	height: 100%;
	overflow: visible;
	display: none;
	font-family: $font-family-nimb;

	&.active {
		display: block;
	}

	body.ajax-active & {
		right: 0;

		.ajax-content-close {
			right: $width-ajax-content;
		}
	}
	//these are overrides depending on the section of the homepage activated
	&.work a {
		color: $color-brand-work;
	}

	&.values a {
		color: $color-brand-values;
	}

	&.team a {
		color: $color-brand-team;
	}

	.ajax-loading-screen {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: 100;
		background-color: $color-white;
		display: none;

		&.active {
			display: block;
		}

		.loading-message {
			text-align: center;

			@include vertical-align;
		}
	}

	.ajax-content-close {
		position: fixed;
		top: 0;
		width: $width-search-button;
		height: $height-header;
		background-color: $color-white;
		text-align: center;
		cursor: pointer;
		z-index: $z-index-ajax-content;
		right: -$width-search-button;
		transition: right 1s, background-color .5s, color .5s;

		&:hover {
			background-color: $color-brand-blue;
			color: $color-white;
		}

		@media all and (max-width: $bp-lg) {
			width: 70px;
		}

		@media all and (max-width: $bp-sm) {
			height: $height-header-sm;
		}
	}

	.article-home {
		padding: $spacing-unit calc($width-search-button/2) calc($width-search-button/2);
		margin-bottom: 1.2rem;
		line-height: 1.55rem;
		font-size: 1rem;
		height: 100%;
		overflow-x: hidden;
		overflow-y: scroll;

		@media all and (max-width: $bp-lg) {
			padding:calc( $width-search-button/3);
		}

		.btn-read-more, .btn.btn-read-less {
			float: right;
			padding: 0 1rem 1rem 0;
		}

		a {
			color: $color-links;
			transition: $base-animation;

			&:hover {
				color: $color-links-hover;
			}
		}
	}

	.article-intro {
		line-height: 1.1em;
		font-weight: 600;
		padding-bottom: calc($spacing-unit/2);
		margin-bottom: calc($spacing-unit/2);
		border-bottom: 1px solid $color-lightish-gray;

		a {
			color: $color-brand-blue;
		}
	}

	.article-authors {
		margin: 1.1em 0 0;


		.author {
			display: inline-block;

			&:after {
				content: ',';
			}

			&:last-of-type:after {
				content: '';
			}
		}
	}

	.article-text {
		margin-right: 30px;
		font-family: Georgia;
		line-height: 1.6em;
		color: $color-dark-gray;
	}

	.tools-container {
		overflow: hidden;
		margin-bottom: calc($spacing-unit/2);
	}

	.article-date {
		color: $color-dark-gray;
		font-family: $font-family-nimb;
		font-weight: $font-weight-bold;
		font-size: .83rem;
	}

	.article-tools {
		float: right;

		@include vertical-align;
	}

	.article-info {
		background-color: $color-lightest-gray;
		padding: 20px;
		width: 250px;
		float: left;
		margin: 8px 30px 30px 0;

		@media all and (max-width: $bp-lg) {
			width: 200px;
		}

		.title {
			color: $color-black;
			margin-bottom: 20px;
			font-size: 1.6em;
			font-weight: 800;
		}

		ul {
			margin: 0;
		}

		li {
			margin: 0 0 calc($spacing-unit/2);

			&:last-child {
				margin: 0;
			}
		}
	}

	.share-tools {
		position: relative;
		height: 70px;
		padding: 0;
		margin-bottom: 40px;
		margin-top: 40px;
		border-top: 1px solid $color-lightish-gray;
		border-bottom: 1px solid $color-lightish-gray;
		color: $color-text;
		clear: both;

		span,
		.addthis_toolbox {
			display: inline-block;

			@include vertical-align;
		}

		a {
			padding: 0 15px;
			font-size: 1.2rem;
		}

		span {
			margin-right: 10px;
			font-size: 1.35rem;
			font-weight: 800;
		}
	}

	.article-next {
		background-color: $color-lightest-gray;
		padding: 40px;
		position: relative;
		font-size: 1.6em;
		font-weight: 800;

		span {
			display: table-cell;
			padding-right: .5rem;
			white-space: nowrap;
		}

		> a {
			display: table-cell;
			line-height: 1.1em;
		}
	}
}

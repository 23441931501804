﻿.service-landing {
    .site-wrapper {
        background: none;
        padding-top: 0;
        padding-bottom: 0;
    }

    .page-hero {
        margin-top: 0;
    }

    &__columns {
        display: flex;
        flex-direction: column;

        @media screen and (min-width: $bp-md) {
            flex-direction: row;
            flex-flow: row wrap;
        }
    }

    &__column {
        width: 100%;
        margin-bottom: 0.5rem;

        @media screen and (min-width: $bp-md) {
            width: calc(50% - 0.5rem);
            margin-right: 0.5rem;
        }

        @media screen and (min-width: $bp-lg) {
            width: calc(33.333% - 0.5rem);
        }

        .service-landing__listing {
            column-count: 1;
        }
    }

    &__section {
        margin: 2rem 0;
        visibility: visible;
        opacity: 1;
        left: 0;
        transition: visibility 0s linear, opacity 300ms;

        &:last-of-type {
            margin-bottom: 0;
        }

        &--hidden {
            position: fixed;
            opacity: 0;
            visibility: hidden;
            left: -9999px;
        }
    }

    &__blue-section {
        background: rgba(205, 230, 245, 0.4);
        border-radius: 20px;
        padding: 2rem;
        height: 100%;

        &:hover {
            background: rgba(205, 230, 245, 0.8);
        }
    }

    &__flex {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    &__title {
        flex-basis: 33%;
        margin-right: 1rem;
        color: $color-footer-gray;

        h2, h3 {
            font-weight: 100;
            font-size: 30px;
            margin-bottom: 2rem;
            text-transform: none;
        }
    }

    &__listings {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .service-landing__listing {
            column-count: 1;
        }
    }

    &__listing {
        column-count: 2;
    }

    &__listing {
        font-family: $font-family-nimb;

        a {
            color: $color-brand-blue;
            word-break: break-word;

            &:hover {
                color: $color-brand-lightblue;
            }
        }

        &-container {
            //three column
            width: 30%;

            @media all and (max-width: $bp-md) {
                //two column
                width: 47%;
            }

            @media all and (max-width: $bp-sm) {
                width: 100%;
            }
        }
    }

    @media all and (max-width: $bp-lg) {
        &__flex {
            display: block;
        }
    }

    @media all and (max-width: $bp-md) {
        &__listings {
            columns: 2;
        }
    }

    @media all and (max-width: $bp-sm) {
        &__listings {
            display: block;
            columns: auto;
        }

        &__listing {
            column-count: 1;
        }
    }
}

.careers-landing-rich-text {
    .responsive-video {
        position: relative;
        padding-bottom: 56.25%;
    }

    .responsive-video iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

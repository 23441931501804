
.offices-list {
	columns: 2 auto;

	@media all and (max-width:$bp-sm-landscape-iphone) {
		columns: 1;
		height: auto;

	}
}

.office-item {
	-webkit-column-break-inside: avoid;
        page-break-inside: avoid;
             break-inside: avoid;
	//min-height: 200px;
	padding-bottom: calc($spacing-unit/2);

	@media all and (max-width:$bp-sm-landscape-iphone) {
		width: 100%;
		min-height: 10px;
		padding-bottom: $spacing-unit;
	}
}

.office-item-title {
	@extend .title-blue;

	margin-bottom: 0;
}

.office-directions {
	@extend .small-text;

	display: inline-block;
}

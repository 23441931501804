.recognition-item {
	display: flex;
	flex-flow: row wrap;
	border-bottom: 1px solid $color-list-border;
	padding-bottom: 1.2rem;
	margin-bottom: 1.2rem;

	@media all and (max-width: $bp-sm-landscape) {
		.col1-5 {
			width: 30%;
			padding-right: 5%;
		}

		.col2-5 {
			width: 70%;
			padding: 0;
		}
	}

	 .recognition-text {
		width: $site-main-column;

		.no-flexbox & {
			float: left;
		}
	}


	.featured-recognition-title,
	.recognition-title {
		@extend h3;

		text-transform: none;
	}


	.recognition-title {
		@extend h4;
		margin-bottom: 28px;
	}

	.recognition-photo {
		width: $site-sidebar-column;
		padding-left: 1rem;
		margin-top: 5px;

		@media all and (max-width: $bp-md) {
			width: 27%;
		}

		@media all and (max-width: $bp-md-grid) {
			margin-top: $spacing-unit;
		}

		&>* {
			margin-top: $spacing-unit;
		}

		&>*:first-child {
			margin-top: 0px;
		}

		.no-flexbox & {
			float: right;
		}
	}
}
//ninja fix for one off case for pro-bono page
#anchor-recognition{
	.recognition-item{
		.recognition-text{
			width: 100%;
		}
	}
}
.js-list-recognition{
	.article{
		.article-title{
            em {
                font-weight: 600;
            }
			p:last-child{
				margin-bottom: 0;
			}
		}
	}
}

﻿.page-hero {
    .site-subnav+div & {
        margin-top: 65.59px;
    }

    &__image {
        background-position: top left;
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 250px;
        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
            height: 250px;
        }
    }

    &__layer {
        display: flex;
        align-items: center;
        background: linear-gradient(to right, #001633, rgba(0, 58, 112, 0));
        mix-blend-mode: hard-light;
        min-height: 250px;
        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
            height: 250px;
        }
    }

    &__title {
        font-weight: 100;
        color: $color-white;
    }

    &__header {
        font-family: $font-family-primary;
        color: #FCFCFC;
        padding: 64px;
        width: 50%;
    }

    &__quote {
        font-size: 24px;
        line-height: 28.8px;
    }

    &__quote-author {
        font-size: 16px;
        line-height: 19.2px;
    }  

    @media all and (max-width: $bp-lger) {
        .site-subnav+div & {
            margin-top: 50px;
        }

        &__header {
            padding: 32px;
            width: 100%;
        }

        &__quote-author {
            display: block;
            padding-top: 10px;
        } 
    }
}
﻿.slide {
	display: none;

	&.slick-active {
		display: block !important;
	}
}

.slick-initialized {
	.slide {
		display: block;
	}
}


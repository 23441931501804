.case-showcase {
  font-size: 1rem;
  line-height: 1.69rem;

  .showcase-header {
    @include section-header;
  }

  .showcase-description {
    color: #494949;
  }

  .case-details {
    margin: 2rem 0;
  }

  .case-details-title {
    font-family: $font-family-nimb;
    color: $color-brand-work-mid;
    font-weight: $font-weight-bold;
    font-size: 2rem;
    margin: 2rem 0 0.8rem 0;
  }

  .case-details-readmore {
    display: none;
  }
}

.cases-container {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(-50%, 0, 0);
    width: 2000%;
    height: 100%;
    background-color: #f0f0f0;
    z-index: -1;
  }
}

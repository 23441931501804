.side-list {
  padding: 1.3rem 1.3rem 0;
  font-family: $font-family-nimb;
  border: 1px solid #e2e2e2;
  border-radius: 6px;

  .side-list-title {
    font-size: 1.55rem;
    font-weight: $font-weight-bold;
    margin-bottom: 1.25rem;
    color: $color-brand-work;
    padding-right: 1rem;
  }

  .side-list-item {
    font-size: 1rem;
    font-family: $font-family-primary;
    line-height: 1.35rem;
    margin-bottom: 1.2rem;
  }
}

.fixed-height-sidelist {
  ul {
    display: block;
    height: 300px;
    margin-right: -1.3rem;
    padding-right: 1rem;
    overflow: auto;
  }
}

.dynamic-height-sidelist {
  ul {
    display: block;
    margin-right: -1.3rem;
    padding-right: 1rem;
    overflow: auto;
  }

  ul::-webkit-scrollbar {
    display: none;
  }
}
.subscribe-link-button-contatiner {
  padding: 0rem 1.3rem 0;
  .link-subscribe-button {
    font-size: 1.2rem;
    padding: 0.5rem 0.5rem;
  }
}

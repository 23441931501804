.site-footer {
    $font-size-sm: 16px;

	position: relative;
	background-color: $color-footer-gray;
	color: $color-lightest-gray;
	transition: all 1s;
	font-family: $font-family-nimb;
	//font-weight: $font-weight-bold;
	padding: 35px 20px;
    text-align: center;

	@media all and (max-width: $bp-sm) {
		text-align: center;
		top: auto;		
	}

	&:after {
		content: '';
		display: block;
		clear: both;
	}

	.home-container + & {
		width: 100%;
		top: 100%;
		right: 0;
		
		@media all and (max-width: $bp-two-tile) {
			top: calc( #{100 - $height-header} + #{$two-tile-height});
		}

		@media all and (max-width: $bp-sm) {
			position: relative;
			top: auto;
			right: auto;
			margin-top: 0;
		}
	}

	body.ajax-active & {
		right: $width-ajax-content;
	}
		
	a, li {
		text-decoration: none;		
		display: inline-block;
		transition: color .5s;
        color: inherit;

		&:hover {
			color: $color-links-hover;
		}
	}

	li + li {
		padding-left: 15px;
	}

    &__headline {
        font-size: 32px;
        font-family: $font-family-nimb;
        font-weight: 100;
        line-height: 1.2;
    }
    
    &__cta {
        margin-top: 20px;

        a {
			padding: 10px 20px;
			@include icon-after;
            border: 1px solid $color-lightest-gray;
			font-size: $font-size-sm;
			border-radius: 50px;
			transition: all $base-animation-timing $base-animation-ease;
			&::after {
				background-image: url('../images/icons/arrow-right-white.svg');
			}
			&:hover {
				background: $color-white;
				color: $color-brand-blue;
				&::after {
					background-image: url('../images/icons/arrow-right.svg');
				}
			}

            .fa {
                margin-left: 5px;
            }

            @media all and (max-width: $bp-sm-portrait) {
                width: 90%;
			}            
        }
    }

    .social-icons {
        margin-top: 20px;

		@media all and (max-width: $bp-sm) {
			position: static;
			display: inline-block;
			text-align: center;
			background-color: transparent;
			padding: 10px;
		}

		.fa-linkedin:hover {
			color: #1f75b9;
		}

		.fa-facebook:hover {
			color: #3c56Ac;
		}

		.fa-twitter:hover {
			color: #65A8E2;
		}

		.fa-google-plus:hover {
			color: #d34836;
		}

        &__spacer {
            border-left: 1px solid $color-lightest-gray;
            vertical-align: top;
        }

        &__rss {            
            padding-left: 20px;

            span {
                font-family: $font-family-nimb;
                font-size: $font-size-sm;       
                height: 1rem;
                display: inline-block;
                vertical-align: middle;         
            }
        }
	}

    &__navigation {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 50px;
        font-size: $font-size-sm;

        @media all and (max-width: $bp-md) {
			flex-direction: column;            
		}
    }
       
	.footer-links {
		display: flex;
		flex-flow: row wrap;
		&__expander {
            display: inline-block;
            padding: 0;
			padding-right: 10px;
			margin-right: 10px;
            font-size: $font-size-sm;
			color: $color-lightest-gray;
			&::after {
                @include font-awesome-icon;
                content: $fa-var-angle-down;
				font-size: 1.5em;
				display: inline-block;
				vertical-align: bottom;
				line-height: 0.8em;
				transition: transform $base-animation-timing $base-animation-ease;
			}
			&:hover {
				color: $color-links-hover;
			}
			&--expanded {
				&::after {
					transform: rotate(180deg);
				}
			}

            @media all and (max-width: $bp-md) {
                display: block;
                margin: 0 auto;
			}
            @media all and (max-width: $bp-sm-portrait) {
                width: 90%;
			}            
		}   

		ul {
            display: inline-block;
			margin-bottom: 0;

            @media all and (max-width: $bp-md) {
                margin-top: 20px;
			}
		}

		li {
			display: inline-block;
			padding-right: 10px;
			margin-bottom: 0;
		}

        &__legal-policy {
            margin-top: 20px;
            font-size: $font-size-sm;
            text-align: left;
			width: 100%;
			order: 3;
			display: none;
            ul {
				margin: 0;
                li {
                    display: inline-block;
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                @media all and (max-width: $bp-md) {
                    display: flex;
                    flex-direction: column;
                    text-align: center;

                    li {
                        margin-left: 0;
                    }
			    }
            }

            &--collapsed {
                display: none;
            }
		}
		@media all and (max-width: $bp-lgx) {
			display: block;
		}
	}
		
	.copyright {		
		color: $color-lightest-gray;

        @media all and (max-width: $bp-md) {
            margin-top: 20px;
            font-size: 12px;
		}
	}	

	.language-select {
		display: inline-block;
		margin-left: 15px;
	}

	.lang-dropdown {
		position: static;
		width: auto;
		height: auto;
	}	
}
.list-bullet-section {
    border-bottom: 1px solid #e5e5e5;
    position: relative;
    padding: 0 0 0.75rem;

    a {
        color: $color-brand-lightblue;
        transition: $base-animation;

        &:hover {
            color: $color-gray-dark;
            text-decoration: underline;
        }
    }

    li {
        margin-bottom: 1.6rem;
    }

    &:nth-last-child(1) {
        border-bottom: 0 none;
    }

    &:first-of-type {
        padding-top: 0;
    }

    ul {
        margin: 0;
        list-style-type: square;
        padding-left: 1rem;
    }

    .list-bullet-subheader {
        font-weight: $font-weight-bold;
        font-family: $font-family-nimb;
        font-size: 1.22rem;
        line-height: 1.55rem;
    }

    .list & + .list-bullet-section {
        padding-top: 0.75rem;
    }

    .bulleted-item {
        $spacing: 1rem;

        margin: 0;
        margin-top: 0.75rem;
        color: #548bb4;
        position: relative;
        left: $spacing;
        padding-right: $spacing;

        &:first-of-type {
            margin-top: 0;
        }

        .bulleted-item-text {
            p {
                margin: 0;
            }

            color: #393939;
        }

        & * {
            display: inline;
        }
    }
}

.read-more-content,
.list-bullet-section {
  p:empty {
    display: none;
    margin: 0;
    padding: 0;
  }
}

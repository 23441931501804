.main-stage-slide {
	position: absolute;
	top: 0;	
	right: -50px;
	overflow: hidden;
	width: 100%;
	height: 100%;
	z-index: 7;
	visibility: hidden;
	
	//Make sure to update homepage.js with the transition timing to sync up the slide transition


	.hero-title {
		opacity: 0;
		
		
		@media all and (max-width: $bp-sm) {
			opacity: 1;
		}
	}

	.slide-content-wrapper {
		opacity: 0;
		
		
		@media all and (max-width: $bp-sm) {
			opacity: 1;
		}
	}

	.footer-infographic .highlight {
		opacity: 0;
		
		
		@media all and (max-width: $bp-sm) {
			opacity: 1;
		}
	}


	&.active {
		right: 0;
		visibility: visible;
		width: 100%;
		transition: width 1s,
					right 1s;

		&.zero-width {
			width: 0;
			right: -50px;
		}


		.slide-content-wrapper {
			opacity: 1 !important;

			transition: opacity .3s !important;
			transition-delay: .7s !important;
		}

		.hero-title {
			opacity: 1;

			transition: opacity .3s;
			transition-delay: .7s;
		}

		.footer-infographic .highlight {
			opacity: 1;
			transition: opacity .3s;
			transition-delay: .7s;
		}

		.home-footer {
			position: absolute;
		}

		@media all and (max-width: $bp-sm) {

			.home-footer {
				position: relative;
				top: auto;
				height: auto;
				display: block;
			}	
		}
	}

	&.active-prev {
		visibility: visible;
		z-index: 5;
		right: 0;

		.slide-content-wrapper,
		.hero-title,
		.footer-infographic .highlight {
			opacity: 1;
		}

		.home-footer {
			@media all and (min-width: $bp-sm) {
				position: absolute;
			}
		}
	}


	&.active-next {
		z-index: 10;
		visibility: hidden;
		width: 0;

		&.active {
			visibility: visible;
			width: 100%;
		}

		@media all and (max-width: $bp-sm) {
			visibility: visible;
			width: 100%;
		}
	}

	@media all and (max-width: $bp-sm) {
		width: 100%;
		right: 0;
		position: relative;
		visibility: visible;
	}
}
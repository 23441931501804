﻿.jump-links {
    padding: 20px 0;
    width: 100%;
    background-color: $color-white;
    z-index: $z-index-fixed-header - 1;
    box-shadow: $header-shadow;

    &__flex {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &--padding {
        margin-top: 49px;
    }

    &--fixed {
        position: fixed;
        top: $height-header;
    }

    &__link {
        border-radius: 50px;
        padding: 10px 15px;
        color: $color-brand-blue;

        &--active {
            background-color: $color-lightest-gray;
            font-weight: $font-weight-bold;
        }

        &:hover {
            color: $color-brand-lightblue;
        }
    }

    &__links {
        margin: 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        li {
            display: inline;
            margin: 0;
            margin-left: 20px;

            &:first-child {
                margin-left: 0;
            }

            button {
                font-size: 16px;
            }

            label {
                font-size: 16px;
                font-family: $font-family-nimb;
            }
        }

        &--spacer {
            border-left: 1px solid $color-gray;
            height: 1.5rem;
        }
    }

    &__dropdown {
        display: none;
        margin: 0 0 1rem 0;
    }

    &__search {
        margin-bottom: 0;
        margin-right: 15px;
        min-width: 320px;
        width: 100%;
        position: relative;

        form {
            height: 100%;
        }

        input {
            border: 2px solid $color-brand-blue;
            border-radius: 25px;
            color: $color-brand-blue;
            background-color: $color-white;
            padding: 8px 15px;
            text-transform: none;
            font-size: 14px;
            font-weight: 100;
            height: 100%;
            margin: 0;

            &::placeholder {
                color: $color-gray;
            }

            &:hover {
                border-color: $color-brand-lightblue;
            }
        }
        /* clears the 'X' from Internet Explorer */
        input[type=search]::-ms-clear {
            display: none;
            width: 0;
            height: 0;
        }

        input[type=search]::-ms-reveal {
            display: none;
            width: 0;
            height: 0;
        }
        /* clears the 'X' from Chrome */
        input[type="search"]::-webkit-search-decoration,
        input[type="search"]::-webkit-search-cancel-button,
        input[type="search"]::-webkit-search-results-button,
        input[type="search"]::-webkit-search-results-decoration {
            display: none;
        }

        button {
            height: 100%;
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            padding: 0;
            top: 0;
            right: 0;
        }

        .fa {
            font-size: 18px;
            top: auto;
            transform: none;
        }
    }

    &__view-all {
        @include icon-after;
        color: $color-brand-blue;
        border: 2px solid $color-brand-blue;
        border-radius: 25px;
        color: $color-brand-blue;
        text-transform: none;
        font-size: 14px;
        font-weight: 100;
        min-width: 110px;
        padding: 7px 16px;
        font-family: $font-family-nimb;

        &::after {
            margin-top: 0;
            height: 11px;
        }

        &:hover {
            color: $color-brand-lightblue;
            border-color: $color-brand-lightblue;
        }
    }

    &__tools {
        display: flex;
        align-self: center;
        margin-left: 1rem;
    }

    @media all and (max-width: $bp-lgx) {
        &__links {
            li {
                margin-left: 15px;
            }
        }
    }

    @media all and (max-width: $bp-lg) {
        &__flex {
            display: block;
        }

        &__search {
            flex: 1 1 auto;
            width: auto;
        }

        &__tools {
            margin: 0.5rem 0 0 0;
        }

        &__links {
            li {
                margin-left: 10px;
            }
        }
    }

    @media all and (max-width: $bp-md) {
        top: $height-header-sm;
    }

    @media all and (max-width: $bp-md-grid) {
        &__dropdown {
            display: block;
        }

        &__links {
            display: none;
        }

        &__view-all {
            width: 100%;
            flex-basis: 20%;
        }

        &__search {
            min-width: auto;
            flex-basis: 80%;
        }
    }

    @media all and (max-width: $bp-sm-bio) {
        &__view-all {
            //min-width: 80px;
            text-align: center;
            padding: 7px 0;

            &::after {
                display: none;
            }
        }
    }
}

$industry-filter-width: 250px;

.recoveries-filters-dropdown {
  border: 1px solid $color-lightish-gray;
}

.recovery-filters {
  padding-bottom: $spacing-unit;

  .search-filter-button {
    background-color: $color-brand-darkblue;
    color: $color-brand-white;
    display: block;
    font-family: $font-family-nimb;
    font-size: 1.2rem;
    font-weight: $font-weight-bold;
    margin-bottom: $spacing-unit;
    padding: 0.5rem 0.5rem;
    text-align: center;
    transition: $base-animation;
    border-radius: $site-border-radius;
    &:hover,
    &:disabled {
      background-color: $color-brand-lightblue;
    }
  }
  .recovery-filters-dropdowns {
    display: inline-block;
  }

  .data-type-toggle-wrapper {
    display: inline-block;
    margin-left: 20px;
    vertical-align: middle;
    margin-bottom: 5px;

    input {
      width: auto;
    }
  }
  .data-custom-date-filter {
    .search-filter-custom-date-input-container {
      margin-top: 15px;
    }
    .search-filter-custom-date-input {
      display: inline-block;
      margin-right: 15px;
      label {
        margin-right: 1em;
      }
    }
    @media screen and (max-width: $bp-sm) {
      .search-filter-custom-date-input {
        align-items: center;
        display: inline-flex;
        flex: 1;
        margin-top: 25px;
        width: 100%;
        label {
          margin-right: 1em;
          min-width: 30px;
        }
        input {
          flex-grow: 3;
        }
      }
      .search-button-container {
        width: 100%;

        button {
          width: 100%;
        }
      }
    }
  }
  .recovery-industry-filter-wrapper {
    border: 1px solid $color-lightish-gray;
    background-color: $form-input-bg;
    display: inline-block;
    width: $industry-filter-width;
    margin-left: 20px;
    border-radius: $site-border-radius;

    .recovery-industry-filter {
      &.active {
        .industry-filter-list {
          max-height: 1000px;
        }

        .expand-icon:before {
          transform: translate3d(0, -50%, 0);
        }
      }
    }

    ul {
      margin: 20px 0 0 0;
    }

    .recovery-industry-filter-selected {
      padding: 0.75rem 1rem;
      color: $color-brand-lightblue;
      font-weight: $font-weight-bold;
      font-size: 1.1rem;
      line-height: 1.1;
      font-family: $font-family-nimb;
      position: relative;
      cursor: pointer;
      transition: all 0.2s;

      .expand-icon {
        &:before,
        &:after {
          content: '';
          display: inline-block;
          color: $color-brand-lightblue;
          background-color: $color-brand-lightblue;
          width: 12px;
          height: 2px;
          position: absolute;
          right: 1rem;
          top: 50%;
          transform: translate3d(0, -50%, 0);
        }

        &:before {
          transform: translate3d(0, -50%, 0) rotate(90deg);
          transition: transform 0.4s;
        }
      }
    }

    .industry-filter-list {
      @extend %clearfix-micro;
      overflow: auto;
      margin: 0;
      max-height: 0;
      transition: all 0.5s;
      position: absolute;
      z-index: 1;
      background-color: $form-input-bg;
      width: $industry-filter-width;
    }

    .industry-filter-item {
      margin: 0 1.2rem 0 0;
      padding: 0 0 1rem 1rem;
      clear: both;
      position: relative;

      &:last-child {
        padding-bottom: 1rem;
      }
    }

    .industry-filter-item-checkbox {
      position: absolute;
      vertical-align: top;
      width: auto;
      float: left;
      display: inline-block;
      margin: 0.3rem 0 0;
      padding: 0;
      border: 1px solid $form-input-border;
    }

    .industry-filter-item-name {
      color: $color-black;
      font-size: 0.88rem;
      display: block;
      padding: 0 20px;
      position: relative;
      word-wrap: break-word;
      word-break: break-word;
    }
  }

  .recovery-filters-dropdown {
    position: relative;
    display: inline-block;
    border: 1px solid $color-lightish-gray;
    background-color: $form-input-bg;
    padding: 0.75rem 1rem;
    vertical-align: top;
    border-radius: $site-border-radius;

    select {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: transparent;
      opacity: 0;
      z-index: 5;
      cursor: pointer;
    }
  }

  .recovery-filters-dropdown-label {
    display: block;
    color: $color-brand-lightblue;
    font-weight: $font-weight-bold;
    font-size: 1.1rem;
    line-height: 1.1;
    font-family: $font-family-nimb;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:after {
      @include font-awesome-icon;
      content: '\f107';
    }
  }
}

@media screen and (max-width: $bp-sm) {
  .recovery-filters {
    .data-type-toggle-wrapper {
      margin-left: 0;
      margin-top: 1rem;
      display: flex;
      align-items: center;
    }
  }
}

@media screen and (max-width: $bp-sm-portrait) {
  .recovery-filters {
    .recovery-industry-filter-wrapper {
      margin-left: 0;
      width: 100%;
    }
    .recovery-filters-dropdown {
      width: 100%;
      margin-bottom: 10px;
    }
    .recovery-filters-dropdown-label {
      &:after {
        @include font-awesome-icon;
        content: '\f107';
        position: absolute;
        right: 1rem;
        top: 22%;
        font-size: 1.5rem;
        line-height: 1;
      }
    }
  }
  .recovery-filters-dropdowns {
    width: 100%;
  }
}

.professional-header {
	background-color: $color-brand-work-dark;
	color: $color-light-gray;
	padding: 50px 0;

	.picture {
		float: left;

		img {
			width: 100%;
		}
	}

	.contact-info {
		float: left;
	}
	
}
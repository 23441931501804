.blog-landing {
    &-filters {
        display: flex;
        flex-direction: row;
        gap: 1rem;

        .search-box {
            flex: 3
        }

        .select-dropdown {
            flex: 1;
            margin-bottom: 2rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            &-label:after {
                position: inherit;
            }
        }

        @media all and (max-width: $bp-md) {
            flex-direction: column;

            .search-box {
                margin-bottom: 0;
            }

            .select-dropdown {                
                padding: 15px 20px;
                
                &-label {
                    line-height: 1.5;

                    &:after {
                        position: absolute;
                        top: 18px;
                    }
                }
            }
          }
    }
}
.site-header {
	$tcon-size: 28px !default;
	$tcon-transition: 0s !default;
	$tcon-jstransform: 'tcon-transform' !default;
	$tcon-menu-radius: calc($tcon-size / 14) !default;
	$tcon-menu-color: black !default;
	$tcon-menu-arrowleft-transformation: (transform: scale3d(.8, .8, .8) rotate3d(0, 0, 1, 90deg)) !default;
	$tcon-menu-arrow360left-transformation: (transform: scale3d(.8, .8, .8) rotate3d(0, 0, 1, 360deg)) !default;

	.tcon {
		appearance: none;
		border: none;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		height: $tcon-size;
		transition: $tcon-transition;
		user-select: none;
		width: $tcon-size;
		background: transparent;
		position: relative;
		font-size: 20px;

		>* {
			display: block;
		}

		&:focus {
			outline: none; // see issue #36 https://github.com/grayghostvisuals/transformicons/issues/36
		}
	}

	@mixin tcon-menu-lines {
		display: inline-block;
		height: calc($tcon-size / 10);
		width: $tcon-size;
		transition: $tcon-transition;
		background: $tcon-menu-color;
	}

	.tcon-menu__lines {
		@include tcon-menu-lines;
		position: relative;
		width: calc(#{$tcon-size} - 5px);

		&::before,
		&::after {
			@include tcon-menu-lines;
			content: '';
			position: absolute;
			left: 0;
			transform-origin: calc($tcon-size / 14) center;
			width: $tcon-size;
		}

		&::before {
			top: calc($tcon-size / 4);
		}

		&::after {
			top: calc($tcon-size / -4);
		}

		.#{$tcon-jstransform} & {
			transform: scale3d(.8, .8, .8);
		}
	}


	.mobile-nav-toggle {
		backface-visibility: hidden; //fixes transition flicker bug http://stackoverflow.com/questions/3461441/prevent-flicker-on-webkit-transition-of-webkit-transform

		.menu-container {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate3d(-50%, -50%, 0);
			display: flex;
			align-items: center;
		}

		.tcon-menu__label {
			margin-right: 10px;
		}

		.tcon-menu__label-close {
			display: none;
		}

		&.active {
			.tcon-menu__label-close {
				display: block;
			}

			.tcon-menu__label-menu {
				display: none;
			}
		}
	}

	.tcon-menu--xbutterfly {
		width: 100%;
		height: 100%;

		.tcon-menu__lines {

			&::before,
			&::after {
				transform-origin: 50% 50%;
				transition: top .3s .6s ease, transform .3s ease;
			}
		}

		&.#{$tcon-jstransform} {
			.tcon-menu__lines {
				background: transparent;

				&::before,
				&::after {
					top: 0;
					transition: top .3s ease, transform .3s .5s ease;
					width: $tcon-size;
				}

				&::before {
					transform: rotate3d(0, 0, 1, 45deg);
				}

				&::after {
					transform: rotate3d(0, 0, 1, -45deg);
				}
			}
		}
	}

	// see issue #7 https://github.com/grayghostvisuals/transformicons/issues/7#issuecomment-59813596
	.tcon-visuallyhidden {
		border: 0;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;

		&:active,
		&:focus {
			clip: auto;
			height: auto;
			margin: 0;
			overflow: visible;
			position: static;
			width: auto;
		}
	}
}
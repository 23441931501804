.home-container {
	position: absolute;
	width: 100%;
	// height: $height-home-container;
	
	top: $height-header;
	right: 0;
	z-index: 1;
	box-shadow: 0px -4px 12px 7px rgba($color-dark-gray, .2);
	transition: all 1s;

	@media all and (max-width: $bp-two-tile) {
		height: calc( 85vh + #{$two-tile-height});
	}

	@media all and (max-width: $bp-sm) {
		position: relative;
		height: auto;
		
		top: 0;
	}

	body.ajax-active & {
		right: $width-ajax-content;
	}
}
.blog-post-details {

	.page-title {
		text-transform: none;
	}


	.btn-download-pdf {
		margin: 1rem 0;
	}

	.download-icon {
		font-size: .9rem;
		vertical-align:middle;
		margin-right: .5rem;
	}

    .blog-authors-side-list {
        .key-contact{
            width: 100%;
            height: 262px;
        }
    }

    .related-blog-posts-section{
        margin-top: 2.4rem;
    }
}
.littlefoot {
  --button-background-color: hsla(0,0%,43%,.2);
  --button-active-background-color: #6e6e6e;
  --button-border-radius: 0.3rem;
  --button-height: 1rem;
  --popover-font-size: 1rem;
  --popover-text-color: inherit;
  --popover-font-family: inherit;
  --button-margin: 0 .1em 0 .2em;
  --button-padding: 0.35em;

  top: -0.1em;

  button:focus {
    outline: none;
  }

  &__content {
    font-family: inherit;
  }
}
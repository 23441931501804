.careers-nav {
    font-size: 0;
    line-height: 0;

    .mobile-hidden {
        display: none;
        height: 0;
    }

    ul {
        margin-bottom: 0;
    }

    .careers-nav-section {
        display: inline-block;
        width: 33.3%;
        padding-right: .2rem;
        font-size: 1rem;
        line-height: 1rem;
        vertical-align: top;
        transition: all .5s;

        &.selected {
            width: 50%;
            height: auto;

            .section-close-btn {
                opacity: 1;
            }

            .section-links {
                opacity: 1;
                transition: opacity .2s, max-height .6s;
                transition-delay: 0.1s, 0s;
                max-height: 400px;
            }
        }

        &.selected-mobile {
            width: initial;

            .section-close-btn {
                opacity: 1;
            }

            .section-links {
                opacity: 1;
                transition: opacity .2s, max-height .6s;
                transition-delay: 0.1s, 0s;
                //max-height: 600px;
            }
        }

        &.not-selected-mobile {
            width: initial;
        }

        &.not-selected {
            width: 25%;
            height: 365px;
        }

        @media all and (max-width: 1400px) {
            display: block;
            width: auto;
            margin-bottom: .25rem;

            .section-wrapper {
                height: auto;
            }

            &.selected {
                width: auto;
                height: auto;

                .section-links {
                    opacity: 1;
                    max-height: 500px;
                }
            }

            &.not-selected {
                width: auto;
            }
        }
    }

    .section-wrapper {
        padding: 2rem;
        height: 365px;
        overflow: hidden;
        position: relative;
        border-radius: $site-border-radius;
        background-color: $color-brand-values;

        &.highlight {
            &-blue {
                background-color: $color-brand-navy;
            }
            &-dark-blue {
                background-color: $masonry-dark-blue;
            }
            &-light-blue {
                background-color: $masonry-lt-blue;
                --careers-nav__section-color: #{$color-footer-gray};
            }
            &-light-gray {
                background-color: $masonry-lt-gray;
                --careers-nav__section-color: #{$color-footer-gray};
            }
            &-light-yellow {
                background-color: $masonry-lt-yellow;
                --careers-nav__section-color: #{$color-footer-gray};
            }
            &-medium-blue {
                background-color: $masonry-med-blue;
            }
            &-medium-gray {
                background-color: $masonry-md-gray;
                --careers-nav__section-color: #{$color-footer-gray};
            }
            &-medium-yellow {
                background-color: $masonry-md-yellow;
            }
            &-orange {
                background-color: $color-brand-orange;
            }
            &-white {
                background-color: $color-brand-white;
                --careers-nav__section-color: #{$color-footer-gray};
                border: 1px solid rgba($color-brand-black, 0.3);
            }
            &-yellow {
                background-color: $color-brand-yellow;
            }
        }
    }

    .section-close-btn {
        position: absolute;
        right: 0;
        top: 0;
        opacity: 0;
        width: 1.5rem;
        height: 1.5rem;
        background-color: rgba(0, 0,0, .3);
        transition: opacity .4s;

        &:after {
            content: '+';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0) rotate(45deg);
            color: #fff;
            font-size: 2rem;
            line-height: 1rem;
            font-family: $font-family-nimb;
        }
    }

    .section-header {
        font-family: $font-family-nimb;
        font-size: 1.75rem;
        line-height: 1;
        font-weight: $font-weight-bold;
        color: var(--careers-nav__section-color, $color-black);
        margin-bottom: 1.5rem;

        @media all and (max-width: 1400px) {
            font-size: 2.25rem;
        }
    }

    .section-link {
        margin-bottom: 0;
        padding-bottom: .8em;
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
    }

    .section-links {
        width: 100%;
        opacity: 0;
        columns: 2;
        font-size: 1.125rem;
        line-height: 1.5rem;

        @media all and (max-width: $bp-sm-landscape-iphone) {
            columns: 1;
        }

        a {
            color: var(--careers-nav__section-color, $color-brand-black);
            &:hover, &:focus {
                text-decoration: underline;
            }
        }
    }
}

﻿.about-initiative{
  .pro-bono{
    display: none;
    @media screen and (min-width: $bp-sm) {
      display: block;
      position: absolute;
      right: 5%;  
    }

    &-mobile{
      display: block;
      padding: 5px;
      @media screen and (min-width: $bp-sm) {
        display: none;
      }  
    }
  }
}

.service-detail {
  background: $form-input-bg;

  .page-hero {
    margin-top: 0;
  }

  .site-wrapper {
    background: none;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__main {
    flex: 3;

    > section {
      margin-bottom: 2rem;
    }
  }

  &__aside {
    flex: 1;
    margin: 0 -1rem;

    section {
      margin-bottom: 3rem;
    }

    h2 {
      font-weight: 400;
      font-size: 2rem;
    }
  }

  &__aside-content {
    background: $color-white;
    padding: 1rem 1rem 0 1rem;
  }

  &__service-header {
    margin-bottom: 2rem;
    font-weight: $font-weight-semibold;
  }

  &__header {
    margin-bottom: 2rem;
    font-weight: $font-weight-thin;
  }

  .page-tools {
    position: relative;
    right: auto;
    margin-right: 0;
    display: flex;
    justify-content: flex-end;

    &-icon:first-child {
      padding-left: 0;
    }

    &__container {
      margin-bottom: 1rem;      
      .lang-dropdown {
        position: relative;
        right: auto;
        width: 100%;
        &-wrap {
          flex: 3;
        }
      }
    }

    &-wrap {
      flex: 1;
    }

    @media screen and (min-width: $bp-md) {
      justify-content: flex-start;
    }
  }

  &__view-more {
    font-size: 1rem;
    padding: 1rem 0;
    color: $color-links;

    &:hover {
      color: $color-links-hover;
    }
  }

  &__read-more {
    &:hover {
      img {
        transform: translateX(10px);
      }
    }

    img {
      transition: transform 0.5s;
      height: 13px;
    }
  }

  .card-grid {
    &__further-reading {
      background: none;
    }
  }

  .service-listing {
    font-family: $font-family-nimb;

    ul {
      margin: 0;
    }
  }

  .recognition {
    font-family: $font-family-nimb;

    &__image {
      display: flex;
      justify-content: center;

      img {
        max-width: 150px;
      }
    }

    &__item {
      margin-bottom: 1rem;
    }

    &__title {
      font-weight: $font-weight-bold;
      margin-bottom: 5px;
    }

    &__description {
      a {
        text-decoration: underline;
      }
    }
  }

  @media screen and (min-width: $bp-md) {
    &__container {
      flex-direction: row;
    }

    &__aside {
      margin: 0 0 0 3rem;
      padding: 2rem 2rem 0 2rem;
    }
  }
}

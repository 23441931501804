.splash {
  background-color: rgba($color-black, 0.75);
  visibility: hidden;

  .splash-content {
    position: absolute;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    overflow: hidden;
    margin: auto;
    padding: 0;
    width: 80%;
    max-width: 790px;
    max-height: 550px;

    background-repeat: no-repeat;

    @media (max-width: $bp-sm-landscape-iphone) {
      width: 100%;
    }

    .background-image {
      width: 100%;
    }

    .body-container {
      position: absolute;
      width: 96%;
      bottom: 10%;
      margin: 0 2% 0 2%;
      color: #fff;
      background-color: rgba(32, 54, 111, 0.6);

      @media (max-width: $bp-sm-landscape-iphone) {
        width: 100%;
        height: 98%;
        margin: 0;
        top: 0;
        left: 0;
      }
    }

    .body {
      margin: 35px;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;

      @media (max-width: $bp-sm) {
        flex-wrap: wrap;
      }

      @media (max-width: $bp-sm-landscape-iphone) {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin: auto;
      }

      .continue {
        order: 3;
        cursor: pointer;

        @media (max-width: $bp-sm) {
          display: none;
        }
      }

      .text {
        order: 2;
        margin-left: 20px;

        font-family: 'Oswald', $font-family-nimb;
        font-size: 1.25rem;
        line-height: 1.5rem;

        @media (max-width: $bp-sm) {
          margin: 40px 0 0 0;
        }

        @media (max-width: $bp-sm-landscape-iphone) {
        }

        a {
          font-size: 1rem;
          color: #edb21c;
          cursor: pointer;
        }
      }

      .title {
        order: 1;
        font-family: 'SimpleType', $font-family-primary;
        font-size: 4.75rem;
      }
    }

    .close {
      position: absolute;
      top: 0;
      right: 0;
      margin: 27px 27px 0 0;
      cursor: pointer;
      z-index: 10;

      @media (max-width: $bp-sm) {
        margin: 2% 2% 0 0;
      }
    }
  }
}

.no-scroll {
  overflow: hidden;
}

.is-shown-splash {
  visibility: visible;
}

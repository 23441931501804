.explore-cases {
  text-align: center;
  padding: 2rem 0;
  background-color: #f0f0f0;

  &.general{
    padding: 0;
  }

  .explore-cases-header {
    @include section-header;
    font-size: 2rem;
  }

  .case-details-other {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #f0f0f0;
    margin: 0;
    text-align: left;
    background-color: #fbfbfb;
    &:first-of-type {
      border-top: 1px solid #f0f0f0;
    }

    &.general{
      background-color: #efefef;
      border-bottom: 1px solid #cecece;
    }

    &:hover {
      cursor: pointer;
      background-color: #fff;

      &.general{
        background-color: #efefef;
      }

      .expand-icon,
      .expand-icon:after {
        background-color: #548bb4;
      }

      .case-name {
        color: #548bb4;
      }

      .case-name.general {
        color: black;
      }
    }
  }

  .case-name {
    position: relative;
    font-family: $font-family-nimb;
    font-weight: $font-weight-bold;
    font-size: 1.5rem;
    color: #878787;
    transition: all 0.2s;
    padding-right: 2.5rem;

    &.general{
      font-family: $font-family-primary;
      font-size: inherit;
      color: black;
    }

    &.active {
      color: #548bb4;

      .expand-icon:after {
        left: 0;
        transform: translate3d(0, 0, 0) rotate(0deg);
      }

      .expand-icon,
      .expand-icon:after {
        background-color: #548bb4;
      }
    }
  }

  .expand-icon,
  .expand-icon:after {
    position: absolute;
    width: 1.25rem;
    height: 0.2rem;
    background-color: #d8d8d8;
    transition: background-color 0.2s, left 0.4s, transform 0.4s;
  }

  .expand-icon {
    right: 1rem;
    top: 50%;
    transform: translate3d(0, -50%, 0);
  }
  .expand-icon:after {
    content: '';
    font-size: 0;
    line-height: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0) rotate(90deg);
  }

  .case-content {
    padding-top: 1.6rem;
    display: none;

    p:empty {
      display: none;
    }
  }

  ul {
    &.general{
      list-style-type: none;
    }
  }
}

@media print {

	%print-display {
		display: block !important;
	}

	%print-display-none {
		display: none !important;
	}


	html,
	body {
		font-size: 10pt;
		line-height: 1.4em;
	}


	* {
		background: transparent !important;
		box-shadow: none !important;

	}

	.rich-text {
		ul {
			padding-left: 1em;

			li {
				list-style-type: square;
				list-style-position: outside;
			}
		}

		ol {
			padding-left: 2em;

			li {
				list-style-type: decimal;
				list-style-position: outside;
			}
		}
	}

	h1 {
		font-size: 2rem;
	}

	h2 {
		font-size: 1.2rem;
	}

	h3 {
		font-size: 1.1rem;
	}

	h4 {
		font-size: 1.05rem;
	}

	h5 {
		font-size: 1rem;
	}

	h6 {
		font-size: .95rem;
		line-height: 1.2;
	}

	p {
		margin-bottom: 1.2rem;
	}

	.content-block {
		margin-bottom: 1.5rem;
	}

	.site-banner-design {
		display: none;
	}

	.article {
		padding-bottom: 0.9rem;
		margin-bottom: 0.9rem;
	}

	.site-header {
		position: relative;
		border: none;

		.mobile-nav-toggle,
		.search-toggle,
		.header-nav {
			display: none;
		}

		.header-logo {
			margin-left: 0;

			img {
				width: 325px;
			}
		}
	}

	.header-nav,
	.site-footer,
	.site-subnav,
	.person-card-bar {
		display: none;
	}

	.site-title {
		padding-bottom: 1.5rem;
		margin-bottom: 0;
		border: none;
	}

	.site-subnav+.site-content {
		margin-top: 0;
	}

	.site-content {
		position: static;
		margin-top: 0 !important;


		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-family: Arial, sans-serif;
			text-transform: none;
		}

		.key-contacts {

			.key-contact {
				width: 100%;
				padding: .25rem;
				margin: 0;
			}

			a {
				color: $color-text;
				font-family: arial;
			}
		}

		.experience {
			.article-client-name {
				font-weight: bold;
				font-size: 1rem;
			}

			.article-title {
				font-family: $font-family-primary;
				font-style: italic;
				font-weight: normal;
				color: $color-text;
			}
		}

		.col-content {
			float: right;
			width: 70%
		}

		.col-sidebar {
			float: left;
			width: 25%;

			.list {
				padding-left: .2rem;
			}
		}

		.read-more-content .read-more-hide {
			@extend %print-display;
		}

		.side-list {
			padding: 0;
			border: 0 none;
		}

		.site-wrapper {
			padding: 1.5rem 0;
			width: 100%;
			max-width: none;
		}

		.people-card {
			margin-bottom: 1.25rem;
			border-top: 1px solid gray;

			.site-wrapper {
				padding: 0;
			}

			.person-card-info {
				float: none;
				padding-top: 1.5rem;
			}

			.person-level {
				text-transform: none;
				font-family: Arial;
			}

		}

		.list-bullet-section {
			border-bottom: 0 none;

			ul {
				padding-left: 1rem;
			}

			.bulleted-item {
				left: 0;

				p {
					display: inline;
					margin: 0;
					padding: 0;
				}
			}
		}

		.list-bullet-subheader {
			font-size: 1.2rem;
			font-family: Arial;
			text-transform: none;
			margin-bottom: 0.8rem;
		}

		section.list {
			padding-left: .5rem;
			list-style-type: square;
			list-style-position: outside;


			.article {
				display: list-item;
				margin-bottom: .75rem;
				margin-left: 1rem;
				padding-bottom: 0;
				border-bottom: 0 none;
				color: $color-brand-lightblue;
			}

			.article-note {
				color: $color-text;
			}
		}
	}

	.site-anchor-tabs {
		display: none;
	}

	body {
		padding: 0;
	}

	.btn-read-more,
	.key-contacts-see-all {
		@extend %print-display-none;
	}

	.read-more-content .read-more-hide {
		@extend %print-display;
	}

	.people-card {
		background-color: #F2F1F1 !important;
		border: 1px solid $color-content-border;

		.person-img {
			position: static;
			top: auto;
			left: auto;
			float: left;
			width: 180px !important;
			height: auto !important;


			>img {
				max-height: none;
				position: relative;
				top: 0;
				display: block;
				transform: translateY(0);
				width: 180px;
				margin: -1px;
			}
		}
	}

	.person-card-info {
		float: none;
		width: auto;
		padding-left: 0;
		margin-left: 180px;
		color: $color-text;

		a {
			color: $color-text !important;
		}

		h1,
		.person-level {
			color: $color-brand-blue;
		}
	}

	.person-offices-list {
		overflow: hidden;
	}

	.person-img+.person-card-info {
		padding-left: 30px;
		margin-left: 160px;
		border-left: 0 none;
	}


	.key-contacts {

		.key-contact {
			width: 50%;
			padding: 0.25rem;
			margin: 0;
		}

		.key-contact-info {
			position: static;
			padding: 0 0 1rem;
			margin-left: 25%;
			color: $color-text;
		}

		.key-contact-picture {
			float: left;
			width: 20%;
		}

		.hidden-container {
			max-height: 100%;
		}
	}

	.key-contact-picture {
		width: 70%;
	}

	//history page
	@page {
		margin: 2cm 1cm 1cm 1cm;
	}

	.history-block {
		padding-left: 0;
		padding-top: 0;
	}

	.history-diamond {
		position: relative;
		margin: 0 auto $spacing-unit;

		.path {
			stroke-dashoffset: 0 !important;
		}
	}

	.history-blocks-list {
		position: relative;


		.history-block-line {
			display: none;
		}
	}

	.history-block-content {
		margin-top: $spacing-block-unit;
	}

	.history-dates-sticky {
		padding: 1rem 0;
		font-size: 1.1rem;
		position: static !important;
		width: 100% !important;
		margin-top: 0 !important;
	}

	//recognition
	.recognition-item {
		display: block;

		.recognition-text {
			width: 100%;
		}
	}

	.perspectives-search {

		.search-box,
		.search-filter,
		.btn-load-more,
		.article__bg-img {
			display: none;
		}

		.article {
			list-style-type: none;
			margin-left: 0 !important;
			padding-left: 0 !important;
		}
	}

	.grid-content-sidebar--all {
		display: none;
	}

	.grid-content-main--all {
		width: 100%;
		max-width: 1150px;
		margin: 0 auto;
	}
}
.h1,.h2,.h3,.h4,.h5,.h6 {
	font-family: $font-family-nimb;
	line-height: 1.1em;
}

.h1 {
	font-size: 2.8em;
}

.h2 {
	font-size: 1.7em;
}

.h3 {
	font-size: 1.5em;
}

.h4 {
	font-size: 1.3em;
}

.h5 {
	font-size: 1em;
}

.h6 {
	font-size: .8em;
}

.fa {
	cursor: pointer;
	font-size: 1.2em;
}

.fa-search, .fa-bars {
	display: inline-block;
	
	@include vertical-align;
}

.catch-float {
	overflow: hidden;
}

.close-icon {
	display: inline-block;
	font-size: 1.2em;

	@include vertical-align;
}

// .fa-file-pdf-o {
// 	background-color: $color-pdf;
// 	color: $color-white;
// 	border-radius: 20px;
// }

.team {
	em {
		color: $color-brand-team;
	}
}

.values {
	em {
		color: $color-brand-values;
	}
}

.work {
	em {
		color: $color-brand-work;
	}
}

.rte-block {
	h2 {
		font-family: $font-family-nimb;
		font-size: 1.6em;
		font-weight: $font-weight-bold;
		color: $color-black;
		margin: 20px 0;
	}
}

.vertical-align {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.spacing-base {
	margin-bottom: $spacing-unit;
}

.screenreader-only {
	clip: rect(0 0 0 0); 
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap; 
	width: 1px;
}
@keyframes animateDiamond {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.history-block {
  position: relative;
  padding-left: 32%;

  @media all and (max-width: $bp-sm) {
    padding-left: 0;
    padding-top: 0;
  }
}

.history-diamond {
  $diamond-width: 104px;

  position: absolute;
  z-index: 5;
  left: 0;
  text-align: center;
  background-color: $color-brand-white;
  width: 153px;
  height: 153px;

  @media all and (max-width: $bp-sm) {
    position: relative;
    margin: 0 auto;
  }

  .history-date {
    @include vertical-align;

    position: absolute;
    display: block;
    width: 100%;
    text-align: center;
    font-family: $font-family-nimb;
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 1;
    color: $color-brand-blue;
    z-index: 6;
    transition: color 0.5s ease-in 1s;
  }

  .svg-diamond {
    position: relative;
    z-index: 6;

    .path {
      stroke-dasharray: 1000;
      stroke-dashoffset: 1000;
    }
  }

  .animate-triangle & {
    .path {
      animation: animateDiamond 3s ease-out forwards;
    }

    .history-date {
      color: $color-brand-white;
    }

    &:after {
      opacity: 1;
    }
  }

  &:after {
    content: '';
    width: 106px;
    height: 104px;
    position: absolute;
    left: 77px;
    top: 2px;
    display: block;
    z-index: 5;
    background-color: $color-brand-blue;
    transform: rotate(45.5deg);
    transform-origin: 0 0;
    opacity: 0;
    transition: opacity 0.5s ease-in 1s;
  }
}

.history-blocks-list {
  position: relative;

  .history-block-line {
    position: absolute;
    background-color: $color-content-border;
    top: 0;
    left: 76px;
    width: 2px;
    z-index: 1;

    @media all and (max-width: $bp-sm) {
      left: 50%;
      margin-right: -5px;
    }
  }
}

.history-block-content {
  border-bottom: 1px solid $color-content-border;
  margin-bottom: $spacing-block-unit;
  background-color: $color-brand-white;
  position: relative;
  z-index: 5;

  @media all and (max-width: $bp-sm) {
    margin-top: $spacing-block-unit;
  }

  .rich-text {
    margin-bottom: $spacing-block-unit;
  }
}

.history-block-image {
  margin-bottom: $spacing-unit;
}

.history-dates {
  margin-bottom: $spacing-unit * 2;
}

.history-dates-sticky {
  border: 1px solid $color-content-border;
  border-width: 1px 0;
  padding: 1.5rem 0;

  @media all and (max-width: $bp-sm) {
    padding: 1rem 0;
    font-size: 1.1rem;
  }

  .is-sticky & {
    position: fixed;
    z-index: 50;
    width: 90%;
    top: 10vh;
    max-width: $site-content-width;
    margin-top: 49px;
    background-color: $color-brand-white;

    @media all and (max-width: $bp-sm) {
      top: $height-header-sm;
    }
  }
}

.history-date-link {
  font-family: $font-family-nimb;
  font-weight: bold;
  font-size: 1.2rem;
  color: $color-brand-blue;
  line-height: 1;
  display: inline-block;
  margin-right: 1%;
  padding-right: 2%;
  border-right: 2px solid $color-content-border;

  &:last-child {
    border-right: none;
  }
}

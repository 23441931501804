.people-card {
  background-color: $color-brand-blue;
  position: relative;
  color: $color-text-graylight;
  font-size: 1rem;
  line-height: 1.5;

  margin-bottom: $spacing-unit * 1.5;

  @media all and (max-width: $bp-md) {
    margin-bottom: $spacing-unit;
  }

  a {
    color: $color-brand-white;

    &:hover {
      color: $color-brand-team;
    }
  }

  .site-wrapper {
    background-color: transparent;
    position: relative;
    padding: 2.77778rem $padding-content-wrapper;
    overflow: hidden;

    @media all and (max-width: $bp-md-bio) {
      width: 94%;

      padding: 1.25rem $padding-content-wrapper-md;
    }

    @media all and (max-width: $bp-sm-landscape-iphone) {
      padding: 1rem $padding-content-wrapper-sm;
      width: 100%;
    }
  }

  .person-img {
    position: absolute;
    top: 0;
    left: $padding-content-wrapper;
    height: 100%;

    @media all and (max-width: $bp-md-bio) {
      width: 35%;
      position: static;
      top: auto;
      left: auto;
      float: left;
    }

    @media all and (max-width: $bp-sm-landscape) {
      position: absolute;
      left: 0;
      width: 35%;
    }

    @media all and (max-width: $bp-sm-bio) {
      position: static;
      left: auto;
      margin-right: 5%;
    }

    > img {
      @include vertical-align;

      height: 100%;
      max-height: 380px;

      @media all and (max-width: $bp-lg-bio) {
        max-height: 360px;
      }

      @media all and (max-width: $bp-md-bio) {
        height: auto;
        max-width: 100%;
        position: static;
        top: auto;
        transform: translateY(0);
      }
    }
  }
}

.person-card-info {
  .person-img + & {
    padding-left: 42%;

    @media all and (max-width: $bp-md) {
      padding-left: 50%;
    }

    @media all and (max-width: $bp-md-bio) {
      float: left;
      width: 60%;
      padding-left: 5%;
    }

    @media all and (max-width: $bp-sm-landscape) {
      width: 100%;
      padding-left: 40%;
    }

    @media all and (max-width: $bp-sm-bio) {
      float: none;
      padding-left: 0;

      h1,
      .person-level {
        margin-left: 40%;
      }
    }
  }

  h1 {
    font-size: 2.77777rem; //50px
    margin-bottom: 0.27777rem;

    @media all and (max-width: $bp-sm-landscape) {
      font-size: 2.5rem;
      line-height: 1;
    }
  }

  .person-level {
    display: block;
    font-size: 1.3333rem;
    line-height: 1.2;
    font-family: $font-family-nimb;
    font-weight: $font-weight-bold;
  }

  .phone {
    a {
      color: $color-text-graylight;

      &:hover {
        color: $color-brand-team;
      }
    }
  }
}

.person-card-contact {
  margin-top: $spacing-unit;

  a {
    &:first-child {
      display: inline-block;
      word-wrap: break-word;
      word-break: break-word;
    }
  }
}

.person-office-title {
  display: block;
}

.person-offices-list {
  margin-top: calc($spacing-unit/2);

  .person-office {
    margin-top: calc($spacing-unit/2);

    @media all and (max-width: $bp-sm-landscape) {
      float: none;
      width: 100%;
    }

    &:first-child:last-child {
      width: 100%;
    }
  }
}

.person-card-contact {
  .person-contact-item {
    display: inline-block;
    margin-left: 5px;

    &:before {
      content: '|';
      display: inline-block;
      margin-right: 8px;
      color: $color-brand-white;
    }
  }
}

.person-card-bar {
  background-color: $color-brand-blue;
  position: fixed;
  left: 0;
  top: 2vh;
  width: 100%;
  z-index: 50;
  transition: top 0.2s ease-out;
  overflow: hidden;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: $font-weight-bold;
  line-height: 1.1;
  font-family: $font-family-nimb;
  color: $color-text-graylight;
  -webkit-transform: translate3d(0, 0, 0);

  @media all and (max-width: $bp-sm-landscape) {
    top: 0;
  }

  &.is-inview {
    top: $height-header;

    @media all and (max-width: $bp-md) {
      top: $height-header-sm;
    }
  }
}

.person-card-bar-name {
  font-size: 1.2rem;
  color: $color-brand-white;
  display: inline-block;
  margin-right: 6px;
}

﻿.grid-content-main {

	.exp-display-date {
		margin-bottom: 10px;
		font-size: .8888rem;
		color: $color-text-gray;
	}

	.exp-client-name {
		font-weight: 600;
		
		&+.rich-text {
			margin-top: 10px;
		}
	}

}
.person-item {
  padding-bottom: 1.2rem;

  & + & {
    border-top: 1px solid $color-list-border;
    padding-top: 1.2rem;
  }

  a {
    color: $color-brand-work;
    &:hover {
      color: $color-brand-lightblue;
    }

    &.add-comma::after {
      content: ',';
    }
  }

  @media all and (max-width: $bp-sm-landscape) {
    .col1-5 {
      width: 25%;
    }

    .col2-5 {
      width: 75%;
      padding: 0;
    }
  }

  @media all and (max-width: $bp-sm-landscape-iphone) {
    .col1-5 {
      width: 30%;
    }

    .col2-5 {
      width: 70%;
    }
  }

  &.no-image {
    .col2-5 {
      &:first-child {
        margin-left: 20%;
      }

      @media all and (max-width: $bp-sm-landscape) {
        &:first-child,
        &:nth-child(2) {
          margin-left: 25%;
        }
      }

      @media all and (max-width: $bp-sm-landscape-iphone) {
        &:first-child,
        &:nth-child(2) {
          margin-left: 30%;
        }
      }
    }

    .person-item-desc {
      margin-left: 20%;

      @media all and (max-width: $bp-sm-landscape) {
        margin-left: 25%;
      }

      @media all and (max-width: $bp-sm-landscape-iphone) {
        margin-left: 30%;
      }

      @media all and (max-width: $bp-sm-portrait) {
        margin-left: 0;
      }
    }
  }
}

.person-story-item-info {
  margin-top: 1.5rem;

  @media all and (min-width: 1060px) {
    padding-left: 2rem;
    margin-top: 0;
  }
}

.person-story-item-img {
  overflow: hidden;
  width: 236px;
  height: 288px;

  img {
    width: 100%;
  }
}

.person-item-img {
  border-right: 12px solid $color-brand-white;
  margin-top: 5px;
  position: relative;
  overflow: hidden;
  padding-bottom: 20%;

  @media all and (max-width: $bp-sm-landscape) {
    padding-bottom: 0;
  }

  > img {
    position: absolute;
    width: 100%;

    @media all and (max-width: $bp-sm-landscape) {
      padding-bottom: 0;
      width: auto;
      position: static;
    }
  }
}

.person-item-name {
  color: $color-brand-work;
  font-weight: bold;
}

.person-item-contact-info,
.person-level,
.person-item-office,
.person-item-phone,
.person-item-vcard,
.person-info {
  font-size: 0.83rem;
  display: block;
}

.person-story-item {
  .site-title & {
  }

  .person-level,
  .person-item-office {
    font-family: $font-family-primary;
    font-size: 1.1rem;
  }

  .practice-label,
  .law-school-label {
    font-weight: 600;
    margin-top: 1.5rem;
  }

  .related-practice,
  .practice-label,
  .law-school-label,
  .law-school-name {
    font-family: $font-family-primary;
    font-size: 1.1rem;
    display: block;
  }

  .view-full-bio {
    margin-top: 1.5rem;
    display: block;
    font-family: $font-family-nimb;
    margin-top: 1.5rem;
    font-weight: 600;
    font-size: 1.45rem;

    a {
      color: #548bb4;
    }
  }
}

.vcard-link {
  display: block;
}

.person-item-contact-info {
  padding-left: 2%;
}

.person-item-desc {
  font-size: 0.83rem;
  color: $color-text-gray;
  width: 80%;
  padding-top: $spacing-unit;

  @media all and (max-width: $bp-sm-landscape) {
    width: 70%;
  }

  @media all and (max-width: $bp-sm-portrait) {
    width: 100%;
  }

  &:before {
    content: '';
    display: block;
    width: 30px;
    padding-top: 1px;
    border-top: 1px solid $color-list-border;
    position: absolute;
    margin-top: calc($spacing-unit/-2);
  }
}

.leadership {
  &.list-person {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
  }

  .person-item {
    display: flex;
    flex-direction: column;
    min-width: 360px;

    &.person-item {
      border-top: none;
      padding-top: 0;
    }

    @media all and (min-width: $bp-md-leadership) {
      flex-basis: 48%;
    }
  
    @media all and (min-width: $bp-lger) {
      flex-basis: 31%;
    }

    @media all and (min-width: $bp-sm-portrait) {
      flex-direction: row;
    }
  }

  .person-item-img {
    border-right: unset;
    position: unset;
    overflow: unset;
    padding-bottom: 0;
  
    > img {
      position: initial;
      width: 150px;
      max-width: unset;
    }
  }

  .person-item-contact-info {
    padding-left: 0;
  }

  .person-item-name {
    font-size: 0.889rem;
  }

  .person-item-phone,
  .person-item-vcard {
    font-size: 0.83rem;
  }

  .person-item-email {
    font-size: 0.667rem;
    display: block;
  }
  
  .person-item-info {
    margin-left: 0;

    @media all and (min-width: $bp-sm-portrait) {
      margin-left: 1rem;
    }
  }
  
  .person-item-phone {
    margin-top: 0.5rem;
  }
}
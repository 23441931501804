
// font values
$font-size-base: 16px;

// heights/widths for site
//$height-header: 15vh;
$height-header: 90px;

// $height-header-height: 95px;
$height-header-sm: 70px;

$header-shadow: 0 7px 12px 0 rgba(0, 0, 0, 0.035);
//
$hompage-width-to-height:  0.57;

$four-tile-height: 25vw * $hompage-width-to-height;
$two-tile-height: 50vw * $hompage-width-to-height;
// $height-home-container: 90%;

$height-hero: 70%;
$height-hero-sm: 250px;

$height-home-footer: 30%;

$height-site-footer: 65px;

$width-ajax-content: 65%;

$width-search-button: 100px;

$width-wrapper: 1224px;
$padding-content-wrapper: 2.222rem;
$padding-content-wrapper-md: 1.5rem;
$padding-content-wrapper-sm: 1.25rem;


$padding-section: 90px;
$site-content-width: 1400px;

$site-fluid-padding: 40px;
$site-fluid-padding-lg: 20px;

$site-related-padding: 30px;
$site-related-padding-md: 20px;


$site-main-column: 	69%;
$site-sidebar-column: 24%;

$site-border-radius: 6px;

// breakpoints
$bp-lgx: 1350px;
$bp-lger: 1200px;
$bp-lg: 1115px;
$bp-lg-bio: 1024px;
$bp-md: 960px;
$bp-md-bio: 850px;
$bp-md-leadership: 825px;
$bp-ipad-landscape: 769px;
$bp-md-grid: 767px;
$bp-sm: 700px;
$bp-sm-landscape: 600px;
$bp-sm-landscape-iphone: 480px;
$bp-sm-portrait: 425px;
$bp-sm-bio: 400px;
//stuff that happens at the size of the wrapper width
$bp-wrapper: $width-wrapper; 

$bp-two-tile: $bp-md;
// colors


$color-lightest-gray: #efefef;
$color-light-gray: #ededed;
$color-lightish-gray: #dadada;
$color-gray: #999;
$color-dark-gray: #444;
$color-black: #010101;
$color-footer-gray: #333333;

$color-gold: #dbbc14;
$color-pdf: #e94c3d;

$color-brand-blue: #071c4d;

$color-brand-team: #548bb4;
$color-brand-team-mid: #3c6888;
$color-brand-team-dark: #315671;

$color-brand-values: #f1b403;
$color-brand-values-mid: #bf8e02;
$color-brand-values-dark: #8e6902;

$color-brand-work: #20366f;
$color-brand-work-mid: #152347;
$color-brand-work-dark: #0d162d;



//  Colors
///////////////////////////////////////////////////////////////////////////////
//
//	All Colors
//
///////////////////////////////////////////////////////////////////////////////

$color-white: 						#fefefe;
$color-dark-white: 					#f0f0f0;
$color-brand-white: 				#fff;
$color-brand-black: 				#000;


//Grays
$color-gray-dark:					#494949;
$color-gray-med:					#a0a0a0;
$color-gray-med-alt:				#828282;
$color-gray-light:					#c6c6c6;


//blues
$color-brand-lightblue:				#548bb4; 
$color-brand-lightblue-hover:		#4177a0;
$color-brand-lightblue-alt:		    #2f5775;
$color-brand-darkblue:				#20366f;
$color-brand-darkblue-hover:		#12234e;
$color-brand-lighterblue:			#CDE6F5;
$color-brand-lighterblue-hover:		rgb(182, 211, 229);
$color-brand-navy:					#002F6D;
$color-brand-navy-hover:			rgb(0, 33, 77);

//yellow
$color-brand-yellow:				#f2b303;
$color-brand-yellow-dark:			#e6aa03;
$color-brand-yellow-darkest:		#c28f02;

$color-brand-yellow-dark-alt:		#d8a203;
$color-brand-yellow-darkest-alt:	#c19002;

//orange
$color-brand-orange:				#de7712;
$color-brand-orange-dark:			#ab5b0e;

//green
$color-brand-green:					#72a44d;

//opaque
$opaqueblack: 						rgba(0,0,0,0.8);
$opaquewhite:						rgba(255,255,255,0.5);
$opaquewhiteborder:					rgba(255,255,255,0.5);
$opaquemobilebg:					rgba(30,46,89,0.95);

//Base color
$color-text:						$color-gray-dark;
$color-links:						$color-brand-blue;
$color-links-hover:					$color-brand-lightblue;
$color-links-hover-alt:				$color-brand-lightblue-alt;
$color-sidebar-nav:					$color-gray-med-alt;
$color-text-gray:					#818181;
$color-text-gray-alt:				#999;
$color-text-gray-dark-alt: 			#737373;
$color-text-graylight:				#ccc;
$color-text-filter:					#717171;
$color-accordion:					#878787;

$color-accordion-bg:				#fbfbfb;
$color-accordion-border:			#f0f0f0;


$color-sidebar-border:				#c7c7c6;
$color-content-border:				#cecece;
$color-list-border:					#c7c7c6;

$form-input-border:					#e2e2e2;
$color-load-more-bg:				#e2e2e2;
$form-input-bg:						#f5f5f5;
$form-input-placeholder:			#898b8e;


// Masonry Grid Colors

$masonry-dark-blue: $color-brand-navy;
$masonry-med-blue: #779ECB;
$masonry-lt-blue: #D2E5F3;
$masonry-md-gray: #C3C3C3;
$masonry-lt-gray: #E6E7E8;
$masonry-md-yellow: #F5B324;
$masonry-lt-yellow: #ECC251;
$masonry-lt-green: #B9DFCB;

//  Fonts
///////////////////////////////////////////////////////////////////////////////
//
//	Font Size REMS
//	Base: 16px
// 
///////////////////////////////////////////////////////////////////////////////


$font-base-rem:				112.5%;  //18 pixels
$font-base-rem-table:		100%;	//16 pixels


$font-family-primary:		georgia, serif;
$font-family-nimb: 			"nimbus-sans", Helvetica, sans-serif;
$font-family-nimb-con: 		"nimbus-sans-condensed", Helvetica, sans-serif;


$font-weight-thin:			300;
$font-weight-regular:		400;
$font-weight-semibold:		500;
$font-weight-bold:			700;



///////////////////////////////////////////////////////////////////////////////
//
//	Spacing
//
///////////////////////////////////////////////////////////////////////////////

$spacing-unit:						1.6rem;   //30px
$spacing-block-unit:				$spacing-unit * 2;   //60px


///////////////////////////////////////////////////////////////////////////////
//
//	Z-Indexs
//
///////////////////////////////////////////////////////////////////////////////

$z-index-ajax-content: 100000;
$z-index-fixed-header: 99999;

$z-index-modal-bg:	 100000;
$z-index-modal-content:	 200000;

$z-index-overlay-all: 999999;


///////////////////////////////////////////////////////////////////////////////
//
//	Animations
//
///////////////////////////////////////////////////////////////////////////////

$base-animation-timing:				.3s; 
$base-animation-ease:				ease-in;

$base-animation: 					color $base-animation-timing $base-animation-ease,
									border-color $base-animation-timing $base-animation-ease,
									background-color $base-animation-timing $base-animation-ease;

$fancy-animation-ease:				cubic-bezier(0.86, 0.01, 0.77, 0.78);

$overlay-animation:					opacity .6s $fancy-animation-ease,
									visibility .6s $fancy-animation-ease;
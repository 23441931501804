﻿.typeahead {
  position: absolute;
  background: white;
  display: block;
  width: 100%;
  border: 1px solid #e2e2e2;
  overflow-y: auto;
  z-index: 100;
  transition: all 0.5s;
  max-height: 50vh;
  opacity: 1;
  font-family: $font-family-nimb;
  font-size: 16px;
  line-height: 1.2em;
  border-radius: $site-border-radius;

  ul {
    margin: 0;
  }

  li.selected a {
    color: #494949;
    background-color: darken($color-accordion-bg, 5);
  }

  &.closed,
  &.closed * {
    opacity: 0;
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

#header-site-search .typeahead {
  position: fixed;
  top: $height-header + 10;
  right: 1rem;
  max-width: 800px;
  // top: 20vh
}

.typeahead-item {
  margin-bottom: 0;

  > a {
    display: block;
    padding: 10px;

    &:hover {
      background-color: darken($color-accordion-bg, 5);
    }
  }
}

.home-hero {
  position: relative;
  // height: $height-hero;
  $starting-height: calc(100vh - #{$height-header});
  //$starting-height: 100vh - $height-header;

  height: calc(#{$starting-height} - #{$four-tile-height});

  @media all and (max-width: $bp-two-tile) {
    height: calc(#{$starting-height} - #{$two-tile-height});
  }
  width: 100%;
  overflow: hidden;
  font-family: $font-family-nimb;
  background-color: $color-black;
  background-size: cover;
  background-position: 50% 50%;
  cursor: pointer;

  &.work {
    @media all and (max-width: 1024px) {
      .bg video {
        display: none;
      }
    }
  }

  @media all and (max-width: $bp-sm) {
    position: relative;
    height: $height-hero-sm;
    display: block;
    opacity: 1;

    &.active {
      display: block;
    }

    @media all and (max-height: 600px) {
      height: 300px;
    }

    @media all and (max-height: 500px) {
      height: 200px;
    }
  }

  @media all and (max-width: 500px) {
    height: 180px;
  }
  $hero-title-height: 80px;
  $hero-title-height-lg: 80px;
  $hero-title-height-md: 60px;
  $hero-description-below-amount: 30px;

  .hero-title {
    position: absolute;
    left: 250px;
    font-size: 6em;
    font-weight: 800;
    line-height: 1em;
    z-index: 1;
    color: $color-white;
    bottom: $hero-title-height;

    @media all and (max-width: $bp-lg) {
      bottom: $hero-title-height-lg;
      left: 225px;
      font-size: 4.2em;
    }

    @media all and (max-width: $bp-md) {
      bottom: $hero-title-height-md;
      left: 150px;
      font-size: 4em;
    }

    @media all and (max-width: $bp-sm) {
      bottom: 40px;
      left: 0;
      text-align: center;
      width: 100%;
      font-size: 3.75em;
    }
  }

  .hero-description {
    position: absolute;
    left: 250px;
    font-size: 2.5em;
    z-index: 1;
    color: $color-white;
    bottom: 1em;
    //  $hero-title-height - $hero-description-below-amount;

    @media all and (max-width: $bp-lg) {
      bottom: $hero-title-height-lg - $hero-description-below-amount;
      left: 225px;
      font-size: 1.75em;
    }

    @media all and (max-width: $bp-md) {
      bottom: $hero-title-height-md - $hero-description-below-amount;
      left: 150px;
      font-size: 1.25em;
    }

    @media all and (max-width: $bp-sm) {
      bottom: 15px;
      left: 0;
      text-align: center;
      width: 100%;
      font-size: 1.5625em;
    }
  }

  .prev-page.work:hover,
  .next-page.work:hover {
    background-color: $color-brand-work;
  }

  .prev-page.team:hover,
  .next-page.team:hover {
    background-color: $color-brand-team;
  }

  .prev-page.values:hover,
  .next-page.values:hover {
    background-color: $color-brand-values;
  }

  .prev-page,
  .next-page {
    position: absolute;
    bottom: $hero-title-height;
    padding: 30px;
    background-color: rgba($color-black, 0.7);
    color: $color-gray;
    z-index: 1;
    cursor: pointer;
    font-weight: 600;
    font-size: 1.5em;
    transition: color 0.5s, background-color 0.5s;

    @media all and (max-width: $bp-lg) {
      padding: 20px;
      bottom: $hero-title-height-lg;
    }

    @media all and (max-width: $bp-md) {
      padding: 10px;
      bottom: $hero-title-height-md;
    }

    @media all and (max-width: $bp-sm) {
      display: none;
    }

    &:hover {
      color: $color-white;
    }
  }

  .prev-page {
    left: 0;

    span {
      margin-right: 10px;
    }

    @media all and (max-width: $bp-sm) {
      border-right: 1px solid $color-dark-gray;
    }
  }

  .next-page {
    right: 0;

    span {
      margin-left: 10px;
    }

    @media all and (max-width: $bp-sm) {
      text-align: right;
      border-left: 1px solid $color-dark-gray;
    }
  }

  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;

    video {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      min-height: 100%;
      min-width: 100%;
      background-color: $color-black;
    }

    &.is-center-align {
      video {
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &.is-top-align {
      video {
        top: -5%;
        transform: translate(-50%, 0);

        @media all and (max-height: 725px) and (min-width: 1200px) {
          top: -15%;
        }
      }
    }

    &.is-bottom-align {
      video {
        top: auto;
        bottom: -5%;
        transform: translate(-50%, 0);

        @media all and (max-height: 725px) and (min-width: 1200px) {
          bottom: -15%;
        }
      }
    }
  }
}

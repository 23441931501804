.site-subnav {
  background-color: $color-brand-blue;
  position: fixed;
  left: 0;
  top: $height-header;
  width: 100%;
  z-index: 50;
  font-size: 1rem;
  line-height: 0;
  transition: height 0.2s ease-out;

  @media all and (max-width: $bp-lger) {
    border-bottom: 1px solid lighten($color-brand-blue, 5);
    height: 50px;

    &.is-expanded {
      height: calc(100% - 50px);

      ul {
        max-height: 80%;
        overflow: auto;
        padding-bottom: 2rem;
        margin-top: 40px;
        padding: 0 20px;
      }

      .site-subnav-label {
        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }

  @media all and (max-width: $bp-md) {
    top: $height-header-sm;
  }

  ul {
    @media all and (max-width: $bp-lger) {
      max-height: 0;
      overflow: hidden;
    }
  }
}

.site-subnav-label {
  color: $color-brand-white;
  font-size: 1.2rem;
  font-family: $font-family-nimb;
  font-weight: $font-weight-bold;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  padding: 15px 0;
  display: none;
  cursor: pointer;

  @media all and (max-width: $bp-lger) {
    display: block;
  }

  &:after {
    content: '\f107';
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-left: 5px;
    transition: transform 0.3s linear;
  }
}

.site-subnav-link {
  font-size: 16px;
  color: #fcfcfc;
  font-weight: 400;
  font-family: $font-family-nimb;
  padding: 18px 0;
  margin-right: 32px;
  margin-bottom:0;
  display: inline-block;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  @media all and (max-width: $bp-lger) {
    display: block;
  }

  &:last-child {
    margin-right: 0;
  }

  &.is-active {
    font-weight: 700;
    text-decoration: underline;
  }

  &:hover {
    color: $color-brand-lightblue;
  }
}

.site-subnav-ul {
  background-color: $color-brand-blue;
  position: fixed;
  left: 0;
  top: $height-header;
  width: 100%;
  z-index: 50;
  font-size: 1rem;
  line-height: 0;
  transition: height 0.2s ease-out;
  padding: 0 40px;
}

.site-subnav-li {
  font-size: 16px;
  color: #fcfcfc;
  font-weight: 400;
  font-family: $font-family-nimb;
  padding: 15px 0;
  margin-right: 32px;
  margin-bottom:0;
  display: inline-block;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  @media all and (max-width: $bp-lger) {
    display: block;
  }

  &:last-child {
    margin-right: 0;
  }

  &.is-active {
    font-weight: 700;
  }
}

.timely-topics {
  margin-bottom: $spacing-unit;
  margin-right: -10px;

  @media all and (max-width: $bp-sm) {
    margin-right: 0;
  }
}

.timely-topics-link {
  @extend %nimbusBoldTitle;
  border-radius: $site-border-radius;
  overflow: hidden;
  float: left;
  color: $color-brand-white;
  font-size: 1.2rem;
  position: relative;
  padding-bottom: 25%;
  // border-right: 10px solid $color-brand-white;
  margin-right: 10px;
  margin-bottom: 10px;
  background: $color-brand-blue no-repeat center top;
  background-size: cover;
  width: calc(50% - 10px);

  @media all and (max-width: $bp-sm) {
    width: 100%;
    margin-right: 0;
    padding-bottom: 35%;
  }

  @media all and (max-width: $bp-sm-landscape-iphone) {
    padding-bottom: 50%;
  }

  &:hover {
    color: $color-brand-yellow;

    .timely-topics-label {
      padding-bottom: 1.2rem;
    }
  }
}

.timely-topics-label {
  padding: 0.5rem 0.8rem;
  background-color: $opaqueblack;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transition: padding $base-animation-timing;
}

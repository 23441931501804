

.home {
    .site-wrapper {
        background: none;
        max-width: $site-content-width;
        padding-top: 0;
        padding-bottom: 0;
    }

    .homepage-hero-slides-container{
        display: none;
    }

    .homepage-hero-slide{
        
    }
   

    .homepage-hero {
        position: relative;
        padding: 4rem 0 6rem 0;
        margin-bottom: -5rem;
        color: $color-brand-navy;
        min-height: 60vh;
        overflow: hidden;
        background-size: cover;
        background-position-y: 25%;
        background-position-x: center;
        background-repeat: no-repeat;

        &__mobile {
            display: none;
        }

        @media all and (max-width: $bp-md) {
            display: none;

            &__mobile {
                display: block;

                .homepage-hero__video-container {
                    display: none;
                }
            }
        }
        

        &__video-container {
            overflow: hidden;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
        }

        &__video {
            height: 100%;
            width: 177.77777778vh;
            min-width: 100%;
            min-height: 56.25vw;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &__content {
            position: relative;
            width: 100%;
            max-width: 400px;
            			
            h1 {
                font-size: 2rem;
                font-weight: $font-weight-regular;
                line-height: 1.1;
            }
            div {
                font-size: 1.5rem;
                font-family: nimbus-sans,Helvetica,sans-serif;
                font-weight: 400;
                line-height: 1.2;
                margin: 0 0 0.8rem;
                text-rendering: optimizeLegibility;
            }
        }
		

        &__gradient {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 60%;
            background: rgb(255, 255, 255);
            background: linear-gradient( 0deg, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.01) 100% );
            background: -webkit-linear-gradient( 0deg, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.01) 100% );
        }

        &__header {
            font-size: 3rem;
            margin-bottom: 2rem;
        }

        &__accordion-title {
            font-size: 1.5rem;
            font-weight: $font-weight-bold;
            margin-bottom: 1.5rem;
            text-transform: none;
            position: relative;

            &:hover {
                cursor: pointer;
            }

            &::after {
                @include font-awesome-icon;
                content: $fa-var-angle-down;
                margin-left: 15px;
                font-size: 1.5em;
                display: inline-block;
                vertical-align: bottom;
                line-height: 0.8em;
                transition: transform $base-animation-timing $base-animation-ease;
            }
        }

        &__accordion {
            .homepage-hero__accordion-title::after {
                transform: rotate(180deg);
            }
            &--cookie,
            &--hide {
                .homepage-hero__accordion-title::after {
                    transform: rotate(0deg);
                }
            }

            &--cookie {
                .homepage-hero__list-items {
                    display: none;
                }
            }
        }

        &__list-item {
            margin: 0 0 1rem 0;

            h3 {
                font-size: 1.1rem;
                font-weight: $font-weight-bold;
            }

            p {
                font-size: 0.9rem;
                margin: 0;
                color: $color-black;								
            }

            &:not(:last-of-type) {
                padding-bottom: 1rem;
                border-bottom: 1px solid $color-black;
            }
        }

        &__close {
            padding: 0;
        }

        @media all and (max-width: $bp-md) {
            &__gradient {
                width: 100%;
            }

            &__header {
                font-size: 2rem;
            }

            &__accordion-title {
                font-size: 1.25rem;
            }
        }
    }

    .hero-slide {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        opacity: 0;
        transition: opacity 1s ease-in-out;

        &.active {
            opacity: 1;
        }
    }

    .stats-bar {
        margin: 5rem 0;

        &__flex {
            display: flex;
        }

        &__title {
            font-family: $font-family-primary;
            text-align: center;
            margin-bottom: 2rem;
        }

        &__stat {
            flex: 1 1 auto;
            text-align: center;
            padding: 1rem;

            &:not(:last-child) {
                border-right: 1px solid $color-footer-gray;
            }
        }

        &__number {
            font-family: $font-family-nimb;
            text-rendering: optimizeLegibility;
            font-weight: $font-weight-bold;
            font-size: 5.5rem;
            margin: 0;
            color: $color-brand-navy;
        }

        &__number-link {
            font-family: $font-family-nimb;
            text-rendering: optimizeLegibility;
            font-weight: $font-weight-bold;
            font-size: 5.5rem;
            margin: 0;
            color: $color-brand-navy;
            padding-top: 3rem;
            padding-bottom: 2rem;
        }

        &__description {
            font-family: $font-family-nimb;
            font-size: 1.5rem;
            line-height: 1em;
        }

        @media all and (max-width: $bp-sm) {
            &__flex {
                display: block;
            }

            &__stat {
                &:not(:last-child) {
                    border-right: none;
                }
            }

            &__number {
                font-size: 3.5rem;
            }
        }
    }
}

.btn,
button {
  display: inline-block;
  padding: 10px;
  cursor: pointer;
  background-color: transparent;
  transition: $base-animation;
  font-family: $font-family-nimb;
}

button {
  border: 0 none;
}

.btn-read-more,
.btn-read-less {
  font-weight: $font-weight-bold;
  font-size: 1.3333rem;
  line-height: 1.2;
  font-family: $font-family-nimb;
  color: $color-brand-lightblue;
  padding: 0;

  &:hover {
    color: $color-text;
  }
}

.btn-custom-filter {
  &.btn {
    background-color: $color-brand-lightblue;
    color: $color-brand-white;
    display: block;
    padding: 1.2rem 0.6rem;
    text-align: left;
    border-radius: $site-border-radius;
    text-transform: none;

    &:hover {
      background-color: darken($color-brand-lightblue, 5%);
    }

    &:focus {
      border: 2px solid black;
    }
  }

  .fa {
    float: left;
  }

  .btn-fitler-text {
    display: block;
    margin-left: 30px;
    line-height: 1.1em;
  }
}

.btn-submit {
  background-color: $color-brand-lightblue;
  color: $color-brand-white;

  &:hover {
    background-color: darken($color-brand-lightblue, 10%);
  }

  &.btn {
    padding: 1rem;
  }
}

.btn-clear {
  &.btn {
    padding: 1rem;
  }
}

.btn-grid {
  margin-right: -10px;

  @media all and (max-width: $bp-sm-landscape) {
    margin-right: 0;
  }

  .btn {
    background-color: $color-brand-lightblue;
    padding: 0.75rem 1rem;
    color: $color-brand-white;
    font-size: 1rem;
    font-weight: $font-weight-bold;
    text-align: left;
    margin-bottom: 10px;
    border-radius: $site-border-radius;

    &:hover {
      background-color: darken($color-brand-lightblue, 10%);
    }

    @media all and (max-width: $bp-sm-landscape) {
      width: 100%;
      border-right: 0 none;
    }
  }
}

.service-parent {
  &.btn {
    padding: 0;
  }
}

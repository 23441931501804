.focus-areas {
  color: $color-brand-work-mid;
  font-family: $font-family-nimb;

  .focus-areas-wrapper {
    columns: 2;
    column-fill: auto;
    page-break-inside: avoid; /* Firefox */
    break-inside: avoid;

    @media all and (max-width: $bp-sm-landscape) {
      columns: 1;
    }

    .col1-3 > & {
      columns: 1;
    }
  }

  .focus-areas-three-column {
    columns: 3;
    // column-fill: auto;

    @media all and (max-width: $bp-sm-landscape) {
      columns: 1;
    }
  }

  .focus-area {
    color: $color-brand-team;
    font-weight: bold;
    border-top: 1px solid #d5d5d5;
    box-shadow: 0 1px 0 0 #d5d5d5;
    margin: 0;
    padding: 18px 10px 18px 0;
    width: 100%;
    transition: color 0.2s;
    position: relative;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;

    &:hover {
      color: #494949;
      .focus-arrow {
        margin-left: 15px;
      }
    }

    .focus-area-name {
      font-size: 1.33rem;
      line-height: 1.33rem;
    }

    .focus-arrow {
      font-size: 2rem;
      line-height: 1rem;
      margin-left: 8px;
      padding-bottom: 10px;
      vertical-align: bottom;
      position: absolute;
      transition: margin-left 0.2s, color 0.2s;
    }
  }
}

//Services Landing Page
.services-landing {
  margin-right: -3%;

  .col1-3 {
    margin-right: 3%;
    width: 30.33333%;
  }

  @media all and (max-width: $bp-md-grid) {
    .col1-3 {
      width: 50%;

      &:first-child {
        width: 100%;

        .focus-areas-wrapper {
          columns: 2;
        }
      }
    }
  }

  @media all and (max-width: $bp-sm) {
    .col1-3 {
      width: 100%;

      &:first-child {
        .focus-areas-wrapper {
          columns: 1;
        }
      }
    }
  }

  .focus-areas {
    .focus-area-name {
      font-size: 1.15rem;
    }

    .focus-arrow {
      font-size: 1.5rem;
      line-height: 1.2rem;
    }
  }
}

.authors {
	margin-top: 10px;

	.author {
		&:after {
			content: ',';
			color: $color-text;
		}

		&:last-child {
			&:after {
				content: '';
			}
		}
	}

	li, a {
		color: $color-brand-lightblue-alt;
		transition: $base-animation;
		text-decoration: underline;
		display: inline-flex;
		margin: 0;

		&:hover {
			color: $color-brand-blue;
			font-weight: bold;
		}
	}

	ul {
		display: inline;
	}
}

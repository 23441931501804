.modal-content {
	display: none;
}

body.is-active-modal {
	overflow: hidden;
}

.modal-bg {
	background-color: $opaqueblack;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	visibility: hidden;
	opacity: 0;
	height: 0;
	transition: $overlay-animation;

	.is-active-modal & {
		opacity: 1;
		visibility: visible;
		display: block;
		z-index: $z-index-modal-bg;
		height: auto;
        padding: 2em;
	}

	.is-removing-modal & {
		opacity: 0;
		visibility: hidden;
	}	
}

.modal-wrapper  {
	background-color: $color-brand-white;
	position: fixed;
	visibility: hidden;
	opacity: 0;
	z-index: $z-index-modal-content;
	
	top: 0;
	left: 50%;
	max-width: 640px;
	width: 100%;
	padding: 15px 0 15px 20px;
	height: 0;
	overflow: auto;
	transform: translate(-50%, -100%);
	transition: transform .6s $fancy-animation-ease,
				top .6s $fancy-animation-ease,
				opacity .6s $fancy-animation-ease,
				visibility .6s $fancy-animation-ease;

	&.modal-wrapper-wide {
		max-width: 960px;
	}

	iframe,
  	object,
  	embed  {
		width: 100% !important;
		
		@media all and (max-width: $bp-sm-landscape) {
			height: auto !important;
		}
	}

	@media all and (max-width: $bp-md) {
		width: 90%;
	}			

	.modal-content {
		display: block;
		height: 100%;
		padding-right: 20px;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;

		p {
			margin: 0;
			@media all and (max-width: $bp-md) {
				font-size: 90%;
			}
		}

		@media all and (max-width: $bp-md) {
			max-width: 80%;
			margin: 0 auto;
		}

		@media all and (max-width: $bp-sm-landscape) {
			max-width: 100%;
		}
	}

	.is-active-modal & {
		opacity: 1;
		visibility: visible;
		top: 10%;
		transform: translate(-50%, 0);
		height: auto;
    	max-height: 80%;
		padding: 2em;
			
		@media all and (max-width: $bp-md) {
			padding: 60px 5px 15px 25px;
		}
	}

	.is-removing-modal & {
		opacity: 0;
		visibility: hidden;
		transform: translate(-50%, -100%);
		top: 0;
	}

	h2 {
		font-size: 2rem;
	}

	.rich-text {

		font-size: .95rem;
		line-height: 1.4;

		img {
			max-width: 100%;
			margin-bottom: $spacing-unit;
		}
	}
}

.video-modal-wrapper {
	position: fixed;
	visibility: hidden;
	opacity: 0;
	z-index: $z-index-modal-content;
	padding: 0 50px 0 0;
	top: 0;
	left: 50%;
	height: 0;
	overflow: hidden;
	transform: translate(-50%, -100%);
	transition: transform .6s $fancy-animation-ease, top .6s $fancy-animation-ease, opacity .6s $fancy-animation-ease, visibility .6s $fancy-animation-ease;


	@media all and (max-width: $bp-sm-landscape) {
		width: 90%;
	}

	.modal-content {
		display: block;
		height: 100%;
		overflow-y: auto;
	}

	.is-active-modal & {
		opacity: 1;
		visibility: visible;
		top: 10%;
		transform: translate(-50%, 0);
		//height: 485px;
		height: auto;
		padding: 2em;
		background-color: $color-brand-white;

		@media all and (max-width: $bp-md-grid) {
			height: 80%;
		}
	}

	.is-removing-modal & {
		opacity: 0;
		visibility: hidden;
		transform: translate(-50%, -100%);
		top: 0;
	}
}

.btn-modal-close {
	position: absolute;
	right: 0;
	top: 0;
	background-color: $color-brand-blue;
	color: $color-brand-white;	
	font-size: 2rem;
	padding: 2px 12px;
	
	@media all and (max-width: $bp-sm-landscape) {
		padding: 2.5px 7.5px;
		font-size: 1.5rem;
	}

	&:focus{
		border: 2px solid $color-brand-lightblue;
	}
}

#email-modal {
	position: fixed;
	margin: -100px 0 0 -40%;

	a.btn {
		padding: 10px;
	}
}


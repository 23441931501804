@keyframes slideUp {
	from {
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1; 
	} 
}

.site-anchor-tabs {
	background-color:$color-brand-lightblue-alt;
	position: fixed;
	left: 0;
	bottom:0;
	width: 100%;
	z-index: 50;
	font-size: 1rem;
	line-height: 1.2;
	transition: max-height .4s ease;
	transform: translateY(100%);
	animation: slideUp .5s ease-out 1.5s forwards;

	&.is-unanchored {
		position: static;
		bottom: auto;
		left: auto;
		bottom: auto;
		

		@media all and (max-width: $bp-sm) {
			margin-top: 0;
		}
	}

	// & + .site-footer {

	// 	@media all and (max-width: $bp-sm) {
	// 		margin-top: 50px;
	// 	}
	// }

	@media all and (max-width: $bp-sm) {
		max-height: 50px;

		&.is-expanded {
			max-height: 500px;

			nav {
				display: block;
			}

			.site-anchor-tabs-label {
				&:after {
					transform: rotate(180deg);
				}
			}
		}
	}

	nav {

		white-space: nowrap;
  		overflow-x: auto;
		overflow-y: hidden;

		@media all and (max-width: $bp-sm) {
			display: none;
			overflow: hidden;
			white-space: wrap;
			overflow-x: auto;
		}
	}

	ul {
		margin: 0rem;
	}

}

.site-anchor-tabs-label {
	color: $color-brand-white;
	font-size: 1.1111rem;
	font-family: $font-family-nimb;
	font-weight: $font-weight-bold;
	text-rendering: optimizeLegibility; 
	padding: 15px 0 12px;
	cursor: pointer;
	display: none;


	@media all and (max-width: $bp-sm) {
		display: block;
	}


	&:after {
		content: '\f106';
		display: inline-block;
		font: normal normal normal 14px/1 FontAwesome;
		font-size: inherit;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		margin-left: 5px;
		transition: transform .3s linear;
	}
}

.site-anchor-tabs-link {
	
	color: $color-brand-white;
	font-size: 1.1111rem;
	font-family: $font-family-nimb;
	font-weight: $font-weight-bold;
	padding: 15px 0 12px;
	margin-right: 5%;
	display: inline-block;
	text-rendering: optimizeLegibility; 
	border-bottom: 3px solid transparent;
	opacity: .8;

	@media all and (max-width: $bp-md) {
		margin-right: 4%;
	}


	&:last-child {
		margin-right: 5%;
	}

	&.is-empty {
		display: none;
	}

	&:hover,
	&.is-active {
		color: $color-brand-white;
		border-bottom-color: $color-brand-white;
		opacity: 1;

		@media all and (max-width: $bp-sm) {
			border-bottom-color: transparent;
		}
	}

	&.is-active {
		border-bottom-color: $color-brand-white;
	}

	@media all and (max-width: $bp-sm) {
		display: block;

		&.is-active {
			border-bottom-color: transparent;
		}
	}
}

.site-anchor-tabs-nostyle{
	margin: none;
	margin-right: 5%;
	padding: none;
	display:inline;
}
.btn {
  font-family: $font-family-nimb;
  font-size: 1.3333rem;
  text-align: center;
  font-weight: $font-weight-bold;
  padding: 1.2rem 0;
}
.recognition-item {
  .btn-read-more {
    padding-top: 0;
  }
}

.btn-icon {
  font-size: 1rem;
  display: inline-block;
  margin-right: 0.5rem;
  vertical-align: middle;
}

.btn-load-more,
.btn-general {
  background-color: #e2e2e2;
  display: block;
  transition: background-color 0.2s;
  width: 100%;
  border-radius: $site-border-radius;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 8px 0;
  color: $color-brand-navy;

  &:hover {
    background-color: #ddd;
  }
}

.btn-general {
  & + & {
    margin-top: $spacing-unit;
  }
}
.btn-careers-emails {
  background-color: #e2e2e2;
  padding: 1rem;
  display: block;
}

.btn-get-directions {
  background-color: $color-brand-values;
  color: $color-brand-black;
  width: 100%;
  border-radius: $site-border-radius;

  &:hover {
    background-color: $color-brand-yellow-dark;
  }

  .btn-icon {
    vertical-align: top;
  }

  .fa {
    margin-right: 5px;
  }
}

.btn-download-pdf {
  background-color: $color-brand-lightblue-alt;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.75rem 1.25rem;
  border-radius: $site-border-radius;

  &:hover {
    background-color: $color-brand-blue;
  }
}

.btn-subscribe {
  background-color: $color-brand-lightblue-alt;
  color: $color-brand-white;
  display: block;
  width: 100%;
  margin-bottom: calc($spacing-unit/2);

  &:hover {
    background-color: $color-brand-blue;
  }

  &.is-active-toggle {
    background-color: $color-dark-gray;
  }
}

.subscribe-icon {
  width: 20px;
  vertical-align: top;
  margin-right: 4px;
}

.connect {
  position: relative;
}

.connect-info {
  @extend .small-text;

  display: none;
  background-color: $color-dark-white;
  padding: 1rem;
  color: $color-text-gray;
  position: absolute;
  top: 100%;
  opacity: 0;
  transition: opacity 0.25s 0.2s;
  z-index: 99;

  &.is-active-toggle-content {
    opacity: 1;
  }

  .rich-text {
    margin-bottom: 10px;
  }
}

.connect-icon-options {
  .connect-icon {
    @extend %nimbusBoldTitle;

    display: block;
    font-size: 1rem;
    margin-top: 10px;

    .fa {
      font-size: 0.9rem;
      width: 20px;
      margin-right: 5px;
    }
  }
}

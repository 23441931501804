﻿.experience-listing {
    &__container {
        column-count: 1;
    }

    &__item {
        font-size: 16px;
        margin-bottom: 1.5rem;
        break-inside: avoid-column;
    }

    &__title {
        font-weight: bold;
        font-family: $font-family-nimb;
        margin-bottom: 5px;
    }

    &__name {
        font-style: italic;
        margin-bottom: 5px;
    }

    &__abstract {
        line-height: 1.6em;
    }

    h2 {
        font-weight: 300;
        margin-bottom: 2rem;
    }

    @media screen and (min-width: $bp-ipad-landscape) {
        &__container {
            column-count: 2;
        }        
    }
}
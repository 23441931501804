.recovery-statistic-tabs-section{
    .recovery-chart-wrapper {
       padding-top: $spacing-unit;
    }
    .recovery-chart-title{
        text-align: center;
    }
    .recovery-stat-chart{
        width: 100%;
        height: 500px;
    }

    .recovery-statistics-tab-content{
        display: none;
        width: 100%;
        &.active {
            display: block;
        }
    }
    .search-filter-button{
        margin-top: 5px;
    }

    @media screen and (max-width: $bp-sm-portrait) {
        .recovery-stat-chart-gauge{
            height: 310px;
        }
        .search-filter-button, li{
            width: 100%;
        }
    }
}
.recovery-item {
	padding: 1.5rem 0;
    border-bottom: 1px solid $color-content-border;

    &:last-child {
        border-bottom: none;
    }

	a {
		color: $color-brand-work;
        
        .fa {
            font-weight: bold;
            padding-left: 5px;
            font-size: 1em;
        }
        &.announcement-link-friendly {
            font-weight: bold;
        }
		&:hover {
			color: $color-brand-lightblue;
		}
	}

    .primary-defendant-name {
	    color: $color-brand-work;
	    font-weight: bold;
    }

    .recovery-item-data-wrapper {
	    font-size: .83rem;
	    display: block;
    }

    .recovery-item-secondary-info {
	    padding-left: 5%;
    }

	@media all and (max-width: $bp-sm-portrait) {
		.col1-2 {
			width: 100%;
            padding-left: 0;
		}
	}
}



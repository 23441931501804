.job-detail-page {
  .cta-link {
    font-family: $font-family-nimb;
    padding: 0.5rem 1rem;
    border: 1px solid $color-brand-navy;
    font-size: 1rem;
    border-radius: 50px;
    margin-bottom: 1rem;
    color: $color-brand-navy;
    display: inline-block;
    white-space: nowrap;
    @include icon-after;

    &:hover {
        color: $color-brand-lightblue;
    }
  }

  .job-title ~ .content-block {
    margin-top: 2rem;
  }

  .department {
    margin-top: 1rem;
    font-style: italic;
  }

  .lawyer-contacts__email,
  .lawyer-contacts__phone {
    color: $color-brand-navy;

    &:hover {
      color: $color-brand-lightblue;
    }
  }
}
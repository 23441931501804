.side-filter {
  border: 1px solid #e2e2e2;
  background-color: #f6f6f6;
  border-radius: $site-border-radius;
  overflow: hidden;

  .side-filter-header {
    font-family: $font-family-nimb;
    color: $color-brand-team;
    font-weight: $font-weight-bold;
    font-size: 1.33rem;
    padding: 1.25rem 1.5rem;
    margin: 0;
  }

  .filter-category {
    border-top: 1px solid #e2e2e2;

    &.active {
      .filter-category-name {
        background-color: $form-input-bg;
      }

      .filter-list {
        max-height: 1000px;
      }

      .expand-icon:before {
        transform: translate3d(0, -50%, 0);
      }
      //sub-service logic - only applies when parent is active
      .filter-item {
        .expand-icon {
          position: absolute;
          width: 12px;
          height: 20px;
          right: -12px;
          cursor: pointer;
          top: 2px;
          &:before,
          &:after {
            transform: translate3d(0, -50%, 0);
            top: 50%;
            right: 0px;
          }
          &:before {
            transform: translate3d(0, -50%, 0) rotate(90deg);
          }
        }
        .filter-item-name {
          padding-right: 0;
        }
        ul {
          max-height: 0;
          overflow: auto;
          transition: all 0.5s;
          margin: 0.75rem 0 0;
          li {
            padding: 0 0 0.75rem 1.2rem;
            &:last-child {
              padding-bottom: 0;
            }
          }
        }
        &.active {
          ul {
            max-height: 1500px;
          }
          .expand-icon {
            &:before {
              transform: translate3d(0, -50%, 0) rotate(0deg);
            }
          }
        }
      }
    }
  }

  .filter-category-name {
    padding: 1rem 1.5rem;
    color: $color-brand-black;
    font-size: 1rem;
    position: relative;
    background-color: white;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background-color: $form-input-bg;
      color: $color-brand-blue;

      .expand-icon {
        &:after,
        &:before {
          background-color: $color-brand-blue;
        }
      }
    }
  }

  .filter-list {
    @extend %clearfix-micro;

    overflow: auto;
    margin: 0;
    max-height: 0;
    transition: all 0.5s;

    &.filter-list-simple {
      max-height: 100%;
      padding-top: 20px;
      background-color: $color-brand-white;
    }

    > input[type='text'] {
      background-color: $color-brand-white;
      color: $color-brand-blue;
      display: block;
      width: 90%;
      margin: 0.4rem auto;
      padding: 0.5rem;
      font-size: 1rem;
    }
  }

  .filter-item {
    margin: 0 1.2rem 0 0;
    padding: 0 0 0.75rem 0.5rem;
    clear: both;
    position: relative;
    &:last-child {
      padding-bottom: 1rem;
    }
  }

  .filter-item-checkbox {
    position: absolute;
    vertical-align: top;
    width: auto;
    float: left;
    display: inline-block;
    margin: 0.3rem 0 0;
    padding: 0;
    border: 1px solid $form-input-border;
  }

  .filter-item-name {
    color: #666666;
    font-size: 0.88rem;
    display: block;
    padding: 0 20px;
    position: relative;
    word-wrap: break-word;
    word-break: break-word;
  }

  .filter-sub-item {
      padding: 12px 0 0 15px;
      display: flex;
      margin-bottom: 0;
  }

  .filter-sub-checkbox {
      width: auto;
  }

  .filter-sub-label {
      font-size: 15px;
      margin-left: 6px;
      color: #666666;
  }

  .expand-icon {
    &:before,
    &:after {
      content: '';
      display: inline-block;
      width: 12px;
      height: 2px;
      background-color: #717171;
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }

    &:before {
      transform: translate3d(0, -50%, 0) rotate(90deg);
      transition: transform 0.4s;
    }
  }
}

.filter-item-radio {
  display: inline-block;
  vertical-align: top;
  width: auto;
  float: left;
  margin: 0.3rem 0 0;
  padding: 0;
}

.filter-list-date {
}
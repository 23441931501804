.site-header {
  display: flex;
  position: fixed;
  height: $height-header;
  top: 0;
  right: 0;
  width: 100%;
  background-color: $color-white;
  transform: translateZ(0);
  z-index: $z-index-fixed-header;
  transition: all 1s;
  box-shadow: $header-shadow;

  @media all and (max-width: $bp-md) {
    justify-content: space-between;
  }

  @media all and (max-width: $bp-md) {
    height: $height-header-sm;
  }

  body.ajax-active & {
    right: $width-ajax-content;
  }

  .header-logo {
    max-width: 300px;
    width: 100%;
    padding-bottom: 1.5rem;
    margin: 0 3rem 0 1rem;
    display: flex;
    align-items: flex-end;
    cursor: pointer;

    @media all and (max-width: $bp-lg) {
      margin-left: $site-fluid-padding-lg;
    }

    @media all and (max-width: $bp-md) {
      padding: 1rem 0;
    }

    @media all and (max-width: $bp-sm) {
      margin: 0 $site-fluid-padding-lg;
      width: 100%;
      max-width: 200px;
      align-items: center;
    }

    img {
      width: 100%;
    }
  }

  &__navigation-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;

    @media all and (max-width: $bp-md) {
      display: none;
    }
  }

  .mobile-nav-toggle {
    display: none;
    float: right;
    height: 100%;
    width: 150px;
    text-align: center;
    cursor: pointer;
    transition: color 0.5s, background-color 0.5s;

    .fa {
      padding-left: 3px;
    }

    // &:hover {
    // 	background-color: $color-brand-blue;
    // 	color: $color-white;
    // }

    @media all and (max-width: $bp-md) {
      display: flex;
    }

    @media all and (max-width: $bp-sm-portrait) {
      width: 70px;

      .tcon-menu__label {
        display: none;
      }
    }
  }

  .header-nav-featured {
    height: 100%;
    font-family: $font-family-nimb;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    &.header-nav-secondary {
      .featured-navitem {
        font-size: 16px;
      }
    }
  }

  .featured-navitem {
    font-size: 1rem;
    transition: all 0.5s;
    letter-spacing: 0.02em;
    font-weight: 500;
    margin: 0 0.5rem;
    padding-bottom: 0.9rem;
    display: flex;
    align-items: flex-end;

    &.is-active {
      font-weight: $font-weight-bold;
    }

    span {
      height: auto;
      line-height: 1em;
    }

    @media all and (max-width: $bp-lg) {
      margin: 0 0.5rem;
    }
  }

  .featured-navitem-search{
    margin: 0;
  }

  $side-menu-width: 300px;

  .header-nav-menu {
    top: $height-header;
    height: calc(100vh - #{$height-header});

    &__mobile {
      display: none;
      // overflow: hidden;
      // visibility: hidden;
      // transition: opacity .6s cubic-bezier(0.86, 0.01, 0.77, 0.78),
      // 			visibility .6s cubic-bezier(0.86, 0.01, 0.77, 0.78);
    }

    @media all and (max-width: $bp-md) {
      display: block;
      position: fixed;
      top: $height-header-sm;
      height: calc(100vh - #{$height-header-sm});
      width: 100%;
      right: 0;
      max-width: 100%;
      font-family: $font-family-nimb;
      background-color: $color-accordion-bg;
      transition: opacity 0.6s cubic-bezier(0.86, 0.01, 0.77, 0.78),
        visibility 0.6s cubic-bezier(0.86, 0.01, 0.77, 0.78);
      padding: 2rem;
      opacity: 0;
      overflow: hidden;
      visibility: hidden;

      &.active {
        visibility: visible;
        opacity: 1;
        z-index: 2;

        .header-nav-menu__mobile {
          display: block;
          // visibility: visible;
          // opacity: 1;
        }
      }
    }
  }

  .header-nav-menu {
    @media all and (max-width: $bp-md) {
      .header-nav-featured {
        visibility: visible;
        display: block;
      }
    }

    @media all and (max-width: $bp-md) {
      .header-nav-featured {
        display: flex;
        flex-direction: column;
        height: auto;
        font-size: 32px;
        padding: 1rem 0;

        .featured-navitem {
          margin: 0 0 1.5rem 0;
          padding: 0;
          font-size: 1.5rem;

          span {
            padding-left: 0;
          }
        }
      }

      .header-nav-secondary {
        .featured-navitem {
          font-size: 1.25rem;
          margin: 0 0 1rem 0;
          font-weight: $font-weight-thin;
        }
      }
    }

    span {
      padding-left: 1em;
    }
  }

  .menu-navitem {
    display: block;
    font-size: 1.2em;
    font-weight: $font-weight-bold;
    -webkit-font-smoothing: antialiased;
    height: auto;
    background-color: transparent;
    text-align: left;
    padding: 18px 0;
    color: $color-brand-lightblue;

    &:hover {
      background-color: $color-brand-blue;
      color: $color-white;
    }

    &.is-active {
      font-weight: $font-weight-bold;
    }
  }

  .featured-menuitem {
    font-size: 1.4em;
    background-color: $color-dark-white;
    border-bottom: 1px solid $form-input-border;
  }

  .search-toggle {
    height: 100%;
    text-align: center;
    cursor: pointer;
    width: $width-search-button;
    transition: color 0.5s, background-color 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    &__search {
      width: 20px;
    }

    @media all and (max-width: $bp-sm) {
      width: 70px;
    }

    @media all and (max-width: $bp-sm-portrait) {
      width: 50px;
    }

    &:hover {
      color: $color-brand-lightblue;
    }
  }

  .active {
    .search-toggle__search {
      display: none;
    }

    .fa-bars {
      display: none;
    }
  }

  &.is-mobilenav-active .header-nav-featured {
    // transition: visibility 1s cubic-bezier(0.86, 0.01, 0.77, 0.78);
    // visibility: hidden;
    // display: none;
  }

  .mobile-site-search {
    .search-input {
      background-color: $color-accordion-bg;
      color: $color-brand-black;
      font-weight: $font-weight-thin;
      border-color: $color-brand-blue;

      &::placeholder {
        color: $color-gray-dark;
      }
    }

    .site-search {
      &__text {
        background: none;
        margin: 0 0 1rem 0;
      }
    }
  }
}

a.skip-main {
  left:-999px;
  position:absolute;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
  z-index:-999;
}

a.skip-main:focus, a.skip-main:active {
    color: #fff;
    background-color: $color-brand-blue;
    left: auto;
    top: auto;
    width: 30%;
    height: auto;
    overflow:auto;
    margin: 10px 35%;
    padding:5px;
    border-radius: 1px;
    border:2px solid $color-brand-lightblue-alt;
    text-align:center;
    font-size:1.2em;
    z-index:999;
}

.fullscreen-dim {
  position: fixed;
  width: 100vw;
  height: 100vh;
  right: 0;
  top: 0;
  z-index: 1;
}

body.is-mobilenav-active {
  overflow: hidden;
}

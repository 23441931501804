.insights-featured {
  position: relative;
  display: block;
  color: $color-brand-white;
  cursor: pointer;

  img {
    display: block;
  }

  &:hover {
    color: $color-brand-white;

    .insights-featured-content {
      background-color: $color-brand-black;
    }
  }
}

.insights-featured-tag {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  padding: 5px 10px;
  color: $color-white;
  background-color: $color-brand-yellow;
  font-family: $font-family-nimb;
  font-weight: $font-weight-bold;
}

.insights-featured-content {
  background-color: $opaqueblack;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 4% 5%;
  transition: background-color 0.3s ease-in;

  @media all and (max-width: $bp-sm-landscape) {
    position: static;
    left: auto;
    bottom: auto;
  }

  h2 {
    font: $font-weight-bold 1.3333rem/1.4 $font-family-nimb;
    margin-bottom: 0;
  }
}

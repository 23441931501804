.featured-cases {
  font-size: 0;
  line-height: 0;
  text-align: center;
  background-color: #f0f0f0;
  padding: 5rem 0;
  position: relative;

  &:before {
    $triangle-size: 30px;
    content: '';
    font-size: 0;
    line-height: 0;
    display: inline-block;
    border-left: $triangle-size solid transparent;
    border-right: $triangle-size solid transparent;
    border-bottom: $triangle-size solid #f0f0f0;

    position: absolute;
    top: 0;
    left: 50%;
    transform: translate3d(-50%, -100%, 0);
  }

  ul {
    margin: 0;
  }

  .featured-cases-header {
    font-family: $font-family-nimb;
    color: $color-brand-work-mid;
    font-weight: $font-weight-bold;
    font-size: 2rem;
    line-height: 2rem;
    margin-bottom: 2rem;
  }

  .featured-case {
    display: inline-block;
    width: 33.3%;
    min-width: 250px;
    min-height: 13.25vh;
    padding: 0.25%;
    margin: 0;

    &.active {
      .featured-case-title {
        color: #f1b403;
      }
    }

    &:hover {
      cursor: pointer;

      .featured-case-title:after {
        height: 2rem;
      }
    }
  }

  .featured-case-thumbnail {
    // width: 350px;
    width: 100%;
  }

  figure {
    position: relative;
  }

  .featured-case-title {
    position: absolute;
    padding: 0.5rem 1rem;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);

    text-align: left;
    font-family: $font-family-nimb;
    font-weight: $font-weight-bold;
    color: #fff;
    font-size: 1.2rem;
    line-height: 1.2rem;
    &:after {
      content: '';
      display: block;
      height: 0;
      transition: height 0.2s;
    }
  }
}

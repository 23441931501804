.site-search {
	position: fixed;
	display: flex;
	width: 50%;
	height: $height-header;
	top: 0;
	opacity: 0;
	right: 0;
	padding-right: 1rem;
	z-index: $z-index-fixed-header;
	visibility: hidden;
	transition: color .5s, transform .5s, background-color .5s, right 1s, opacity .5s, visibility .5s;
	transform: translateX(100%);

	&__close {
		display: flex;
		align-items: center;
		font-size: 40px;
		background: #fff;
	}

	&__gradient {
		width: 70px;
		background: rgb(255,255,255);
		background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
	}

	&__container {
		position: relative;
		display: flex;
		width: 100%;
	}

	&__text {
		position: relative;
		width: 100%;
		margin: 1rem 1rem 1rem 0;
		background: #fff;
		align-items: center;		
		display: flex;

		.search-input {
			padding-right: 30px;
			background: $color-dark-white;
			color: $color-brand-blue;
			font-size: 1rem;
		}

		.search-button {
			position: absolute;
			right: 0;
			top: 0;
			width: 70px;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			text-align: center;
			cursor: pointer;
		}
	}

	&.active {
		opacity: 1;
		visibility: visible;
		transform: translateX(0);
	}


	@media all and (max-width: $bp-md) {
		visibility: visible;
		display: block;
		opacity: 1;
		margin-bottom: 2rem;
		padding: 0;
		position: relative;
		width: 100%;
		transform: none;
		height: auto;

		.typeahead {
			position: absolute !important;
			top: 100% !important;
		}

		&__text {
			margin: 0;
		}

		&__gradient,
		&__close {
			display: none !important;
		}
	}

	body.ajax-active & {
		right: $width-ajax-content;
	}
	//this is to style the placeholder in the search input
	::-webkit-input-placeholder { /* WebKit browsers */
		color: $color-brand-blue;
		// 	@media all and (max-width: $bp-md) {
		// color: $opaquewhite;
		// 	color:    $opaquewhite;
		// }
	}

	:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
		color: $color-brand-blue;
		opacity: 1;
		//  @media all and (max-width: $bp-md) {
		// color: $opaquewhite;
		// 	color:    $opaquewhite;
		// }
	}

	::-moz-placeholder { /* Mozilla Firefox 19+ */
		color: $color-brand-blue;
		opacity: 1;
		//  @media all and (max-width: $bp-md) {
		// color: $opaquewhite;
		// 	color:    $opaquewhite;
		// }
	}

	:-ms-input-placeholder { /* Internet Explorer 10+ */
		color: $color-brand-blue;
		//  @media all and (max-width: $bp-md) {
		// color: $opaquewhite;
		// 	color:    $opaquewhite;
		// }
	}
}
@media all and (max-width: $bp-md) {
	.site-search {
		&__text {
			display: none;
		}
	}

	.is-mobilenav-active {
		.site-search {
			&__text {
				display: flex;
			}
		}
	}
}
/* FONT PATH
 * -------------------------- */

@font-face {
  font-family: 'FontAwesome';
  src: url('#{$fa-font-path}/fontawesome-webfont.eot?v=#{$fa-version}');
  src: url('#{$fa-font-path}/fontawesome-webfont.eot?#iefix&v=#{$fa-version}') format('embedded-opentype'),
    url('#{$fa-font-path}/fontawesome-webfont.woff?v=#{$fa-version}') format('woff'),
    url('#{$fa-font-path}/fontawesome-webfont.ttf?v=#{$fa-version}') format('truetype'),
    url('#{$fa-font-path}/fontawesome-webfont.svg?v=#{$fa-version}#fontawesomeregular') format('svg');
//  src: url('#{$fa-font-path}/FontAwesome.otf') format('opentype'); // used when developing fonts
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald';
  src: url('#{$fa-font-path}/Oswald-Regular.ttf?v=#{$fa-version}') format('truetype');
  src: url('#{$fa-font-path}/Oswald-Regular.eot?#iefix&v=#{$fa-version}') format('embedded-opentype'),
    url('#{$fa-font-path}/Oswald-Regular.woff?v=#{$fa-version}') format('woff'),
    url('#{$fa-font-path}/Oswald-Regular.ttf?v=#{$fa-version}') format('truetype');
    //url('#{$fa-font-path}/Oswald-Regular.svg?v=#{$fa-version}#oswaldregular') format('svg');
  //src: url('#{$fa-font-path}/Oswald-Regular.otf') format('opentype'); // used when developing fonts
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SimpleType';
  src: url('#{$fa-font-path}/ufonts.com_simpletype.ttf?v=#{$fa-version}') format('truetype');
  src: url('#{$fa-font-path}/ufonts.com_simpletype.eot?#iefix&v=#{$fa-version}') format('embedded-opentype'),
    url('#{$fa-font-path}/ufonts.com_simpletype.woff?v=#{$fa-version}') format('woff'),
    url('#{$fa-font-path}/ufonts.com_simpletype.ttf?v=#{$fa-version}') format('truetype');
    //url('#{$fa-font-path}/fontawesome-webfont.svg?v=#{$fa-version}#fontawesomeregular') format('svg');
  //src: url('#{$fa-font-path}/FontAwesome.otf') format('opentype'); // used when developing fonts
  font-weight: normal;
  font-style: normal;
}
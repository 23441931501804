.sidebar-nav {
  border: 1px solid $color-sidebar-border;
  border-radius: $site-border-radius;
  overflow: hidden;

  li{
    margin: 0;
  }
}

.sidebar-nav-link {
  display: block;
  color: $color-footer-gray;
  font-family: $font-family-nimb;
  font-weight: $font-weight-bold;
  line-height: 1.1;
  padding: 15px;
  border-bottom: 1px solid $color-sidebar-border;
  &:last-of-type {
    border-bottom: none;
  }

  &:hover,
  &.is-active {
    background-color: $color-brand-lightblue-alt;
    color: $color-brand-white;
  }

  &:focus {
    border: 2px solid black;
  }

  
}

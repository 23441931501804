.list {
  color: $color-brand-work-mid;

  .list-title {
    font-size: 2.6rem;
    line-height: 2.6rem;
    margin-bottom: 1rem;
    font-family: $font-family-nimb;
    font-weight: $font-weight-bold;
  }
}

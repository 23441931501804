
.story-authors  {
	margin-bottom: $spacing-unit;
}

.story-author {
	margin-bottom: calc($spacing-unit/2);
	overflow: hidden;

	> img {
		width: 50px;
	}
}

.story-author-link,
.story-author-label {
	margin-left: 60px;
	display: block;
}


.story-author-link {
	@extend %nimbusBoldTitle;

	font-size: 1.25rem;
}

.story-author-label {
	@extend .small-text;
}
.content-tags {
  font-size: 0;
  margin: 1rem 0;

  .content-tags-text {
    font-size: 1rem;
    font-style: italic;
    color: #6e6e6e;
    margin-bottom: 0.75rem;
  }
  .content-tag {
    display: inline-block;
    color: #fff;
    padding: 0.25rem 0.7rem;
    font-size: 1rem;
    background-color: $color-brand-lightblue-alt;
    font-family: $font-family-nimb;
    font-weight: $font-weight-bold;
    margin: 0 0.25rem 0.25rem 0;
    transition: background-color 0.4s;
    border-radius: $site-border-radius;

    &:hover {
      background-color: $color-brand-blue;
    }
  }
}

.page-tools-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1rem;
  @media all and (max-width: $bp-md) {
    justify-content: center;
  }

  .page-tools--right & {
    justify-content: flex-end;
    @media all and (max-width: $bp-md) {
      justify-content: flex-start;
    }
  }
}
.page-tools {
  display: flex;
  align-items: center;

  .fa {
    font-size: 1.5rem;
  }
  &--right {
    margin-bottom: 1rem;
  }
}

.home-ajax-content .page-tools {
  margin: 0;
}

.page-tools-icon {
  font-size: 16px;
  display: block;
  color: $color-links;

  &:hover {
    color: $color-links-hover;
  }

  a {
    display: block;
    padding: 10px;
    transition: color 0.3s ease-in, border-color 0.3s ease-in,
      background-color 0.3s ease-in;
  }
}

.addthis_button_email span,
.addthis_button_linkedin span,
.addthis_button_facebook span,
.addthis_button_twitter span,
.addthis_button_google_plusone_share {
  display: none !important;
}

a#atic_print,
.at3winsvc_print {
  display: none !important;
}

.key-contacts {
	font-size: 0;
	line-height: 0;

	.key-contact {
		position: relative;
		display: inline-block;
		margin: 0 .25rem .25rem 0;
		width: 218px;
		height: 268px;
		overflow: hidden;

		>img {
			width: 100%;
		}

		&:hover {
			.hidden-container {
				max-height: 200px;
			}
		}
	}

	.key-contact-info {
		text-align: left;
		font-size: .88rem;
		line-height: 1.5rem;
		color: #fff;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.7);
		position: absolute;
		bottom: 0;
		padding: 1rem;

	}

	a {
		color: $color-brand-white;

		&:hover {
			color: $color-brand-yellow;
		}
	}

	.key-contact-name {
		font-family: $font-family-nimb;
		font-weight: $font-weight-semibold;
		font-style: normal;
		font-size: 1.12rem;
		transition: color .2s;

	}

	.key-contact-title {
		font-style: italic;
	}

	.hidden-container {
		max-height: 0px;
		transition: max-height .5s;
		overflow: hidden;
	}

	.key-contacts-see-all {
		font-size: 1.33rem;
		line-height: 1.5rem;
		font-family: $font-family-nimb;
		font-weight: $font-weight-bold;
		display: block;
		margin-top: 1rem;
		color: $color-brand-lightblue;
	}

	.see-all-icon {
		font-size: 1.3rem;
		padding-left: .5rem;
	}
}

.key-contact-focusAreas {
	overflow-wrap: break-word;

	a {
		color: $color-brand-white;

		&:hover {
			color: $color-brand-yellow;
		}
	}
}

//service detail redesign
.lawyer-contacts {

	a {
		display: block;
		color: $color-brand-lightblue-alt;

		&:hover{
			color: $color-brand-blue;
			font-weight:bold;
			text-decoration: underline;
		}
	}

	h2 {
		font-size: 2rem;
		line-height: 1.1;
	}

	&__link {
		font-size: 1rem;
		font-weight: 700;
	}


	&__item {
		margin-bottom: 50px;
	}

	&__image {
		background-repeat: no-repeat;
		background-position: left;
		background-size: contain;

		img {
			width: 50%;
			visibility: hidden;
		}
	}
}
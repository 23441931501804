.section-tabs {
  font-size: 0px;
  font-family: $font-family-nimb;
  font-weight: $font-weight-bold;
  margin-bottom: 0.8rem;

  ul {
    display: inline-block;
    margin: 0;
  }
  .section-tab-name {
    display: inline-block;
    padding: 0.5rem 0.75rem;
    border: 1px solid $color-brand-lightblue-alt;
    font-size: 1rem;
    color: $color-brand-lightblue-alt;
    transition: color 0.2s, background-color 0.2s;
    margin: 0;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: $site-border-radius;

    &:last-child {
      margin-right: 0;
    }

    &.active,
    &:hover {
      color: #fff;
      background-color: $color-brand-lightblue-alt;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .featured-topics-label {
    display: inline-block;
    font-size: 1rem;
    line-height: 1em;
    color: $color-brand-work-mid;
    width: 5rem;
    vertical-align: top;
    padding: 5px 1rem;
    border-left: 1px solid $color-brand-work-mid;
    margin-left: 1rem;
    margin-bottom: 0;
  }
}

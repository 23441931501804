.search-filter {
	margin-bottom: $spacing-unit;

	.filter-container {
		display: inline-block;
		margin: 0;
	}

	.filter-label {
		@extend .filter-label-small;

	}

	.filter {
		@extend .small-text;

		display: inline-block;
		margin: 0;
		padding: 5px 0;
		color: $color-text-filter;
		&.clear-all {
			cursor: pointer;
		}

		&:after, &.clear-all:before {
			content: '/';
			display: inline-block;
			margin: 0 5px 0 5px;
			color: $color-text;
		}

		&:last-child, &:nth-last-child(2) {
			&:after {
				content: '';
				margin: 0;
			}
		}
	}

	.fa {
		color: $color-links;

		&:hover {
			color: $color-text;
		}
	}
}
.filter-toggle {
	position: relative;
}
.btn-filter-toggle {
	border: 1px solid $form-input-border;
	padding: .5rem .8rem;
	color: $color-links;

	.fa {
		margin-left: 5px;
		transition: transform .35s linear;
	}

	&:hover,
	&.is-active-toggle {
		background-color: $color-load-more-bg;
	}

	&.is-active-toggle {
		.fa {
			transform: rotate(-180deg);
		}
	}
}

.filter-toggle-options {
	padding: 1rem 0;
	top: 100%;
	left: 0;
	padding: 1rem .8rem 0;
	border: 1px solid $form-input-border;
	width: 100%;
	background-color: $color-brand-white;
	margin-top: -1px;
	display: none;

	.select-dropdown {
		display: block;
		margin-bottom: 1rem;
	}

	.select-dropdown-label {
		padding-right: 1rem;

		&:after {
			position: absolute;
			right: 1rem;
		}
	}

}
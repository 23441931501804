input {
	border: 1px solid $form-input-border;
	background-color: $form-input-bg;
	font-size: 1.3333rem;
	line-height: 1.1;
	font-family: $font-family-nimb;
	padding:15px 20px;
	color: $color-brand-lightblue;
	width: 100%;
	border-radius: 50px;

	@include placeholder {
		color: $color-brand-lightblue;
	}
}

input.datepicker {	
	background-image: url("/Content/public/styles/images/calendar_icon.jpg");
	background-position: right center;
	background-repeat: no-repeat;
}

.search-box {
	position: relative;
	margin-bottom: 2rem;

	button {
		position: absolute;
		right: 22px;
		top: 0;
		height: 100%;
		padding: 0;
		font-size: 20px;
		color: $color-gray-med;
	}

	.fa-search {
		top: auto;
		transform: translateY(0);
	}
}


.form {

	.fillmeout {
		display: none;
	}

	.disclaimer + .form-group {
		margin-top: $spacing-unit;
	}

	legend {
		@extend .title-blue;
		margin-bottom: 10px;
	}

	input[type='text'] {
		font-size: 1.2rem;
		padding: 10px 15px;
	}

	h3 {
		font-size: 1.4rem;
	}		
}

.form-field {
	clear: both;
	margin-bottom: $spacing-unit;

	input[type='checkbox'],
	input[type='radio'] {
		margin: .3rem;
		padding: 0;
		float: left;
		width: auto;


		& + label {
			display: table;
		}
	}

	.select-dropdown {
		display: block;
		width: 80%;	
	}

	.select-dropdown-label {

		&:after {
			position: absolute;
			right: 10px;
		}
	}
}

.form-field.invalid {
	color: red;
	input {
		border: 1px solid red;
	}
}

.form-group {
	border-bottom: 1px dotted $color-content-border;
	padding-bottom: calc($spacing-block-unit/2);
	margin-bottom:$spacing-block-unit;

	&:last-child {
		border-bottom: 0 none;
		padding-bottom: 0;
		margin-bottom:0;

	}
}

.form-btns {
	display: inline-block;
	padding: 1rem 0 1rem 1rem;

	.btn-submit {
		margin-right: 1rem;
	}
}


.sort-label {
	@extend .filter-label-small;
	
	line-height: 1.5;
	width: auto;
}

.alumni-form {
	input[type='text'] {
		text-transform: none;
	}
    .alumni-industry-dropdown {
        .select-dropdown-label {
            height: 21px;
        }
    }


}

.error-message{
	color: red;
}
@mixin highlight($color) {
  background-color: $color !important;

  &:hover {
    background-color: darken($color, 5%) !important;
  }
}

.highlight {
  font-family: $font-family-nimb;
  font-size: 1.2333rem;
  font-weight: $font-weight-bold;
  line-height: 1.2;
  background-color: $color-text;
  display: block;
  color: $color-brand-white;
  padding: 50px $site-related-padding 25px;
  text-rendering: optimizeLegibility;
  border-radius: $site-border-radius;
  transition: all $base-animation-timing $base-animation-ease;

  @media all and (max-width: $bp-md) {
    padding: 40px $site-related-padding-md $site-related-padding-md;
  }

  p {
    margin: 0;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 80px;
    height: 4px;
    background-color: $color-brand-white;
    opacity: 0.75;
    margin-bottom: 10px;
    position: absolute;
    margin-top: -20px;
    transition: opacity $base-animation-timing $base-animation-ease;
  }

  &:hover {
    color: $color-brand-white;
    background-color: darken($color-text, 5%);

    &:before {
      opacity: 1;
    }
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }

  &:focus {
    border: 2px solid black;
  }
  .modal-content {
    display: none;
  }

  > img {
    margin: 15px 0;
    display: block;
  }

  .grid-content-sidebar & + & {
    margin-top: $spacing-unit;
  }

  &__full-image {
    position: relative;
    padding: 10px;

    .highlight-image {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      margin: 0;
      border-radius: $site-border-radius;
    }

    .highlight-title {
      position: relative;
      z-index: 2;
      padding: 20px;
      background-color: rgba(252, 252, 252, 0.85);
      margin: 0;
      border-radius: $site-border-radius - 1;
      color: $color-brand-black;
    }

    &:before {
      display: none;
    }
  }
}

.highlight-nolink {
  //transition: $base-animation;

  pointer-events: none !important;

  &:hover {
    pointer-events: none !important;
  }
}

.highlight-video {
  &:after {
    content: '';
    display: block;
    width: 107px;
    height: 46px;
    margin-top: 15px;
    background: transparent url('~images/highlight-play.png') no-repeat top left;
  }
}

.highlight-modal {
  cursor: pointer;
}

.highlight-white {
  @include highlight($color-brand-white);
}

.highlight-light-green {
  @include highlight($masonry-lt-green);
  color: $color-brand-black;

  &:before {
    background-color: $color-gray-dark;
  }
}

.highlight-blue {
  @include highlight($color-brand-lightblue);
}

.highlight-yellow {
  @include highlight($color-brand-yellow);
  color: $color-brand-black;

  &:before {
    background-color: $color-gray-dark;
  }
}

.highlight-orange {
  @include highlight($color-brand-orange);

  &:before {
    background-color: $color-gray-dark;
  }
}

.highlight-dark-blue {
  @include highlight(#0a1f50);
}

.highlight-blue-light {
  @include highlight($color-brand-lightblue);
}

.highlight-light-blue {
    @include highlight($color-brand-lightblue);
}

.highlight-light-gray {
    @include highlight($masonry-lt-gray);
    color: #333;
    &:hover {
        color: #333 !important;
    }
    &:before{
        background-color: #333;
    }
}

.highlight-blue-mid {
  @include highlight($color-brand-team-mid);
}

.highlight-blue-dark {
  @include highlight($color-brand-blue);
}

//Solving the Whitespace issuewith inlineblock positioning
//http://www.vanseodesign.com/css/inline-blocks/
.highlights-grid {
  overflow: hidden;
  font-size: 0;
  line-height: 0;

  @media all and (max-width: $bp-sm-landscape) {
    margin-right: 0;
  }

  .highlight-image-container {
    position: relative;
    text-align: center;
    padding: 0;

    p {
      padding: 0 1em;
    }

    &:before {
      display: none;
    }

    .highlight-image {
      position: absolute;
      min-height: 100%;
      margin-top: 0;

      @media all and (max-width: $bp-md) {
        min-width: 100%;
      }
    }
  }

  .highlight {
    letter-spacing: 0;
    display: inline-block;
    width: 33.3333%;
    height: 220px;
    vertical-align: top;
    border: 3px solid $color-brand-white;

    border-width: 3px 3px 0 0;
    overflow: hidden;

    @media all and (max-width: $bp-md) {
      width: 50%;
    }

    @media all and (max-width: $bp-sm-landscape) {
      width: 100%;
      border-right: 0;
    }
  }
}

.highlight-resources {
  background-color: $color-brand-yellow;
  background-size: 100% auto;
  color: $color-brand-white;
  vertical-align: top;
  position: relative;
  text-align: center;
  padding: 5px 1em;
  transition: background-color $base-animation-timing $base-animation-ease;

  &:before {
    display: none;
  }

  &:hover {
    color: $color-brand-white;

    &:hover {
      background-color: darken($color-brand-yellow, 5%);
    }
  }

  &:nth-child(2) {
    background-color: $color-brand-yellow-dark;

    &:hover {
      background-color: darken($color-brand-yellow-dark, 5%);
    }
  }

  &:nth-child(3) {
    background-color: $color-brand-yellow-darkest;

    &:hover {
      background-color: darken($color-brand-yellow-darkest, 5%);
    }
  }
}

div.highlight-nolink {
  &:hover {
    color: $color-brand-white;

    &:hover {
      background-color: $color-brand-yellow;
    }
  }

  &:nth-child(2) {
    background-color: $color-brand-yellow-dark;

    &:hover {
      background-color: $color-brand-yellow-dark;
    }
  }

  &:nth-child(3) {
    background-color: $color-brand-yellow-darkest;

    &:hover {
      background-color: $color-brand-yellow-darkest;
    }
  }
}

.highlight-animated {
  padding-top: 30px;
  position: relative;
  text-align: center;

  &:before {
    display: none;
  }
}

.highlight-content-vertical {
  @include vertical-align;

  display: inline-block;
  margin: 0 5px;
  white-space: pre-wrap;
}

.animated-counter-figure {
  font-size: 2.111em;
  line-height: 1;
  font-weight: $font-weight-bold;

  strong {
    font-weight: $font-weight-bold;
  }

  .js-animated-counter-text {
    margin: 0 0.5rem;
    display: inline-block;
  }
}

  .animated-counter-figure {
    display: block;
    margin-bottom: 10px;
  }

.highlight-infograph {
  padding: 0;

  img {
    display: block;
    overflow: hidden;
    margin: 0 auto;
  }

  &:before {
    display: none;
  }
}

.infograph-wrap {
  position: relative;
}

.infographic-overlay {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  clip: rect(500px, auto, auto, 0);
}

.highlights {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;

  &-header {
    &__title {
      font-weight: $font-weight-thin;
    }
    &__subtitle {
      margin-bottom: 0;
    }
  }

  &__tile {
    font-family: $font-family-nimb;
    width: 100%;
    display: flex;
    padding: 10px;
    margin: 20px 0;
    border-radius: $site-border-radius;
    background-color: $color-lightest-gray;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    transition: all $base-animation-timing $base-animation-ease;

    &.js-modal {
      cursor: pointer;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
      }
    }

    &--blue {
      background-color: $color-brand-lighterblue;
    }

    &--dark-blue {
      background-color: $color-brand-navy;

      .highlights__title {
        color: $color-brand-white;
      }
    }

    &--light-blue {
      background-color: $color-brand-lightblue;

      .highlights__title {
        color: $color-brand-white;
      }
    }    

    &--light-gray {
      background-color: $masonry-lt-gray;

      .highlights__title {
        color: #333;
      }
    }

    &--light-yellow {
      background-color: $masonry-lt-yellow;
    }

    &--medium-blue {
      background-color: $masonry-med-blue;

      .highlights__title {
        color: $color-brand-white;
      }
    }

    &--medium-gray {
      background-color: $masonry-md-gray;

      .highlights__title {
        color: $color-brand-black;
      }
    }

    &--medium-yellow {
      background-color: $masonry-md-yellow;
    }
    
    &--orange {
      background-color: $color-brand-orange;

      .highlights__title {
        color: $color-brand-black;
      }
    }

    &--light-green {
      background-color: $masonry-lt-green;

      .highlights__title {
        color: $color-brand-black;
      }
    }

    &--white {
      background-color: $color-brand-white;
    }

    &--yellow {
      background-color: $color-brand-yellow;
    }

    &--link {
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
      }
    }

    &--full-image {
      position: relative;
      justify-content: flex-start;

      .highlights__image {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        padding: .50rem;
      }

      .highlights__title {
        position: relative;
        z-index: 2;
        padding: 20px;
        background-color: rgba(252, 252, 252, 0.85);
        width: 70%;
        height: 95%;
        margin: 0;
        border-radius: $site-border-radius - 1;
        color: $color-brand-black;
      }
    }
  }

  &__title {
    font-size: 1.25rem;
    line-height: 1.1em;
    color: $color-footer-gray;
    padding: 1rem;
  }

  &__image {
    width: 100%;
    max-height: 175px;
    max-width: 175px;
    margin: 0 auto;
    display: block;
    background-color: $color-brand-white;
    background-size: cover;
    background-position: center;
    border-radius: $site-border-radius;
    padding: 0.25rem;

    img {
      visibility: hidden;
    }
  }

  @media screen and (min-width: $bp-ipad-landscape) {
    flex-direction: row;

    &__tile {
      width: calc(50% - 1rem);
    }
  }
}

//typography
html {
  font-size: $font-base-rem;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;

  @media all and (max-width: $bp-md) {
    font-size: 100%;
  }
}

body {
  color: $color-text;
  font-family: $font-family-primary;
}

a {
  color: $color-links;
  transition: $base-animation;

  &:hover {
    color: $color-links-hover-alt;
  }
}

a,
button {
  &:focus {
    outline: 2px solid $color-brand-lightblue;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-nimb;
  font-weight: $font-weight-regular;
  text-rendering: optimizeLegibility;
  line-height: 1.2;
  margin: 0 0 calc($spacing-unit/2);
}

em {
  font-style: italic;
}

h1 {
  font-size: 2.7rem;
  line-height: 1.2;
  font-weight: $font-weight-regular;

  @media all and (max-width: $bp-sm-landscape) {
    font-size: 2rem;
    line-height: 1.3;
  }
}

h2 {
  font-size: 2.5rem;
  line-height: 1.1;

  @media all and (max-width: $bp-sm-landscape) {
    font-size: 1.75rem;
    line-height: 1.25;
  }
}

h3 {
  font-size: 2rem;
  line-height: 1.1;

  @media all and (max-width: $bp-sm-landscape) {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

h4 {
  font-size: 1.8rem;

  @media all and (max-width: $bp-sm-landscape) {
    font-size: 1.25rem;
  }
}

h5 {
  font-size: 1.5rem;

  @media all and (max-width: $bp-sm-landscape) {
    font-size: 1.1rem;
  }
}

h6 {
  font-size: 0.95rem;
  line-height: 1.2;
}

strong,
b {
  font-weight: bold;
}

i {
  font-style: italic;
}

.small-text {
  font-size: 0.85rem;
  line-height: 1.4;
}

.site-content {
  font-size: 1rem;
  line-height: 1.5;
}

%nimbusBoldTitle {
  font-family: $font-family-nimb;
  font-weight: $font-weight-bold;
  line-height: 1.2rem;
}

.content-block-title {
  color: $color-brand-blue;
  margin-bottom: calc($spacing-unit/2);
}

.block-subheader {
  font-weight: $font-weight-bold;
  font-family: $font-family-nimb;
  font-size: 1.5rem;
  line-height: 1.55rem;
  margin-bottom: 0;
}

.filter-label-small {
  font-family: $font-family-nimb;
  font-weight: $font-weight-bold;
  line-height: 1em;
  color: $color-brand-work-mid;
  width: 4rem;
  display: inline-block;
  vertical-align: top;
}

.title-blue {
  color: $color-brand-lightblue;
  font-size: 1.33rem;
  line-height: 1.2;
  font-family: $font-family-nimb;
  font-weight: $font-weight-bold;
  margin-bottom: $spacing-unit;
}

.light-text {
  color: $color-text-gray;
}

.abstract-block-text {
  font-size: 0.9rem;
  line-height: 1.5;
  color: $color-text-gray-alt;
}

.no-spacing {
  margin-bottom: 0;
}

.form-header-title {
  margin-bottom: 5px;
}

.sidebar-title {
  font-size: 1.55rem;
  font-weight: $font-weight-bold;
  margin-bottom: 1rem;
  color: $color-brand-work;
}

.list-accordian {

	.accordian-item {
		margin: 0;
	}

	.accordian-item-container {

		padding: .5rem 0;
		color: $color-brand-team;
		transition: color .2s;

		&:hover {
			cursor: pointer;
			color: $color-brand-team-dark;

			.expand-icon {
				&:before, &:after {
					background-color: $color-brand-team-dark;
				}
			}
		}

		&.active {
			.expand-icon:before{
				transform: translate3d(0, -50%, 0) rotate(0deg);
			}
		}
		position: relative;
	}

	.expand-icon {
		position: relative;

		&:before, &:after {
			content: '';
			width: 10px;
			height: 2px;
			display: inline-block;
			background-color: $color-brand-team;
			position: absolute;
			top: 50%;
			transform: translate3d(0, -50%, 0);
			transition: all .4s;
		}

		&:before {
			transform: translate3d(0, -50%, 0) rotate(90deg);
		}
	}

	.accordian-item-name {
		padding-left:15px;
	}

	.accordian-item-date {
		position: absolute;
		right: 1rem;
		top: 50%;
		transform: translate3d(0, -50%, 0);
	}

	.accordian-rich-text {
		display: none;
	}
}
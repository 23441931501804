﻿$gutter-size: 2%;
$column-size: 31.33333%;
$gap: 1.5rem;

.card-grid {
    position: relative;

    .masonry-grid {
        width: $column-size;
    }

    .masonry-gutter {
        width: $gutter-size;
    }

    &__cards {
        &--latest {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__card {
        position: relative;
        margin-bottom: $gutter-size;
        padding: 35px 25px;
        border-radius: $site-border-radius;
        background: $color-lightest-gray;
        display: flex;
        width: $column-size;
        overflow: hidden;
        color: $color-footer-gray;

        &--services {
            padding: 1rem;
            color: $color-brand-black;
        }

        &--tile-highlight {
            width: 100%;
            margin-top: 20px;

            .card-grid__content {
                flex-basis: 50%;
            }
        }

        &--lg,
        &--md-h {
            width: calc(#{$column-size * 2} + #{$gutter-size});

            .card-grid__content {
                flex-basis: 50%;
            }
        }

        &--md-h-full-overlay {
            width: calc(#{$column-size * 2} + #{$gutter-size});

            .card-grid__content {
                flex-basis: 100%;
            }
        }

        &--dark-blue,
        &--navy {
            background-color: $color-brand-navy;
            color: $color-brand-white;

            .card-grid__abstract {
                a {
                    color: $masonry-med-blue;
                    font-weight: 700;
                }
            }
        }

        &--medium-blue {
            background-color: $masonry-med-blue;
        }

        &--light-blue {
            background-color: $masonry-lt-blue;
        }

        &--medium-gray {
            background-color: $masonry-md-gray;
        }

        &--light-gray {
            background-color: $masonry-lt-gray;
        }

        &--medium-yellow {
            background-color: $masonry-md-yellow;
        }

        &--light-yellow {
            background-color: $masonry-lt-yellow;
        }

        &--light-green {
            background-color: $masonry-lt-green;
        }

        &--latest {
            display: block;
            padding: 25px;
            margin-right: 2%;
        }
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: center;
    }

    &__tile-img {
        height: 176px;
        width: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 4px;
        margin-bottom: 30px;
    }

    &__content {
        background-color: $color-brand-white;
        background-color: rgba(252, 252, 252, 0.85);
        flex-basis: 100%;
        border-radius: $site-border-radius - 1;
        padding: 25px;
        position: relative;
        min-height: 282px;
        line-height: 20px;
        transition: background-color 0.4s;

        &--services {
            padding: 1.5rem;
            min-height: 280px;
        }

        .card-grid--no-image & {
            background: none;
        }
    }

    &__eyebrow-wrapper {
        margin-bottom: 20px;
        line-height: 1;
    }

    &__eyebrow {
        font-family: $font-family-primary;
        font-size: 12px;
        margin-bottom: 2rem;
        position: relative;

        &:not(:last-of-type) {
            &::after {
                content: '';
                height: 12px;
                width: 1px;
                background-color: $color-footer-gray;
                display: inline-block;
                margin: 0 5px -1px 8px;
            }
        }

        &--services {
            font-size: 0.9rem;
        }

        &-type,
        &-service,
        &-date {
            line-height: 1.4;
        }

        &-date {
            font-weight: 700;
        }

        &--topic {
            font-style: italic;
        }
    }

    &__further-reading {
        background: $color-light-gray;
        padding: 2rem;
        width: calc(#{$column-size * 2} + #{$gutter-size});
        border-radius: $site-border-radius;
        margin-bottom: $gutter-size;
        color: $color-brand-black;

        li, a {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-grow: 1;
            margin: 0;

            &:not(:last-of-type) {
                padding-bottom: 1rem;
                margin-bottom: 1rem;
                border-bottom: 1px solid #b7b7b7;
            }

            &:hover {
                h3 {
                    font-weight: bold;
                }
            }
        }

        .card-grid__eyebrow {
            font-size: 0.9rem;
        }
    }

    &__reading-title {
        flex-basis: 60%;
        font-size: 1.1rem;
        font-weight: $font-weight-thin;
        margin: 0;

        a {
            color: $color-brand-black;

            &:hover {
                color: $color-links;
            }
        }
    }

    &__read-more {
        min-width: 100px;
        font-family: $font-family-nimb;
        font-weight: $font-weight-bold;
    }

    &__read-more-link {
        font-family: $font-family-nimb;
        font-weight: $font-weight-bold;
        display: inline;
        color: $color-brand-navy;
        font-size: 16px;
        position: relative;

        &--services {
            min-width: 100px;
            margin-top: 1rem;
        }

        &--video {
            &::after {
                content: url("data:image/svg+xml,%3Csvg width='16' height='15' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.33333 9.88945V5.11056C6.33333 4.84684 6.62508 4.68756 6.84692 4.83017L10.5638 7.21961C10.7679 7.35082 10.7679 7.64919 10.5638 7.7804L6.84692 10.1698C6.62508 10.3125 6.33333 10.1532 6.33333 9.88945Z' fill='%23002F6D'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 1.16667C4.50219 1.16667 1.66666 4.0022 1.66666 7.5C1.66666 10.9978 4.50219 13.8333 8 13.8333C11.4978 13.8333 14.3333 10.9978 14.3333 7.5C14.3333 4.0022 11.4978 1.16667 8 1.16667ZM0.666664 7.5C0.666664 3.44992 3.94991 0.166672 8 0.166672C12.0501 0.166672 15.3333 3.44992 15.3333 7.5C15.3333 11.5501 12.0501 14.8333 8 14.8333C3.94991 14.8333 0.666664 11.5501 0.666664 7.5Z' fill='%23002F6D'/%3E%3C/svg%3E ");
                position: absolute;
                right: -22px;
                bottom: -2px;
            }
        }

        &--download {
            &::after {
                content: url("data:image/svg+xml,%3Csvg width='10' height='14' viewBox='0 0 10 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.46967 9.7803C4.76256 10.0732 5.23744 10.0732 5.53033 9.7803L9.2803 6.03033C9.5732 5.73744 9.5732 5.26256 9.2803 4.96967C8.9874 4.67678 8.5126 4.67678 8.2197 4.96967L5.75 7.43934V0.75C5.75 0.33579 5.41421 0 5 0C4.58579 0 4.25 0.33579 4.25 0.75V7.43934L1.78033 4.96967C1.48744 4.67678 1.01256 4.67678 0.71967 4.96967C0.42678 5.26256 0.42678 5.73744 0.71967 6.03033L4.46967 9.7803ZM0.75 12.0001C0.33579 12.0001 0 12.3359 0 12.7501C0 13.1643 0.33579 13.5001 0.75 13.5001H9.25C9.6642 13.5001 10 13.1643 10 12.7501C10 12.3359 9.6642 12.0001 9.25 12.0001H0.75Z' fill='%23002F6D'/%3E%3C/svg%3E");
                position: absolute;
                right: -20px;
                bottom: 0px;
            }
        }
    }

    &__title {
        font-size: 22px;
        line-height: 24px;
        font-weight: $font-weight-bold;
        margin: 0;
        margin-bottom: 20px;
        display:block;
        font-family: nimbus-sans,Helvetica,sans-serif;

        &--services {
            font-size: 1.5rem;
            font-weight: $font-weight-thin;
        }
    }

    &__abstract {
        margin: 0;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 26px;

        p {
            margin-bottom: 20px;
        }
    }


    @media all and (min-width: $bp-md) {
        @supports (display: grid) {
            &__cards--latest {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: $gap;
                margin-bottom: $gap;

                .card-grid__card {
                    width: auto;
                    margin: 0;
                }
            }

            &__cards--masonry {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: $gap;
                margin-bottom: $gap;

                .masonry-grid,
                .masonry-gutter {
                    display: none;
                }

                .card-grid__card {
                    width: auto;
                    margin-bottom: 0;

                    grid-column-start: var(--card-grid__card-column-start, auto);
                    grid-row-start: var(--card-grid__card-row-start, auto);

                    &--md-h,
                    &--md-h-full-overlay,
                    &--lg {
                        grid-column: span 2;
                    }

                    &--md-v {
                        grid-row: span 2;
                        align-items: flex-end;
                    }
                }

                .card-grid__further-reading {
                    grid-column: span 2;
                    grid-row: span 2;
                    width: auto;
                    margin-bottom: 0;
                }
            }
        }
    }

    @media all and (max-width: $bp-lgx) {
        &__content {
            padding: 1rem;
        }

        &__eyebrow {
            &--services {
                margin-bottom: 1rem;
            }
        }

        &__reading-title {
            font-size: 1.2rem;
        }
    }

    @media all and (max-width: $bp-md) {
        .site-wrapper {
            padding: 0 0.5rem;
        }

        &__cards {
            &--latest {
                display: block;
            }
        }

        &__card {
            padding: 1.5rem;
            display: flex;
            flex-direction: row-reverse;
            margin-bottom: 0.5rem;

            &--md-h,
            &--md-h-full-overlay,
            &--lg,
            &--tile-highlight {
                .card-grid__content {
                    flex-basis: 100%;
                }
            }
        }

        &__bg {
            position: static;
            width: 100px;
            height: 100px;
            flex: 0 0 auto;
            margin-left: 2rem;
            border-radius: $site-border-radius;
        }

        &__tile-img {
            height: 91px;
            width: 25%;
        }

        &__card,
        &__further-reading {
            width: 100%;

            &--lg,
            &--md-h {
                width: 100%;
            }
        }

        &__content {
            background: none;
            padding: 0;
            min-height: initial;
            margin-right: 14px;

            &--services {
                margin-right: 0;
            }
        }

        &__abstract {
            display: none;
        }

        &__reading-title {
            flex-basis: 100%;
        }

        &__read-more {
            display: none;
        }
    }

    @media all and (max-width: $bp-sm-landscape) {


        &__card,
        &__further-reading {
            padding: 1rem;
        }

        &__title {
            font-size: 1.1rem;
        }

        &__reading-title {
            font-size: 1rem;
        }

        &__bg {
            margin-left: 1rem;
            height: 80px;
            width: 80px;
        }
    }
}

a.card-grid__card {
    transition: all $base-animation-timing $base-animation-ease;
    color: $color-footer-gray;

    &--dark-blue,
    &--navy {
        color: $color-white;

        &:hover {
            background-color: $color-brand-navy-hover;
        }

        .card-grid__read-more-link {
            color: $color-white;
        }

        .card-grid__read-more-link--download::after {
            content: url("data:image/svg+xml,%3Csvg width='10' height='14' viewBox='0 0 10 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.46967 9.7803C4.76256 10.0732 5.23744 10.0732 5.53033 9.7803L9.2803 6.03033C9.5732 5.73744 9.5732 5.26256 9.2803 4.96967C8.9874 4.67678 8.5126 4.67678 8.2197 4.96967L5.75 7.43934V0.75C5.75 0.33579 5.41421 0 5 0C4.58579 0 4.25 0.33579 4.25 0.75V7.43934L1.78033 4.96967C1.48744 4.67678 1.01256 4.67678 0.71967 4.96967C0.42678 5.26256 0.42678 5.73744 0.71967 6.03033L4.46967 9.7803ZM0.75 12.0001C0.33579 12.0001 0 12.3359 0 12.7501C0 13.1643 0.33579 13.5001 0.75 13.5001H9.25C9.6642 13.5001 10 13.1643 10 12.7501C10 12.3359 9.6642 12.0001 9.25 12.0001H0.75Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
            position: absolute;
            right: -16px;
            bottom: -2px;
        }

        .card-grid__read-more-link--video::after {
            content: url("data:image/svg+xml,%3Csvg width='16' height='15' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.33333 9.88945V5.11056C6.33333 4.84684 6.62508 4.68756 6.84692 4.83017L10.5638 7.21961C10.7679 7.35082 10.7679 7.64919 10.5638 7.7804L6.84692 10.1698C6.62508 10.3125 6.33333 10.1532 6.33333 9.88945Z' fill='%23FFFFFF'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 1.16667C4.50219 1.16667 1.66666 4.0022 1.66666 7.5C1.66666 10.9978 4.50219 13.8333 8 13.8333C11.4978 13.8333 14.3333 10.9978 14.3333 7.5C14.3333 4.0022 11.4978 1.16667 8 1.16667ZM0.666664 7.5C0.666664 3.44992 3.94991 0.166672 8 0.166672C12.0501 0.166672 15.3333 3.44992 15.3333 7.5C15.3333 11.5501 12.0501 14.8333 8 14.8333C3.94991 14.8333 0.666664 11.5501 0.666664 7.5Z' fill='%23FFFFFF'/%3E%3C/svg%3E ");
            position: absolute;
            right: -19px;
            bottom: -2px;
        }
    }

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    }
}

.card-grid__card {

    &--dark-blue,
    &--navy,
    &--medium-blue,
    &--light-blue,
    &--medium-gray,
    &--light-gray,
    &--medium-yellow,
    &--light-yellow,
    &--light-green,
    &--latest {
        .card-grid__content {
            background: none;
            padding: 0;
        }
    }

    &--dark-blue,
    &--navy {
        .card-grid__eyebrow {
            &:not(:last-of-type) {
                &::after {
                    content: '';
                    background-color: $color-white;
                }
            }
        }
    }
}

.js-modal {
    cursor: pointer;
    transition: all $base-animation-timing $base-animation-ease;

    &.card-grid__card--blue {
        color: $color-footer-gray;

        &:hover {
            background-color: $color-brand-lighterblue-hover;
        }
    }

    &.card-grid__card--dark-blue,
    &.card-grid__card--navy {
        color: $color-white;

        &__eyebrow {
            &:not(:last-of-type) {
                &::after {
                    content: '';
                    background-color: $color-white;
                }
            }
        }

        &:hover {
            background-color: $color-brand-navy-hover;
        }
    }

    &.card-grid__card--yellow {
        color: $color-white;

        &:hover {
            background-color: $color-brand-yellow-dark;
        }
    }

    &.card-grid__card--orange {
        color: $color-white;

        &:hover {
            background-color: $color-brand-orange-dark;
        }
    }

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    }

    &:focus {
        border: 2px solid black;
    }
}


.related-news {
    .card-grid {
        &__cards {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &__further-reading,
        &__card {
            width: calc(50% - 1rem);
            margin-bottom: 2rem;
        }

        &__card {
            background-color: $color-brand-navy;
            color: $color-white;

            .card-grid__read-more-link {
                color: $color-white;
            }

            &:hover {
                background-color: $color-brand-navy-hover;
            }

            &:focus {
                outline: 2px solid $color-footer-gray;
            }

            &.card-grid__card--no-link {
                &:hover {
                    background-color: $color-brand-navy;
                }
            }

            &:nth-child(4n+2),
            &:nth-child(4n+3) {
                background-color: $color-brand-lighterblue;
                color: $color-brand-black;

                .card-grid__read-more-link {
                    color: $color-brand-navy;
                }

                &:hover {
                    background-color: $color-brand-lighterblue-hover;
                }

                &.card-grid__card--no-link {
                    &:hover {
                        background-color: $color-brand-lighterblue;
                    }
                }
            }

            &--no-image {

                &:hover .card-grid__content,
                .card-grid__content {
                    background: transparent;
                }
            }
        }

        &__further-reading {
            h2 {
                font-size: 1.75rem;
                font-weight: $font-weight-thin;
                margin-bottom: 2rem;
            }

            a {
                font-family: $font-family-nimb;
                padding: 1rem 1.5rem;
                border: 1px solid $color-brand-navy;
                font-size: 1rem;
                border-radius: 50px;
                margin-bottom: 1rem;
                color: $color-brand-navy;
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                @include icon-after;

                &:hover {
                    color: $color-brand-lightblue;
                }
            }
        }

        @media all and (max-width: $bp-md) {

            &__card,
            &__further-reading {
                width: 100%;
            }

            &__cards {
                margin: 0 -1rem;
            }

            &__card {
                margin-bottom: 0.5rem;
            }
        }
    }
}

.perspectives-landing {
    .page-title {
        max-width: 1150px;
        margin: 0 auto;
    }
}
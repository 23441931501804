.share-tools {
  $content-color: #a4a4a4;
  $border-color: #cecece;

  border-top: 2px solid $border-color;
  border-bottom: 2px solid $border-color;
  padding: 1.5rem 0rem;
  font-family: $font-family-nimb;
  font-weight: $font-weight-bold;
  font-size: 1.33rem;
  color: $content-color;

  .share-text {
    padding-left: 0.25rem;
  }

  .share-icon,
  .at300b {
    color: $content-color;
    margin-left: 1rem;
    font-size: 1.33rem;

    &:hover {
      color: black;
    }
  }

  .addthis_toolbox,
  .custom_images {
    display: inline-block;
  }

  h2 {
    color: $color-footer-gray;
  }
}

.share-tools-title {
  color: $color-footer-gray;
  font-size: 1.5rem;
  display: inline;
}

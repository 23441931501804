.site-title {
  margin-bottom: $spacing-unit;
  &--flex {
    display: flex;
    justify-content: space-between;
  }
  .page-blog-subheader {
    margin-bottom: 0px;
  }
  &.no-divider {
    border-bottom: none;
    margin-bottom: 0;
  }

  .authors {
    margin-top: 10px;
  }

  .page-date {
    margin-bottom: 10px;
    font-size: 0.8888rem;
    color: $color-footer-gray;
  }

  .page-source {
    margin-bottom: 10px;
    font-size: 0.8888rem;
    color: $color-footer-gray;
    font-style: italic;
  }

  .phone {
    a {
      cursor: pointer;
      color: $color-text;
    }
  }

  .office-intro {
    margin-top: 10px;
  }

  @media all and (max-width: $bp-sm) {
    &--flex {
      display: block;
      padding-bottom: 0.5rem;
      .page-tools-icon {
        padding: 5px;
      }
      .page-title {
        margin-bottom: 0.5rem;
      }
    }
    .office-map-target {
      margin-top: 20px;
    }
  }

  .office-address {
    .col {
      @media all and (max-width: $bp-sm) {
        padding-right: 5%;
      }

      @media all and (max-width: $bp-sm-landscape-iphone) {
        padding-right: 0;
        float: none;
      }
    }
  }
}

.page-title {
  margin-bottom: 0;
  color: $color-brand-blue;
}
.page-subtitle {
  margin-top: 0.5em;
}

.article {
  border-bottom: 1px solid $color-list-border;
  padding-bottom: 1.2rem;
  margin-bottom: 1.2rem;
  line-height: 1.55rem;
  font-size: 1rem;

  &--all {
    border-bottom: 1px solid #bdbdbd;
    padding-bottom: 30px;
    margin-bottom: 30px;
    display: flex;

    .card-grid__abstract {
      @media all and (max-width: $bp-md) {
        display: none;
      }
    }

    .card-grid__read-more-link {
      transition: color 0.2s;

      @media all and (max-width: $bp-md) {
        margin-top: 6px;
      }

      &:hover {
        color: $color-brand-team;
      }
    }

    @media all and (max-width: $bp-md) {
      flex-direction: column-reverse;
    }

    .card-grid__eyebrow-wrapper {
      color: #636466;
    }
  }

  &:last-child {
    border-bottom: 0px none;
  }

  .article-text-wrapper {
    width: 100%;
  }

  .article__bg-img {
    background-position: center;
    background-size: cover;
    width: 67%;
    padding-bottom: 49%;
    border-radius: 5px;
    margin-left: 25px;
    background-repeat: no-repeat;

    @media all and (max-width: $bp-md) {
      width: 100%;
      margin-left: 0;
      margin-bottom: 16px;
    }
  }

  .article-title {
    font-weight: 600;
    transition: color 0.2s;

    &--all {
      font-weight: 700;
      font-family: $font-family-nimb;
      font-size: 22px;
      text-decoration: underline;
      line-height: 24px;
      color: #20366f;
    }

    &.blog-post-title {
      font-size: 1.2rem;
      line-height: 1.5;
    }

    >em {
      font-weight: 600;
    }
  }

  a .article-title:hover {
    color: $color-brand-lightblue-alt;
    font-weight: bold;
    text-decoration: underline;
  }

  .article-date {
    color: #737373;
    font-size: 0.83rem;

    &--all {
      color: #636466;
      font-size: 12px;
      margin-bottom: 12px;
      font-weight: 700;
    }
  }

  .article-client-name {
    display: block;
    font-weight: normal;
  }

  .article-client-name-2 {
    color: $color-black;
    font-weight: 600;
  }

  .article-note,
  .article-source {
    color: #494949;

    >p {
      margin: 0;

      +.read-more-content {
        margin-top: 1.6rem;
      }
    }
  }

  .html-snippet {
    em {
      font-weight: bold;
      font-style: initial;
    }
  }

  .article-source {
    font-style: italic;
  }

  //BOO!!! talk to rene to see why this was added, i had to remove this for the homepage articles
  // p {
  // 	margin: 0;
  // }

  a,
  a:link,
  a:visited {
    color: $color-brand-work;
    display: inline-block;
  }

  .related-authors {
    padding-bottom: 20px;
  }

  .related-authors-list {
    display: inline;
    margin: 0;

    li {
      display: inline-block;
      margin: 0;
    }

    a:hover{
      text-decoration: underline;
      color: $color-brand-lightblue-alt;
    }
  }

  .article-abstract {
    font-size: 0.85rem;

    .abstract-text {
      & p:last-child {
        margin-bottom: 0;
      }
    }

    .article-read-more {
      padding: 5px 0 25px 0;
      display: inline-block;
      font-weight: $font-weight-bold;

      &:hover {
        color: $color-brand-team;
      }

      .fa {
        font-size: 0.9em;
      }
    }
  }

  .article-tags {
    font-size: 0;

    ul {
      margin: 0;
    }

    .article-tag {
      display: inline-block;
      margin: 0 0.25rem 0.25rem 0;

      a {
        padding: 0.25rem 0.7rem;
        color: #fff;
        background-color: $color-brand-lightblue-alt;
        font-family: $font-family-nimb;
        font-weight: $font-weight-bold;
        font-size: 1rem;
        border-radius: $site-border-radius;
        transition: background-color 0.4s;

        &:hover {
          background-color: $color-brand-blue;
        }

        &:focus {
          outline: solid 2px $color-brand-lightblue;
        }
      }
    }
  }
}

.related-professionals {
  &--all {
    margin-top: 10px;

    a,
    a:link,
    a:visited {
      color: $color-brand-lightblue-alt;
      font-family: $font-family-nimb;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-decoration: underline;

      &:hover {
      color: $color-brand-blue;
      }
    }

    @media all and (max-width: $bp-md) {
      display: none;
    }
  }
}
.podcasts-listing {
	margin-bottom: $spacing-unit;
  margin-right: -10px;

	.podcast {
		@extend %nimbusBoldTitle;
		border-radius: $site-border-radius;
		overflow: hidden;
		float: left;
		color: $color-brand-white;
		font-size: 1.2rem;
		position: relative;
		padding-bottom: 25%;
		margin-right: 10px;
		margin-bottom: 10px;
		background: $color-brand-blue no-repeat center top;
		background-size: cover;
		width: calc(50% - 10px);

		@media all and (max-width: $bp-sm) {
			width: 100%;
			margin-right: 0;
			padding-bottom: 35%;
		}

		@media all and (max-width: $bp-sm-landscape-iphone) {
			padding-bottom: 50%;
		}

		&:hover {
			.hidden-container {
				max-height: 200px;
			}

			.podcast-name {
				padding-bottom: 1.2rem;
				color: $color-brand-yellow;
			}
		}
	}

	.podcast-info {
		text-align: left;
		font-size: .88rem;
		line-height: 1.5rem;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.7);
		position: absolute;
		bottom: 0;
		padding: 1rem;
	}

	.podcast-name {
		font-family: $font-family-nimb;
		font-weight: $font-weight-bold;
		font-style: normal;
		font-size: 1.2rem;
		transition: color .2s;
		color: #fff;
	}

	.hidden-container {
		max-height: 0px;
		transition: max-height .5s;
		overflow: hidden;
	}
}

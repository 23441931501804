.experience-block {
	padding-bottom: 1px;
	display: flex;
	justify-content: space-around;

	@media all and (max-width: $bp-sm-landscape) {
		display: block;
	}

	& + & {
		margin-top: $spacing-unit;
	}

	.highlight-company {
		width: 33%;
		flex: 1 33%;
		padding: 15px 4%;
		margin-right: 7%;
		font-size: 2rem;
		line-height: 1.1;
		position: relative;
		background-color: $color-brand-values;

		@media all and (max-width: $bp-sm-landscape) {
			width: 100%;
			flex: 1 100%;
			margin-right: 0;overflow: hidden;
			margin-bottom: calc($spacing-unit/2);

			&:before {
				display: none;
			}
		}

		&:before {
			position: absolute;
			left: 0;
			width: 100%;
			height: 500px;
			top: 100%;
			background-color: $color-brand-values;
			opacity: 1;
			transition: $base-animation;
			margin-top: 0;
		}
	}

	&:nth-child(3n + 2) {
		.highlight-company {
			background-color: $color-brand-yellow-dark-alt;

			&:before {
				background-color: $color-brand-yellow-dark-alt;
			}
		}	
	}

	&:nth-child(3n + 3) {
		.highlight-company {
			background-color: $color-brand-yellow-darkest-alt;

			&:before {
				background-color: $color-brand-yellow-darkest-alt;
			}
		}	
	}
}

.experience-block-title {
	font-weight: bold;
	color:  $color-brand-work;
	display: inline-block;
	margin-bottom: calc($spacing-unit/2);

	&:hover {
		color: $color-links;
	}
}

.experience-block-content {
	border-bottom: 1px solid $color-content-border;

	@media all and (max-width: $bp-sm-landscape) {
		width: 100%;
		flex: 1 100%;
	}
}

.experience-block-abstract {
	@extend .abstract-block-text;

	margin-bottom: $spacing-unit;

	@media all and (max-width: $bp-sm-landscape) {
		margin-bottom: calc($spacing-unit/2);
	}
}


﻿.lawyer-contacts {
    font-family: $font-family-nimb;

    a {
        //display: inline;
    }

    &__items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        gap: 50px;
    }

    &__item {
        display: flex;
        margin: 0;
    }

    &__name {
        font-weight: $font-weight-bold;
        font-size: 100%;
        margin: 0;
    }

    &__content {
        flex: 1;
    }

    &__image {
        width: 100px;
        margin-right: 1rem;
    }

    &__email {
        word-break: break-all;
    }

    &__see-all {
        img {
            margin-left: 5px;
            transition: transform $base-animation-timing $base-animation-ease;
        }

        &:hover {
            img {
                transform: translateX(10px);
            }
        }
    }
}

.home-footer {	
	height: $height-home-footer;
	//four rectangles of fixed aspect ratio
	height: 25vw * $hompage-width-to-height;
	@media all and (max-width: $bp-two-tile) {
		height: 100vw * $hompage-width-to-height;
	}
	
	width: 100%;
	background-color: $color-white;	
    $starting-height: calc(100vh - #{$height-header});
	top: calc( #{$starting-height} - #{$four-tile-height} );
	
	@media all and (max-width: $bp-md) {
			top: calc( #{$starting-height} - #{$two-tile-height} );
	}
	position: fixed;
	font-size: 2em;

	@media all and (max-width: $bp-sm) {
		position: relative;
		top: auto;
		height: auto;
		display: block;
	}

	.footer-infographic {
		float: left;
		width: 25%;
		height: 100%;
		color: $color-white;
		cursor: pointer;
		text-align: center;
		position: relative;
		font-family: $font-family-nimb;

		@media all and (max-width: $bp-md) {
			width: 30%;
		}

		@media all and (max-width: $bp-sm) {
			width: 100%;
			float: none;
			height: 150px;
		}

		img {
			display: inline-block;
			width: 80%;

			@include vertical-align;

			@media all and (max-width: $bp-sm) {
				top: auto;
				transform: none;
				padding: 10px;
				width: 50%;
			}
		}

		.footer-highlight {
			position: relative;
			top: 50%;
			left: 50%;
			width: 70%;
			font-weight: 700;
			transform: translate(-50%, -50%);
			-webkit-font-smoothing: antialiased;

			.emphasis {
				line-height: 100%;
				margin-bottom: 0;
				font-size: 1.8em;
			}

			.content {
				font-size: .6em;
				line-height: 1em;
			}
		}
	}
	
	// &.team .footer-infographic {
	// 	background-color: $color-brand-team;
	// }
	// 
	// &.values .footer-infographic {
	// 	background-color: $color-brand-values;
	// }
	// 
	// &.work .footer-infographic {
	// 	background-color: $color-brand-work;
	// }

	&.team .story-panel, &.team .story-panel-container {
		border-color: $color-brand-team;
		color: $color-brand-team;
	}
	&.team .story-panel-title {
		color: $color-brand-team-mid;
		&:hover {
			color: $color-brand-team-dark;
		}
	}
	
	&.work .story-panel, &.work .story-panel-container { 
		border-color: $color-brand-work;
		color: $color-brand-work;
	}
	&.work .story-panel-title {
		color: $color-brand-work-mid;
		&:hover {
			color: $color-brand-work-dark;
		}
	}
	
	&.values .story-panel, &.values .story-panel-container {
		border-color: $color-brand-values;
		color: $color-brand-values;
	}
	&.values .story-panel-title {
		color: $color-brand-values-mid;
		&:hover {
			color: $color-brand-values-dark;
		}
	}
	
	// slick carousel replaced with 4 panel feature
	.four-panel-feature-container {
		position: relative;
		height: 100%;
		font-family: $font-family-nimb;
		
		$mobile-tile-side-padding: 15vw;

		.story-panel-container {
			box-sizing: border-box;
			float: left;
			width: 25%;
			// height: 25vw
			height: 100%;
			padding:  10px;
			border-right: 5px solid;
			&:last-child  {
				border-right: none;
			}
			
			@media all and (max-width: $bp-two-tile) {
				width: 50%;
				height: 50%;
				&:nth-child(even) {
					border-right: none;
				}
				&:nth-child(n + 3) {
					border-top: 5px solid;
				}
			}
			
			@media all and (max-width: $bp-sm) {
				width: 100%;
				min-height: 60px;
				border: none;
				&:nth-child(n + 3) {
					border-top: none;
				}
				padding-left: $mobile-tile-side-padding;
				padding-right: $mobile-tile-side-padding;
				height: auto;
			}

		}
		
		.story-panel {
			box-sizing: border-box;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			
			height: 100%;

			display: flex;
			align-items: center;
			justify-content: center;
			
			@media all and (max-width: $bp-sm) {
				width: 100%;
				min-height: 40vw * $hompage-width-to-height;
				
				&.has-image {
					height: ( 100vw - ( $mobile-tile-side-padding * 2 )) * $hompage-width-to-height;
				}
				// background-size: contain;
			}			
		}

		.story-panel-modal {
			cursor: pointer;
		}

		@media all and (max-width: $bp-sm) {
			.story-panel-container:not(:first-child) .story-panel:not(.has-image){
					border-top: 5px solid;
			}
			//image sandwich item has two borders
			.story-panel-bottom-border  {
				border-bottom: 5px solid;
			}
		}

		
		.story-panel-title {
			font-weight: 800;
			max-width: 75%;
			font-size: 2.2vw;
			line-height: 1;
			@media all and (max-width: $bp-md) {
				font-size: 34px;
			}
			@media all and (max-width: $bp-sm) {
				max-width: 90%;
				padding-top: .5em;
				padding-bottom: .5em;
			}
		}

		.slide-content-wrapper {
			@include vertical-align;
			position: absolute;
			padding: 60px;
			padding-right: 120px;
			font-weight: 800;
			line-height: 1em;
			font-family: $font-family-nimb;


			@media all and (max-width: $bp-lg) {
				padding: 45px;
			}

			@media all and (max-width: $bp-md) {
				padding: 30px;
			}

			@media all and (max-width: $bp-sm) {
				font-size: .8em;
				padding: 0;			
				position: static;
				top: auto;
				transform: translateY(0);
				display: inline-block;

			}
		}

/*		.slick-prev {
			bottom: 0;
			left: auto;
			border-top: 1px solid $color-white;

			@media all and (max-width: $bp-sm) {
				left: 0;
				border-top: none;
			}
		}

		.slick-next {
			top: 0;
			border-bottom: 1px solid $color-white;

			@media all and (max-width: $bp-sm) {
				top: 40%;
				border-bottom: none;
			}
		}

		.slick-dots {
			position: absolute;
			bottom: 5%;
			left: 50%;
			transform: translateX(-50%);

			@media all and (max-width: $bp-sm) {
				position: static;
				bottom: auto;
				left: auto;
				transform: translateX(0);
				text-align: center;
				margin-right: 40px;
				margin-left: 40px;
			}

			@media all and (min-width: $bp-sm) {
				display: none;
			}

			li {
				display: inline-block;
				margin: 5px;
			}

			button {
				background-color: rgba(85, 85, 85, 0.5);
				height: 4px;
				width: 4px;
				padding: 0px;
				margin: 0px 2px;
				font-size: 0px;
				border: 0 none;
				border-radius: 4px;
				cursor: pointer;
				transition: background-color 0.3s;
			}

			.slick-active button {
				background-color: rgba(85, 85, 85, 1);
			}
		}
		*/
	}
/*
	&.team .slide-content-wrapper {
		color: $color-brand-team-mid;
		
		&:hover {
			color: $color-brand-team-dark;
		}
	}

	&.values .slide-content-wrapper {
		color: $color-brand-values-mid;
		
		&:hover {
			color: $color-brand-values-dark;
		}
	}

	&.work .slide-content-wrapper {
		color: $color-brand-work-mid;
		
		&:hover {
			color: $color-brand-work-dark;
		}
	}

	.slide-content-wrapper {
		cursor: pointer;
		transition: color .5s;
	}

	&.team .slick-prev, 
	&.team .slick-next {
		background: $color-brand-team-dark;

		&:hover {
			background: $color-brand-team;

			@media all and (max-width: $bp-sm) {
				background: none;
			}
		}


		@media all and (max-width: $bp-sm) {
			background-color: transparent;
			color: $color-brand-team-dark;
		}
	}

	&.values .slick-prev, 
	&.values .slick-next {
		background: $color-brand-values-dark;

		&:hover {
			background: $color-brand-values;

			@media all and (max-width: $bp-sm) {
				background: none;
			}
		}

		@media all and (max-width: $bp-sm) {
			background-color: transparent;
			color: $color-brand-values-dark;
		}
	}

	&.work .slick-prev, 
	&.work .slick-next {
		background: $color-brand-work-dark;

		&:hover {
			background: $color-brand-work;

			@media all and (max-width: $bp-sm) {
				background: none;
			}
		}


		@media all and (max-width: $bp-sm) {
			background-color: transparent;
			color: $color-brand-work-dark;
		}
	}
	*/
}

//.active-next .story-panel, .active-prev .story-panel
.zero-width .story-panel  {
	visibility: hidden;
}
@media all and (min-width: $bp-sm) {
	.active-next .story-panel-title {
		visibility: hidden;
	}
}
@mixin vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@mixin navbar-align {
  top: 70%;
  transform: translateY(-70%);
  position: relative;
}

//Mixin for placeholder text
@mixin placeholder {
  $placeholders: ':-webkit-input' ':-moz' '-moz' '-ms-input';
  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      @content;
    }
  }
}

@mixin section-header {
  font-size: 2.6rem;
  line-height: 2.6rem;
  margin-bottom: 2rem;
  font-family: $font-family-nimb;
  color: $color-brand-work-mid;
  font-weight: $font-weight-bold;
}

@mixin font-awesome-icon {
  display: inline-block;
  margin-left: 5px;
  font: normal normal 1rem/1 FontAwesome;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin expand-plus {
  position: absolute;
  top: 0;
  width: 1.25rem;
  height: 0.2rem;
  background-color: #d8d8d8;
  transition: background-color 0.2s, left 0.4s, transform 0.4s;

  &:after {
    content: '';
    position: absolute;
    width: 1.25rem;
    height: 0.2rem;
    background-color: #d8d8d8;
    transition: background-color 0.2s, left 0.4s, transform 0.4s;
    font-size: 0;
    line-height: 0;
    left: 50%;
    transform: translateX(-50%) rotate(90deg);
  }
}

@mixin icon-after {
  &::after {
    content: '';
    display: inline-block;
    height: 13px;
    width: 20px;
    background-image: url('../images/icons/arrow-right.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: transform $base-animation-timing $base-animation-ease;
  }
  &:hover {
    &::after {
      transform: translateX(10px);
    }
  }
}

// @mixin icon-absolute {
// 	position: relative;
// 	padding-right: 50px;
// 	@include icon-after;
// 	&::after {
// 		position: absolute;
// 		right: 20px;
// 		top: 50%;
// 		transform: translate(0, -50%);
// 	}
// 	&:hover {
// 		&::after {
// 			transform: translate(10px, -50%);
// 		}
// 	}
// }

.rich-text {
    ul {
        padding-left: 1rem;
        list-style-type: square;
    }

    ol {
        padding-left: 1rem;
        list-style-type: decimal;
    }

    p, li {
        line-height: 1.6em;
    }

    &--mb {
        margin-bottom: $spacing-unit* 1.3;
    }

    a {
        color: $color-brand-lightblue-alt;
        transition: $base-animation;
        text-decoration: underline;

        &:hover {
            color: $color-brand-blue;
            text-decoration: underline;
            font-weight: bold;
        }

        &[name] {
            all: unset;
        }
    }

    &__video {
        margin-bottom: $spacing-unit;
    }
}

.rich-text-intro {
    ul {
        margin: 0 0 $spacing-unit;
    }

    table {
        width: 100%;
        text-align: center;
        font-family: $font-family-primary;
        color: $color-brand-blue;
        border: 1px solid #808080;
    }

    table th {
        background-color: $color-brand-blue;
        color: white;
        font-weight: bold;
        text-align: center;
        border: 1px solid $color-brand-blue;
        column-span: all;
    }

    table tr {
    }

    table td {
        border: 1px solid #EBEBEB;
    }

    table td, table th {
        padding: 3px 4px;
    }

    table tbody td {
        font-size: 13px;
    }


    table tfoot td {
        font-size: 14px;
    }
}

.video-widget {
  position: relative;
  padding-bottom:55%;
  height: 0;
  overflow: hidden;
  margin-bottom: $spacing-unit;


  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

//neded for collapsed content
.read-more-hide {
	display: none;
	@media print {
		display: block;
	}
}

.rich-text {

    &__columns {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: wrap;
    }    
    
    &__column {
        padding-right: 0;
        width: 100%;
        margin-bottom: 1rem;
    }

    @media screen and (min-width: $bp-lg) {
        &__columns {
            flex-direction: row;

            &--single {
              & > * {
                  width: 100%;
              }
            }
            &--double {
                & > * {
                    width: calc(100% / 2);
                }
            }
            &--triple {
                & > * {
                    width: calc(100% / 3);
                }            
            }
        }        

        &__column {
            padding-right: 3rem;
        }
	}
}
.grid-content-main {

	.event-info {
		margin-bottom: 10px;
		font-size: .8888rem;
		color: $color-text-gray;

		a.btn-download-pdf {
			margin: 1rem auto;
		}
	}

}
.related-stories {
	font-size: 0;
	line-height: 0;
	padding-bottom: $spacing-block-unit;
	border-bottom: 1px solid $color-content-border;

	.related-story {
		position: relative;
		display: inline-block;
		margin: 0 .25rem .25rem 0;
		width: 218px;
		height: 250px;
		overflow: hidden;

		&.key-contact {
			height: auto;
		}

		&:hover {
			.hidden-container {
				max-height: 200px;
			}
		}
	}

	.related-story-info {
		text-align: left;
		font-size: .88rem;
		line-height: 1.5rem;
		color: #fff;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.7);
		position: absolute;
		bottom: 0;
		padding: 1rem;


	}

	.fa {
		float: right;
		font-size: 15px;
		border: 1px solid $color-brand-white;
		padding: 5px 5px 5px 8px;

		transition: border-color .2s;
	}

	.related-story-name {
		font-family: $font-family-nimb;
		font-weight: $font-weight-bold;
		font-style: normal;
		font-size: 1.22rem;
		transition: color .2s;

		&:hover {
			color: $color-brand-yellow;
			cursor: pointer;

			.fa {
				border-color: $color-brand-yellow;
				
			}
		}
	}

	.related-story-title {
		font-style: italic;
	}
}


* {
  box-sizing: border-box;

  &:focus {
    outline: 2px solid;
  }
}

html {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

body {
  font-size: $font-size-base;
  line-height: 1.3em;
  padding: $height-header 0 0;
  margin: 0;
  overflow-x: hidden;
  position: relative;
  overflow-y: auto;
  background-color: $color-white;

  // @media all and (max-width: $bp-lgx) {
  // 	font-size: $font-size-base - 2;
  // }

  // @media all and (max-width: $bp-lg) {
  // 	font-size: $font-size-base - 4;
  // }

  @media all and (max-width: $bp-md) {
    padding-top: $height-header-sm;
  }

  //need homepage classes added
  &.homepage {
    background-color: $color-dark-white;
  }
}

main {
  display: block;
}

.header-padding {
  min-height: 100 - $height-header;
  // min-height: 90%;
  top: 7.5%;
  position: relative;

  @media all and (max-width: $bp-sm) {
    top: 5%;
  }
}

.window-shade {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  display: none;
  background-color: rgba($color-black, 0.5);
  z-index: $z-index-ajax-content;
  cursor: pointer;
}

a {
  text-decoration: none;
}

p {
  margin: 0 0 $spacing-unit;
}

ul {
  margin: 0 0 $spacing-unit;
}

li {
  margin: 0 0 calc($spacing-unit/2);
}

.phone {
  display: block;

  a {
    color: $color-text;
  }
}

.wrapper {
  max-width: $width-wrapper;
  margin: 0 auto;

  @media all and (max-width: $bp-wrapper) {
    padding: 0 10px;
  }

  &.padding {
    padding: $padding-section 0;

    @media all and (max-width: $bp-md) {
      padding: calc($padding-section/2) 0;
    }

    @media all and (max-width: $bp-sm) {
      padding:calc($padding-section/4) 0;
    }
  }

  &.paddingTop {
    padding-top: $padding-section;
  }

  @media all and (max-width: $bp-lg) {
    &.padding {
      padding: $padding-section * 0.8 0;
    }

    &.paddingTop {
      padding-top: $padding-section * 0.8;
    }
  }

  @media all and (max-width: $bp-md) {
    &.padding {
      padding: calc($padding-section/2) 0;
    }

    &.paddingTop {
      padding-top: calc($padding-section/2);
    }
  }
}

.col-row {
  @extend %clearfix-micro;
}

//Solving the Whitespace issuewith inlineblock positioning
//http://www.vanseodesign.com/css/inline-blocks/
.col-row-inline-block {
  overflow: hidden;
  font-size: 0;
  line-height: 0;

  [class*='col'] {
    font-size: 1rem;
    line-height: 1.5;
    display: inline-block;
    vertical-align: top;
  }
}

.col {
  float: left;
}

.col-padded {
  float: left;

  &.col1-2 {
    float: left;
    width: 48%;
    padding-right: 2%;

    @media all and (max-width: $bp-sm) {
      width: 100%;
      float: none;
      clear: both;
      padding-right: 0;
    }
  }

  &.col1-3 {
    float: left;
    width: 33.333336%;
    padding-right: 2%;

    @media all and (max-width: $bp-sm) {
      width: 100%;
      float: none;
      padding-right: 0;
    }
  }
}

.col1-2 {
  width: 50%;
}

.col1-3 {
  width: 33.3333%;

  @media all and (max-width: $bp-sm) {
    width: 100%;
  }
}

.col2-3 {
  width: 66.6666%;

  @media all and (max-width: $bp-sm) {
    width: 100%;
  }
}

.col1-4 {
  width: 25%;

  @media all and (max-width: $bp-md) {
    width: 33%;

    &.col-break-half-width {
      width: 50%;
    }
  }

  @media all and (max-width: $bp-sm) {
    width: 100%;
  }
}

//specify breakpoint inside components, breakpoints will differ based on layout
.col1-5 {
  width: 20%;
}

//specify breakpoint inside components, breakpoints will differ based on layout
.col2-5 {
  width: 40%;
}

//specify breakpoint inside components, breakpoints will differ based on layout
.col3-5 {
  width: 60%;
}

.col3-4 {
  width: 75%;

  @media all and (max-width: $bp-md) {
    width: 66.6666%;
  }

  @media all and (max-width: $bp-sm) {
    width: 100%;
  }
}

//content grid with fallback to ie9

.grid-content {
  @extend %clearfix-micro;

  &--all {
    display: flex;
    justify-content: space-around;

    @media all and (max-width: $bp-md-grid) {
      flex-flow: column-reverse;
    }
  }

  &__back-to-top {
    position: fixed;
    bottom: 50px;
    right: 9%;
    width: 66px;
    height: 66px;
    border-radius: 50%;
    background-color: $masonry-lt-gray;
    display: none;
  }
}

.grid-content-main {
  width: $site-main-column;
  float: left;

  &--all {
    width: 52%;

    @media all and (max-width: $bp-md-grid) {
      width: 100%;
    }
  }

  &.people-search {
    float: right;
  }

  h2 {
    color: $color-brand-blue;
  }

  &--full {
    width: 100%;
  }
}

.grid-content-main-right {
  float: right;
}

.grid-content-main-right {
  float: right;
}

.grid-content-perspectives {
  max-width: 1150px;
  margin: 0 auto;
  width: 100%;
}

.no-sidebar {
  width: 100% !important;
}

.grid-content-sidebar {
  width: $site-sidebar-column;
  float: right;


  &--all {
    width: 25%;

    @media all and (max-width: $bp-md-grid) {
      width: 100%;
    }
  }

  &.people-search {
    float: left;

    @media all and (max-width: $bp-md) {
      .btn-custom-filter {
        &.btn {
          display: inline-block;
          width: 100%;
        }
      }

      &>*:first-child {
        margin-top: 1.2rem;
      }
    }
  }

  @media all and (max-width: $bp-md) {
    width: 27%;
  }

  @media all and (max-width: $bp-md-grid) {
    margin-top: $spacing-unit;
  }

  &>* {
    margin-top: $spacing-unit;
  }

  &>*:first-child {
    margin-top: 0px;
  }

  .side-list {
    a {
      color: $color-brand-lightblue-alt;
      transition: $base-animation;

      &:hover {
        color: $color-gray-dark;
        text-decoration: underline;
      }
    }
  }
}

.grid-content-sidebar-left {
  float: left;
}

.grid-content-main,
.grid-content-sidebar {
  @media all and (max-width: $bp-md-grid) {
    width: 100%;
    float: none;
  }
}

//section
.content-block {
  margin-bottom: 2.4rem;

  &__additional-listing {
    margin-top: 2.4rem;
  }
}

.site-banner-design {
  background: transparent no-repeat center top;
  background-size: cover;
  position: absolute;
  width: 100%;
  top: $height-header;
  left: 0;
  height: 280px;
  z-index: 1;
  overflow: hidden;

  @media all and (max-width: $bp-lg) {
    height: 300px;
  }

  @media all and (max-width: $bp-md) {
    height: 240px;
  }

  @media all and (max-width: $bp-sm) {
    text-align: center;
    top: $height-header-sm;
  }

  @media all and (max-width: $bp-sm-landscape) {
    height: 150px;
  }

  &+.site-content {
    //margin-top: 200px;
    margin-top: 280px;
    min-height: calc(100vh - 19vh - 310px);

    @media all and (max-width: $bp-lg) {
      margin-top: 220px;
      min-height: calc(100vh - 18vh - 220px);
    }

    @media all and (max-width: $bp-md) {
      margin-top: 165px;
      min-height: calc(100vh - 19vh - 165px);
    }

    @media all and (max-width: $bp-sm-landscape) {
      margin-top: 95px;
      min-height: calc(100vh - 145px);
    }
  }

  /*	&:after {
		content: '';
		position: absolute;
		top: 100%;
		width: 100%;
		box-shadow: 0px -22px 32px 18px rgba($color-dark-gray, 0.5);
		display: block;
		background-color: white;
		height: 1%;
	}*/
}

.site-content {
  position: relative;
  z-index: 5;
  margin: 0 auto;

  //Set minimum height of site-content area to push the footer to the bottom of the screen if the page doesnt have enough content
  min-height: calc(100vh - 19vh);

  .site-subnav+& {
    margin-top: 84px;

    @media all and (max-width: $bp-lg) {
      margin-top: 45px;
    }
  }

  img {
    max-width: 100%;
  }
}

.site-main-content {
  position: relative;
  z-index: 10;
}

.site-wrapper {
  background-color: $color-white;
  margin: 0 auto;
  max-width: $site-content-width;
  width: 100%;
  padding: 1.74444em $padding-content-wrapper;

  &--no-padding-top {
    padding-top: 0;
  }

  &--leadership-padding {
    padding: 1rem 1.5rem;
  }

  @media all and (max-width: $bp-md-bio) {
    padding: 1.25rem $padding-content-wrapper-md;
  }

  @media all and ($bp-sm-landscape-iphone) {
    padding: 1rem $padding-content-wrapper-sm;
  }
}

.site-wrapper-base {
  max-width: $site-content-width;
  width: 90%;
  margin: 0 auto;

  @media all and (max-width: $bp-md-bio) {
    width: 94%;
  }
}

.site-wrapper-fluid {
  padding: 0 $site-fluid-padding;

  @media all and (max-width: $bp-lg) {
    padding: 0 $site-fluid-padding-lg;
  }
}

.perspectives-search {
  width: calc(100% - 2%);

  .page-title {
    max-width: 1150px;
    margin: 0 auto;
  }

  .search-box {
    position: relative;
    margin-bottom: 2rem;

    &--all {
      width: 66%;
    }

    button {
      color: $color-brand-navy !important;
    }

    input {
      border: none;
      background-color: $form-input-bg;
      font-size: 0.9rem;
      line-height: 1.1;
      font-weight: 700;
      font-family: $font-family-nimb;
      padding: 15px 20px;
      color: $color-footer-gray;
      width: 100%;
      border-radius: 50px;

      @include placeholder {
        color: $form-input-placeholder;
      }
    }

    @media all and (max-width: $bp-md) {
      width: 100%;
    }

    button {
      position: absolute;
      right: 22px;
      top: 0;
      height: 100%;
      padding: 0;
      font-size: 20px;
      color: $color-gray-med;
    }

    .fa-search {
      top: auto;
      transform: translateY(0);
    }
  }

  .search-filter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px !important;

    .fa {
      margin: 0 4px 0 5px;
    }
  }

  .filter-label {
    font-size: 16px !important;
    color: #333 !important;
    margin-right: 8px;
    width: unset !important;
  }

  .filter {
    font-family: $font-family-nimb;
    font-size: 16px !important;
    font-weight: 700;

    &:after,
    &.clear-all:before {
      font-weight: 400;
      font-size: 17px;
      font-family: $font-family-primary;
      color: #717171 !important;
    }
  }

  .clear-all {
    color: $color-brand-navy !important;
  }

  .side-filter {
    border: none;
    background-color: transparent;

    &__mobile-btn {
      display: none;
    }

    .side-filter-header {
      font-family: $font-family-nimb;
      color: $color-footer-gray;
      font-weight: $font-weight-bold;
      font-size: 0.9rem;
      padding: 0;
      padding-bottom: 8px;
      margin: 0;
    }

    .filter-category {
      border: none;

      &.active {
        .filter-category-name {
          background-color: #fcfcfc;
        }
      }
    }

    .filter-category-name {
      padding: 16px 13px 16px 8px;
      color: #636466;
      font-size: 17px;
      background-color: #fcfcfc;
      margin-bottom: 8px;

      &:hover {

        .expand-icon {

          &:after,
          &:before {
            background-color: $color-brand-navy;
          }
        }
      }
    }

    .filter-list {
      >input[type='text'] {
        background-color: $form-input-bg;
        margin-bottom: 14px;
        padding: 14px 18px;
        font-size: 0.9rem;
      }
    }

    .filter-sub-list {
      max-height: unset;
      overflow: unset;
      margin: unset;
    }

    .filter-sub-item {
      padding: 12px 0 0 15px;
      display: flex;
      margin-bottom: 0;
    }

    .filter-sub-checkbox {
      width: auto;
    }

    .filter-sub-label {
      font-size: 15px;
      margin-left: 6px;
      color: #666666;
    }

    .expand-icon {

      &:before,
      &:after {
        content: '';
        display: inline-block;
        width: 15px;
        height: 1px;
        background-color: $color-brand-navy;
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translate3d(0, -50%, 0);
      }

      &:before {
        transform: translate3d(0, -50%, 0) rotate(90deg);
        transition: transform 0.4s;
      }
    }

    @media all and (max-width: $bp-md) {
      max-height: 0;
      transition: all 0.5s;

      &.active {
        max-height: 1000px;
      }

      .side-filter-header {
        display: none;
      }

      &__mobile-btn {
        display: block;
        width: 100%;
        border-radius: 8px;
        text-align: left;
        font-size: 16px;
        font-weight: 700;
        padding: 9px 15px;
        background: $masonry-lt-gray;
        color: $color-brand-navy;
        margin-bottom: 18px;
      }

      &__chevron {
        margin-left: 5px;
        transform: rotate(0);
        transition: transform 0.5s ease-in-out;

        &.active-chev {
          transform: rotate(180deg);
        }
      }
    }
  }
}

//this line can be removed after the build
.template-nav {
  a {
    display: block;
  }
}
$industry-filter-width: 250px;

.external-location-text {
  font-size: 12px;
  text-align: center;
  width: 100%;
}

.recovery-map-section {
  position: relative;
  .search-filter-button {
    margin-top: 5px;
    @media screen and (max-width: $bp-sm-portrait) {
      width: 100%;
    }
  }
  .recovery-map-container {
    position: relative;
  }
  .recovery-heat-map {
    width: 100%;
    height: 475px;
    margin-top: 50px;
  }
  .recovery-heat-map-controls {
    position: absolute;
    // top: 25%;
    left: 0;
    z-index: 1;
    button {
      color: $color-brand-blue;
      font-size: 25px;
      display: block;
      padding-bottom: 5px;
    }
  }
  .recovery-map-overlay {
    position: absolute;
    width: 600px;
    max-height: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    background-color: $color-brand-white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
    font-family: $font-family-primary;

    .map-overlay-header {
      padding: 20px 20px 0 20px;
    }
    .map-overlay-no-data {
      padding: 20px 20px 0 20px;
    }
    .map-overlay-title {
      margin-bottom: 0;
    }

    .map-overlay-close {
      position: absolute;
      top: 10px;
      right: 10px;
      font-weight: bold;
      color: $color-brand-blue;
    }
    .map-overlay-chart {
      width: 100%;
      height: 300px;
    }
    .amcharts-legend-div {
      overflow-y: auto !important;
      max-height: 280px;
    }
  }
  .recovery-totals-text {
    font-size: 24px;
    color: $color-brand-blue;
    margin-top: $spacing-unit;
    border: 1px solid $color-lightish-gray;
    background-color: #f2f2f2;
    padding: 10px;
    text-align: center;
    border-radius: $site-border-radius;
  }
  .recovery-map-external-locations {
    position: relative;
    width: 100%;
    height: 75px;
    margin-top: $spacing-unit;
    ul {
      display: flex;
      justify-content: center;
    }
    li {
      border: 2px solid $color-lightish-gray;
      cursor: pointer;
      width: calc(100% / 9);
      padding: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 5px;
      border-radius: $site-border-radius;

      svg {
        width: 50px;
        height: 50px;
      }

      &:hover,
      &.selected,
      &.hover-selected {
        border-color: $color-black !important;
      }
    }
  }
  .recovery-map-ranges {
    position: absolute;
    top: 75px;
    right: 20px;
    z-index: 1;
  }
  .recovery-map-range-list {
    display: none;
    list-style: none;
    list-style-type: none;
    margin: 0;
    padding: 0;
    &.active {
      display: block;
    }

    .range-item {
      border: 1px solid $color-lightish-gray;
      position: relative;
      width: 30px;
      height: 30px;
      margin: 0;
      padding: 0;
      cursor: pointer;

      .range-item-text {
        position: absolute;
        left: 33px;
        top: 3px;
        font-size: 14px;
        width: 130px;
        display: none;

        &.active {
          display: block;
        }
      }
      &.active-hover {
        border: 2px solid $color-black;

        .range-item-text {
          display: block;
        }
      }

      &:hover {
        border: 2px solid $color-black;

        .range-item-text {
          display: block;
        }
      }
    }
  }

  @media screen and (max-width: $bp-lg-bio) {
    .recovery-map-components {
      position: relative;
    }

    .recovery-heat-map {
      margin-top: 0;
    }
    .recovery-map-ranges {
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
    }
    .recovery-map-range-list {
      transform: rotate(180deg);
      &.active {
        display: flex;
      }

      .range-item {
        &.active-hover {
          border: 1px solid #dadada;

          .range-item-text {
            display: none;
          }
        }
        .range-item-text {
          left: 0px;
          top: -25px;
          transform: rotate(180deg);
          width: auto;
          &.active {
            display: none;
            &.show-mobile {
              display: block;
            }
          }
        }
      }
    }
    .recovery-totals-text {
      margin-bottom: $spacing-unit * 2;
      margin-top: $spacing-unit;
    }

    .recovery-map-external-locations {
      height: auto;
      margin-bottom: $spacing-unit;
      margin-top: 1rem;
      ul {
        display: flex;
        justify-content: flex-start;
        margin-right: 0;
      }
      li {
        width: calc(100% / 7);
      }
    }
    .recovery-heat-map-controls {
      display: flex;
      margin-bottom: 1rem;
      button {
        padding-right: 5px;
      }
    }
  }

  @media screen and (max-width: $bp-md-grid) {
    .recovery-map-ranges {
      .range-item {
        &:hover {
          border: none;

          .range-item-text:not(.active) {
            display: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: $bp-sm-landscape) {
    .recovery-heat-map-controls {
      top: 10%;
    }
    .recovery-map-external-locations {
      ul {
        flex-wrap: wrap;
      }
      li {
        width: calc(100% / 3 - 5px);
      }
    }
  }

  @media screen and (max-width: $bp-sm-portrait) {
    .recovery-heat-map-controls {
      top: 17%;
    }
    .recovery-heat-map {
      height: 250px;
    }

    .recovery-map-external-locations {
      li {
        width: calc(100% / 3 - 6px);
        margin-bottom: 5px;
        svg {
          width: 40px;
          height: 40px;
        }
      }
    }

    .recovery-map-overlay {
      width: 95vw;
      max-height: 95vh;

      .map-overlay-chart {
        width: 100%;
        height: 525px;
      }
    }
  }
}

﻿#table.tabulator {

    & .tabulator-footer {

        & .tabulator-page {
            padding: 5px 8px;
            border-radius: 6px;
            background-color: $color-load-more-bg;
            -webkit-transition: background-color 0.2s;
            transition: background-color 0.2s;
            font-family: $font-family-nimb;
            font-size: .75rem;
            border: 0 none;
            color: #030303;
            font-size: 1rem;
            font-weight: 700;

            &.active {
                color: $color-brand-orange;
            }

            &:hover {
                background-color: $color-lightish-gray;
            }
        }
    }
}

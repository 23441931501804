.recovery-stats-summary-section {
    	.recovery-stats-summary {
            
    	}
        .recovery-stats-group{
            margin-bottom: $spacing-unit;
        }
        .recovery-stats-text{
            display: inline-block;
            font-weight: $font-weight-bold;
            vertical-align: middle;
        }
        .recovery-stats-text-left {
            color: $color-brand-lightblue;
	        font-size: 1.2rem;
            width: 45%;
            font-family: $font-family-primary;
            margin-right: 8%;
        }
        .recovery-stats-text-right {
            font-size: 1rem;
            width: 45%;
        }
        .recovery-stats-disclaimer{
            margin-bottom: $spacing-unit;
        }
        .recovery-stats-more{
            text-align: center;
            margin-bottom: $spacing-unit;
            a {
                color: $color-brand-white;
                background-color: $color-brand-darkblue;
                display: block;
                padding: .5rem .5rem;
                transition: $base-animation;
                font-weight: $font-weight-bold;
                font-size: 1.2rem;
                border-radius: $site-border-radius;

                &:hover {
                    background-color: $color-brand-lightblue;
                }
            }

        }

        @media all and (max-width: $bp-lg-bio) and (min-width: $bp-md-grid) {
		    .recovery-stats-text-left {
                width: 100%;
            }
            .recovery-stats-text-right {
                width: 100%;
            }
	    }
}
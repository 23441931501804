﻿.featured-topics {
    background: #f7f7f7;
    padding: 2rem 0;

    &__listings {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
    }

    &__item {
        width: 100%;
        margin-bottom: 30px;
        position: relative;
        background: $color-light-gray;
        border-radius: $site-border-radius;
        overflow: hidden;
        padding: 2rem;

        h3 {
            color: $color-brand-blue;
            margin-bottom: 2rem;
            font-size: 1.4rem;
            font-weight: $font-weight-thin;
            transition: color $base-animation-timing $base-animation-ease;
        }

        a {
            font-family: $font-family-nimb;
            font-size: 18px;
            transition: color $base-animation-timing $base-animation-ease;
        }

        &:hover {

            .featured-topics {

                &__image {
                    opacity: 1;

                    &::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: $color-black;
                        opacity: .5;
                    }
                }

                &__content {
                    color: $color-white;

                    h3, a {
                        color: $color-white;
                    }
                }
            }
        }
    }

    &__content {
        position: relative;
        transition: color $base-animation-timing $base-animation-ease;
    }

    &__image {
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        transition: opacity $base-animation-timing $base-animation-ease;
    }

    .service-landing__listings {
        flex-wrap: wrap;
    }

    &__view-all {
        width: 100%;
        display: flex;
        justify-content: center;

        a {
            padding: 7px 16px;
            font-size: 16px;
            margin: 0;
            border-width: 1px;
        }
    }

    @media screen and (min-width: $bp-sm) {
        &__item {
            width: 49%;
        }
    }
}

﻿#sliding-popup {
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 500;
    transform: translateY(100%);
    transition: transform .4s ease-in .4s;
    background-color: #ededed;
    border-top: 1px solid #c5c5c5;

    &.is-animating {
    	transform: translateY(0);
    }

    p,
    h1,
    h2,
    h3 {
    	padding:0 25px;
    }

    a {
        font-weight: bold;
        text-decoration: underline;
    }
}


#sliding-popup .popup-content {	
    border: 0 none;
    margin: 0 auto;
    max-width: 700px;
    padding:30px 15px;
	font-size: .9em;
}
#popup-buttons {
    margin-top: 15px;
    text-align: center;
}

#popup-buttons .btn{
    font-size: 1.3rem;
	padding-left: 3rem;
	padding-right: 3rem;
	background-color: #071c4d;
}
﻿.overlay-all {
	display: block;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: $opaquewhite;
	z-index: $z-index-overlay-all;
	opacity: 0;
	transition: $overlay-animation;

	&.is-shown-overlay {
		opacity: 1;
	}
}

.publication-details {
  .page-title {
    text-transform: none;
  }

  .cases-container {
    margin-top: 2.4rem;
  }

  .grid-content-main .content-block .grid-content-main{
      width: inherit;

  }

  .content-block {
    &__viewMoreLink {
      a {
          align-items: baseline;
          border-radius: 50px;
          display: flex;
          font-family: $font-family-nimb;
          font-size: 1rem;
          justify-content: space-between;
          margin-bottom: 1rem;
          padding: 10px 20px;
          background-color: $color-brand-navy;
          color: $color-white;
          width: fit-content;
          @include icon-after;

          &:after {
            filter: brightness(0) invert(100%) sepia(100%) saturate(1%) hue-rotate(263deg) brightness(105%) contrast(101%);
            margin-left: 0.2rem;
          }

          &:hover {
            background-color: $color-brand-navy-hover;
          }
      }
    }
  }
}

.up-next {
  font-family: $font-family-nimb;
  font-weight: $font-weight-bold;
  font-size: 1.33rem;
  padding: 1.5rem 0;
  border-top: 1px solid $color-content-border;
  border-bottom: 1px solid $color-content-border;

  .up-next-text {
    color: $color-gray-dark;
  }

  .up-next-location{
    text-decoration: underline;
  }
}

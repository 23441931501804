
.list-alpha-wrap {
	margin-bottom: $spacing-unit;
	overflow: hidden;
	position: relative;
}

.list-alpha {
	overflow: hidden;
	transition: margin-left .25s ease-in-out;
	display: flex;
	border-radius: 50px;
	overflow: hidden;
	border: 1px solid $form-input-border;

	@media all and (max-width: $bp-sm-landscape) {
		width: 2000px;
	}

	> li {
		text-align: center;
		margin: 0;
		flex: 1 1 auto;
		&:not(:last-of-type) {
			border-right: 1px solid $form-input-border;
		}
	}


	&.disableTransition {
		transition: none;
	}
}

.list-alpha-btn {
	background-color: $form-input-bg;
	width: 100%;
	color: $color-links;
	text-align: center;
	padding: 10px 2px;
	font-size: 1rem;


	&:hover {
		background-color: lighten($form-input-border, 5%);
		color: $color-text;
	}

	&:focus {
		outline: 2px solid;
		background-color: darken($form-input-border, 10%)
	}

	&.is-disabled {
		background-color: lighten($form-input-bg, 10%);
		color: $color-text-graylight;
		cursor: default;

		&:hover {
			background-color: lighten($form-input-bg, 10%);
			color: $color-text-graylight;
		}
	}

	&.is-active {
		background-color: $color-brand-white;
		border-color: $color-text;
		color: $color-text;
	}
}

.list-alpha-belt {
	overflow: hidden;
	position: relative;

	@media all and (max-width: $bp-sm-landscape) {
		margin: 0 45px;
		
	}
}

.list-alpha-arrow {
	display: none;
	position: absolute;
	left: 0;
	text-align: center;
	width: 35px;
	height: 100%;
	top: 0;
	font-size: 1.5rem;
	line-height: 1;
	padding: 5px;

	@media all and (max-width: $bp-sm-landscape) {
		display: block;
	}

	&.arrow-right {
		right: 0;
		left: auto;
	}
}
.filter-sortby-label {
    color: #20366f;
    font-weight: bold;
}

.filter-sortby {
    color: #548bb4;
    font-size: 100%;
    font-weight: bold;
    .fa {
        font-size: 1em;
    }
}
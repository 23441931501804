.pull-quote {
    display: flex;
    min-height: 200px;
    margin-bottom: 25px;

    @media all and (max-width: $bp-md) {
        flex-direction: column;

        &__secondary {
            flex-direction: column-reverse;
        }
    }

    &__primary {
        border-top: $color-dark-gray 1px solid;
        border-bottom: $color-dark-gray 1px solid;
        padding: 25px 0;

        & .pull-quote__quote {
            font-size: 2rem;
            color: $color-brand-darkblue;

            @media all and (max-width: $bp-md) {
                font-size: 1.2rem;
            }
        }

        & .pull-quote__attribution {
            font-family: $font-family-primary;
            font-size: 1rem;

            @media all and (max-width: $bp-md) {
                font-size: .875rem;
            }
        }

        @media all and (max-width: $bp-md) {
            flex-direction: column;
        }
    }

    &__secondary-container {
        display: flex;
        padding: 30px;

        &--dark-blue {
            background-color: $color-brand-darkblue;
            
            & .pull-quote__quote, .pull-quote__attribution {
                color: $color-white;
            }
        }

        &--orange {
            background-color: $color-brand-orange;

            & .pull-quote__quote, .pull-quote__attribution {
                color: $color-white;
            }
        }

        &--light-blue {
            background-color: $color-brand-lighterblue;
        }

        &--yellow {
            background-color: $color-brand-yellow;
        }

        & .pull-quote__secondary-image {
            border-radius: 50%;
            width: 100px;
            height: 100px;
            flex-shrink: 0;
            margin-right: 20px;
        }

        & .pull-quote__quote {
            font-size: 1.625rem;

            @media all and (max-width: $bp-md) {
                font-size: 1.2rem;
            }
        }

        & .pull-quote__attribution {
            font-family: $font-family-nimb;
            font-size: .875rem;
            font-weight: $font-weight-bold;
        }

        &:has(+ .pull-quote__image) {
            flex-basis: 50%;

            & .pull-quote__quote {
                font-size: 1.2rem;
            }
        }

        @media all and (max-width: $bp-md) {
            & + .pull-quote__image {
                height: 250px;
            }
        }
    }

    &__quote-container {
        display: flex;
        flex-direction: column;
    }

    &__quote {
        font-family: $font-family-nimb;
        font-weight: $font-weight-thin;

        & p {
            line-height: 1.2;
        }
    }

    &__image-container {
        display: flex;
        min-height: 250px;
        max-height: 300px;
        margin-bottom: 20px;
        
        @media all and (min-width: $bp-md) {
            margin-right: 30px;

            &--one {
                flex-basis: 25%;

                & + .pull-quote__quote-container {
                    flex-basis: 75%;
                }
            }
        }

        &--two {
            justify-content: space-between;

            & .pull-quote__image:last-child:not(:only-child) {
                margin-left: 5px;
            }

            @media all and (min-width: $bp-md) {
                flex-basis: 50%;

                & + .pull-quote__quote-container {
                    flex-basis: 50%;

                    & .pull-quote__quote {
                        font-size: 1.625rem;  
                    }
    
                    & .pull-quote__attribution {
                        font-size: .875rem;  
                    }
                }
            }
        }

        & .pull-quote__image {
            border-radius: 5px;

            @media all and (max-width: $bp-md) {
                &:only-child {
                    flex-grow: 0;
                    width: 50%;
                    margin: 0 auto;
                }
            }
        }
    }

    &__image {
        background-size: cover;
        background-position: center;
        flex-grow: 1;
    }
}

﻿.focus-area {
    background: #f7f7f7;
    padding: 2rem 0;

    &__listings {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
    }

    &__item {
        width: 100%;
        min-height: 50px;
        position: relative;
        background: $color-light-gray;
        border-radius: $site-border-radius;
        overflow: hidden;

        h3 {
            color: $color-white;
            font-size: 1rem;
            font-weight: $font-weight-regular;
            margin: 0;
            width: 75%;
        }

        span {
            color: $color-white;
            font-family: $font-family-nimb;
            font-size: 0.6rem;
            transition: color $base-animation-timing $base-animation-ease;
            visibility: hidden;
            @include icon-after;
            &::after {
                background-image: url('../images/icons/arrow-right-white.svg');
                background-size: cover;
                width: 20px;
                height: 20px;
                visibility: visible;
                vertical-align: middle;
            }
        }

        &:hover {

            .focus-area {

                &__image {
                    transform: scale(1.1);
                    transition: all 0.3s ease-in-out;
                    filter: brightness(85%);
                }
            }
        }
    }

    &__content {
        margin: 1rem;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }

    &__image {
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &__layer {
        align-items: center;
        background: linear-gradient(to right, rgba(0,22,51), rgba(0, 22, 51, 0));
        mix-blend-mode: hard-light;
        min-height: 250px;
        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
            height: 250px;
        }
    }

    .service-landing__listings {
        flex-wrap: wrap;
    }

    &__view-all {
        width: 100%;
        display: flex;
        justify-content: center;

        a {
            padding: 7px 16px;
            font-size: 16px;
            margin: 0;
            border-width: 1px;
        }
    }

    @media screen and (min-width: $bp-sm) {
        &__listings {
            gap: 1rem;
        }

        &__item {
            width: 160px;
            height: 175px;

            span {
                visibility: visible;
                &::after {
                    visibility: hidden;
                }
            }

            h3{
                width: 100%;
            }
        }

        &__content {
            position: absolute;
            left: 0;
            bottom: 0;
            margin: 1rem;
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
        }
    }
}

.job-listing-page {
  .view-all-btn {
    font-family: $font-family-nimb;
    padding: 0.5rem 1rem;
    border: 1px solid $color-brand-navy;
    font-size: 1rem;
    border-radius: 50px;
    margin-bottom: 1rem;
    color: $color-brand-navy;
    display: inline-block;
    white-space: nowrap;
    @include icon-after;

    &:after {
      margin-left: 0.5rem;
    }

    &:hover {
        color: $color-brand-lightblue;
    }
  }

  .spacing-base {
    margin-bottom: 1rem;
  }

  .select-dropdown {
    min-width: 200px;
    height: 58.38px;
    display: flex;
    align-items: center;
  }

  .select-dropdown-label {
    width: 100%;
  }

  .title {
      font-family: $font-family-primary;
  }

  .department-practice {
    margin-top: 0.5rem;
    font-family: $font-family-primary;
    font-weight: $font-weight-regular;
    font-style: italic;
    color: $color-gray-dark;
    font-size: 21px;
  }

  .locations {
    padding-left: 1rem;
    color: $color-gray-med;
    font-size: 16px;
    margin-top: 0.5rem;

    .location {
      font-family: $font-family-nimb;
      font-weight: $font-weight-regular;

      &:not(:last-child) {
        &:after {
          content: ','
        }
      }
    }
  }

  .workplace-type {
    margin-top: 0.3rem;
    color: $color-gray-med;
    font-size: 16px;
    font-family: $font-family-nimb;
    font-weight: $font-weight-regular;
  }

  @media (min-width: 500px) {
    .filter-dropdowns {
      display: flex;
      gap: 0.5rem;
      flex-wrap: wrap;
    }
  }

  @media (max-width: 1000px) {
    &__search-controls {
      margin-bottom: 2rem;
    }

    .search-box {
      margin-bottom: 1rem;
    }
  }


  @media (min-width: 1000px) {

    &__search-controls {
      display: flex;
      gap: 0.5rem;

      .search-box {
        flex-grow: 1;
      }

      .filter-dropdowns {
        display: contents;

        .spacing-base {
          margin-bottom: 2rem;
        }

        .select-dropdown-label {
          &:after {
            margin-left: 1rem;
          }
        }
      }
    }
  }
}